import React, { useState } from 'react'

import { Modal, Space, Spin } from 'antd'
import { closeSVG } from '../../../assets/svg/close'
import { ButtonModal } from '../../../atom/button'
import { AlertError } from '../../../components/error'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { SwtchApiError } from '../../../models/error'
import {
  ArchiveProgram,
  CancelShavingEvent,
  GetAllPeakShavingEvents,
} from '../../../services/data-provider/peak-shaving'
import moment from 'moment'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
  programId: number
}

export const ArchiveProgramModal: React.FC<props> = ({ visible, onCancel, onSubmit, programId }) => {
  const { cancelText, archiveText, arhiveProgramWarningText } = usePeakShavingTranslation()
  const [error, setError] = useState<SwtchApiError>()
  const [loading, setLoading] = useState(false)

  const onClickArchiveButton = () => {
    // Get all events which are published,
    // cancel them and then archive program
    setLoading(true)
    GetAllPeakShavingEvents(
      {
        page: 1,
        perPage: 100,
        programId: programId,
        state: 'published',
      },
      moment().subtract(6, 'days'),
      moment().endOf('day'),
    )
      .then(({ data }) => {
        data && data.length > 0
          ? Promise.all(data.map((event) => CancelShavingEvent(event.id)))
              .then(() => {
                ArchiveProgram(programId)
                  .then(() => {
                    onSubmit()
                  })
                  .catch((err: SwtchApiError) => {
                    setError(err)
                  })
              })
              .catch((err: SwtchApiError) => {
                setError(err)
              })
              .finally(() => {
                setLoading(false)
              })
          : ArchiveProgram(programId)
              .then(() => {
                onSubmit()
              })
              .catch((err: SwtchApiError) => {
                setError(err)
              })
              .finally(() => {
                setLoading(false)
              })
      })
      .catch((err: SwtchApiError) => {
        setError(err)
      })
  }

  return (
    <Modal
      visible={visible}
      title={<h6 className="heading-06-bold">{archiveText}</h6>}
      className={`archive-program-modal ${error ? 'ant-modal-error' : ''}`}
      footer={
        <Space>
          <ButtonModal type="ghost" icon={<></>} click={onCancel} text={cancelText} />
          <ButtonModal type="primary" icon={<></>} click={onClickArchiveButton} text={archiveText} />
        </Space>
      }
      onCancel={onCancel}
      closeIcon={closeSVG}
    >
      <Spin spinning={loading}>
        <AlertError error={error} />
        <h6 className="heading-06-regular">{arhiveProgramWarningText}</h6>
      </Spin>
    </Modal>
  )
}
