import { ListingFilter, ServiceStatusReasonsFilter } from '../../models/filter'

export const listingQuery = (f: ListingFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.defaultTenant) {
    queryParams['tenant_id'] = `${f.defaultTenant.id}`
  }

  if (f.tenants && f.tenants.length > 0) {
    queryParams['tenant_id'] = `${f.tenants.map((t) => t.id)}`
  }

  if (f.listing) {
    queryParams['term'] = `${f.listing.title}`
  }

  if (f.listingTitle && f.listingTitle.length !== 0) {
    queryParams['listing_title'] = `${f.listingTitle.join(',')}`
  }

  if (f.listingId && f.listingId.length !== 0) {
    queryParams['listing_id'] = `${f.listingId.join(',')}`
  }

  if (f.serviceStatuses && f.serviceStatuses.length !== 0) {
    queryParams['service_status'] = f.serviceStatuses.join(',')
  }

  if (f.charger) {
    queryParams['charger_id'] = `${f.charger.id}`
  }

  if (f.country) {
    queryParams['country'] = `${f.country}`
  }
  if (f.province) {
    queryParams['province'] = `${f.province}`
  }

  if (f.masterAccount) {
    queryParams['master_account_ids'] = f.masterAccount.id.toString()
  }

  if (f.allListings) {
    queryParams['all_listings'] = `${f.allListings}`
  }

  return queryParams
}

export const serviceStatusReasonsQuery = (f: ServiceStatusReasonsFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}
  if (f.newStatus) {
    queryParams['new_status'] = `${f.newStatus}`
  }

  if (f.previousStatus) {
    queryParams['previous_status'] = `${f.previousStatus}`
  }

  return queryParams
}
