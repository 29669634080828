import { Cascader } from 'antd'
import { useEffect, useRef, useState } from 'react'

import { useFormatMessage } from '../../helpers/intl'
import { Option, locations } from '../../helpers/location'
import { useAppState } from '../../state'

import { removeIconSVG } from '../../assets/svg/removeIcon'
import { styled, theme } from '../../theme'
import { buildingSVG } from '../../assets/svg/building'

interface props {
  isCombineLeft?: boolean
  reportsPage?: boolean
  removeSVG?: boolean
  onSearch: (chosenCountries: string[], chosenProvinces: string[]) => void
  loading: boolean
  transparent?: boolean
  customWidth?: number
  dropdownClassName?: string
}

interface styleProps {
  isCombineLeft?: boolean
  removeSVG?: boolean
  transparent?: boolean
  customWidth?: number
}

export const LocationSelectorContainer = styled.div<styleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  flex-direction: row;
  position: relative;
  min-width: ${(props) => (props.customWidth ? props.customWidth : 183)}px;

  .ant-select-selector {
    background: ${(props) => props.theme.colors.cultered};
  }

  svg.locationIcon {
    z-index: 1;
    position: absolute;
    top: 47%;
    right: 11px;
    left: 10.5px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.3s;
    height: 16px;
  }

  .ant-select-clear {
    right: ${(props) => (props.isCombineLeft ? '50px' : '11px')};
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: ${(props) => (props.removeSVG ? '10px' : '30px')};
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    font-size: 12px;
  }

  .ant-cascader-checkbox-inner {
    border-radius: 50%;
  }

  .ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
    background-color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${(props) => props.theme.colors.primary};
    box-shadow: none;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 39.19px;
    ${(props) =>
      props.transparent || props.isCombineLeft ? `border-left-color: transparent` : theme.colors.lightGray};
    ${(props) => props.transparent && !props.isCombineLeft && `border-radius: 0`};
    ${(props) => (!props.transparent && props.isCombineLeft ? `border-radius: 0 48px 48px 0` : '48px')};
    ${(props) => !props.transparent && !props.isCombineLeft && `border-radius: 48px`};
    /* border-radius: ${(props) => (props.isCombineLeft ? '' : '48px')}; */

    /* border-left-color: ${(props) => (props.isCombineLeft ? 'transparent' : theme.colors.lightGray)}; */
    border-right-color: ${(props) => (props.transparent ? 'transparent' : theme.colors.lightGray)};
  }

  svg[data-icon='close']:after {
    content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='red' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 1L1 9M9 9L1 1' stroke='%23242E2C' strokeWidth='2' strokeLinecap='round'/%3E%3C/svg%3E%0A");
    display: block;
    width: 22px;
    height: 10px;
    margin: 10px 5px 0 10px;
  }

  svg.removeIcon {
    width: 7px;
    height: 7px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    padding-top: 0px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-overflow {
    margin-left: ${(props) => (props.removeSVG ? '0px' : '20px')};
  }
`

export const LocationSelector: React.FC<props> = ({
  isCombineLeft,
  reportsPage,
  removeSVG,
  onSearch,
  loading,
  transparent,
  customWidth,
  dropdownClassName,
}) => {
  const { currentUser, language, IsDesktop, IsLaptop } = useAppState()
  const [selectedLocations, setSelectedLocations] = useState<Option[]>([])
  const placeholder = useFormatMessage('dashboard.text.location', 'Location')
  const [locationText, setLocationText] = useState(placeholder)
  const cascaderRef = useRef<HTMLInputElement>(null)
  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()
  const canCombineBorderLeft = (isDesktop || isLaptop) && isCombineLeft

  const onChange = (selectedLocations: any) => {
    const selectedCountries: string[] = []
    const selectedProvinces: string[] = []
    selectedLocations.forEach((selectedLocation: [string, string]) => {
      const [country, province] = selectedLocation
      if (!selectedCountries.includes(country)) {
        selectedCountries.push(country)
      }

      // When only the country clicked, the provinces are not selected
      if (!province) {
        const childrenObj: any = locations.filter((t) => t.value === country).map((t) => t.children)
        const allProvinces = childrenObj[0].map((t: Option) => t.value)
        selectedProvinces.push(...allProvinces)
      } else {
        selectedProvinces.push(province)
      }
    })

    // Sort the selected provinces alphabetically
    selectedProvinces.sort((a, b) => a.localeCompare(b))

    onSearch(selectedCountries, selectedProvinces)
    setSelectedLocations(
      selectedProvinces.map((province) => {
        return { value: province, label: province }
      }),
    )
  }

  const handleOnFocus = () => {
    cascaderRef.current && cascaderRef.current.focus()
  }

  const handleOutFocus = () => {
    setLocationText(placeholder)
    cascaderRef.current && cascaderRef.current.blur()
  }

  useEffect(() => {
    cascaderRef.current && cascaderRef.current.blur()
    setLocationText(placeholder)
  }, [language])

  const renderMultiOptions = (options: Option[]): Option[] => {
    const temp: string[] = selectedLocations.map((obj) => String(obj.value))

    options.forEach((o) => {
      if (o.children) {
        o.children.sort((a, b) => {
          const indexA = temp.indexOf(String(a.value))
          const indexB = temp.indexOf(String(b.value))

          // If both items are in selectedLocations, sort by their order in selectedLocations
          if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB
          }
          // If only one item is in selectedLocations, move it to the top
          if (indexA !== -1) {
            return -1
          }
          if (indexB !== -1) {
            return 1
          }
          // If neither item is in selectedLocations, sort alphabetically by label
          return a.label.localeCompare(b.label)
        })
      }
    })

    return options.map((option) => {
      return {
        ...option,
        label: option.label,
        children: option.children ? renderMultiOptions(option.children) : [],
      }
    })
  }

  useEffect(() => {
    selectedLocations && renderMultiOptions(selectedLocations)
    clearClass()
    const selectedProvinces: any = Array.from(
      document.querySelectorAll(
        ".ant-select-dropdown .ant-cascader-menus .ant-cascader-menu:nth-child(2) li[aria-checked='true']",
      ),
    )

    selectedLocations &&
      Array.isArray(selectedProvinces) &&
      selectedProvinces.map((t: any, i: any) => {
        if (i === selectedProvinces.length - 1) {
          t.classList.add('last-item-selected')
        } else {
          t.classList.remove('last-item-selected')
        }
        return t
      })
  }, [selectedLocations])

  const clearClass = () => {
    const selectedProvinces: Element[] = Array.from(
      document.querySelectorAll('.ant-select-dropdown .ant-cascader-menus .ant-cascader-menu:nth-child(2) li'),
    )
    Array.isArray(selectedProvinces) &&
      selectedProvinces.map((t: Element) => {
        return t.classList.remove('last-item-selected')
      })
  }

  const onClear = () => {
    clearClass()
    cascaderRef.current && cascaderRef.current.blur()
  }

  return currentUser?.role === 'admin' || reportsPage ? (
    <LocationSelectorContainer
      transparent={transparent}
      isCombineLeft={canCombineBorderLeft}
      className={`location-selector ${selectedLocations.length > 1 ? 'multiple' : 'single'}`}
      removeSVG={removeSVG}
      customWidth={customWidth}
    >
      {removeSVG ? null : buildingSVG}

      <Cascader
        disabled={loading}
        placeholder={locationText}
        options={renderMultiOptions(locations)}
        allowClear
        multiple
        showSearch
        onClear={onClear}
        onChange={onChange}
        style={{ width: customWidth ?? 195, minWidth: '100%', lineHeight: '34px' }}
        maxTagCount="responsive"
        onFocus={handleOnFocus}
        onBlur={handleOutFocus}
        ref={cascaderRef}
        removeIcon={removeIconSVG}
        clearIcon={removeIconSVG}
        dropdownClassName={dropdownClassName}
      />
    </LocationSelectorContainer>
  ) : (
    <></>
  )
}
