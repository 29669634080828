import { theme } from '../../theme'

export const PeakShavingSelectArrowDown = (props: any) => (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 5L5 1L1 5"
      stroke={theme.colors.softBlack}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
