import moment from 'moment'
import configProvider from '../../config'
import { addQueryString, buildApiManageUrl } from '../../helpers/url'
import { log } from '../../logger'
import {
  mockChargerById,
  mockChargers,
  mockChargersListingsLoadMgmtResponse,
  mockChargersListingsResponse,
  mockChargersPerTenantResponse,
  mockChargersUptimeResponse,
} from '../../mock/charger-mock'
import { mockChargerDiagnosticFilesResp } from '../../mock/diagnostic-mock'
import { mockAllListings, mockAllListingsResp } from '../../mock/listing-mock'
import { mockTransactions } from '../../mock/transaction-mock'
import { Charger, ChargerDiagnosticsState, ChargerRef } from '../../models/charger'

import { ConnectorRef } from '../../models/connector'
import {
  ChargerDiagnosticsFileResponse,
  ChargersListingsLoadMgmtResponse,
  ChargersListingsResponse,
  ChargersResponse,
  ChargersUptimeForTenantResponse,
  DREventResponse,
  ListingsBaseResponse,
} from '../../models/http'
import { Listing, ListingBase } from '../../models/listing'
import { TenantRef } from '../../models/tenant'
import { Transaction } from '../../models/transaction'
import { TenantPermission } from '../../models/user'
import { getSession } from '../../state/session-store'
import { apiCihClient, apiClient } from './client'
import { mockDREventResponse, returnMock } from './mock'
import { ChargersPerTenantFilter } from 'models/filter'
import { chargersPerTenantQuery } from 'helpers/query/ChargersQuery'
import { chargersUptimeQuery } from 'helpers/query/ChargerUptimeQuery'

export async function GetCharger(chargerId: number, defaultTenant: TenantPermission | undefined): Promise<Charger> {
  let url = `/chargers/${chargerId}`

  let queryParams: { [key: string]: string } = {}

  if (defaultTenant?.id) {
    queryParams['tenant_id'] = `${defaultTenant.id}`
    url = addQueryString(url, queryParams)
  }

  log('fetching a charger', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockChargerById[chargerId])
  }
  return apiClient(url, {})
}

export async function GetChargers(term?: string, tenant?: TenantRef, tenants?: TenantRef[]): Promise<ChargerRef[]> {
  let url = '/chargers/find'

  let queryParams: { [key: string]: string } = {}

  if (term) {
    queryParams['term'] = term
  }

  if (tenant) {
    queryParams['tenant_id'] = `${tenant.id}`
  }

  if (tenants && tenants.length > 0) {
    queryParams['tenant_id'] = `${tenants.map((t) => t.id)}`
  }

  url = addQueryString(url, queryParams)

  if (configProvider.config.mockEnabled) {
    return returnMock(mockChargers)
  }

  return apiClient(url, {})
}

export async function GetChargersAndItsListings(tenantId: number, page: number): Promise<ChargersListingsResponse> {
  const url = `/chargers/listings?tenant_id=${tenantId}&page=${page}`

  if (configProvider.config.mockEnabled) {
    return returnMock(mockChargersListingsResponse(tenantId))
  }

  return apiClient(url, {})
}

export async function GetChargersUptimeForTenant(
  tenantId: number,
  page: number,
  startDate: moment.Moment,
  endDate: moment.Moment,
): Promise<ChargersUptimeForTenantResponse> {
  let url = '/tenants/listing-insight'

  const queryParams = chargersUptimeQuery({ page, tenant: { id: tenantId } }, startDate, endDate)

  url = addQueryString(url, queryParams)

  log('fetching chargers uptime for tenant', { url })

  if (configProvider.config.mockEnabled) {
    return returnMock(mockChargersUptimeResponse(tenantId, page, startDate, endDate))
  }

  return apiCihClient(url, {})
}

export async function GetChargersListingsLoadMgmt(tenantId: number): Promise<ChargersListingsLoadMgmtResponse> {
  const url = `/chargers/listings?tenant_id=${tenantId}`

  if (configProvider.config.mockEnabled) {
    return returnMock(mockChargersListingsLoadMgmtResponse(tenantId))
  }

  return apiClient(url, {})
}

export async function RequestChargerDiagnostics(
  charger: ChargerRef,
  dateRange: [moment.Moment | null, moment.Moment | null],
  defaultTenant?: TenantPermission,
): Promise<void> {
  const url = `/diagnostics/${charger.id}/get`
  let queryParams: { [key: string]: string } = {}
  queryParams['start_time'] = `${dateRange[0]}`
  queryParams['end_time'] = `${dateRange[1]}`

  if (defaultTenant) {
    queryParams['tenant_id'] = `${defaultTenant.id}`
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify(queryParams),
  })
}

export async function GetChargerDiagnosticsState(
  charger: ChargerRef,
  defaultTenant?: TenantPermission,
): Promise<ChargerDiagnosticsState> {
  let url = `/diagnostics/${charger.id}/state`
  let queryParams: { [key: string]: string } = {}
  if (defaultTenant) {
    queryParams['tenant_id'] = `${defaultTenant.id}`
  }

  url = addQueryString(url, queryParams)
  return apiClient(url, {})
}

export async function GetChargerDiagnosticsFiles(
  page: number,
  charger: ChargerRef,
  defaultTenant?: TenantPermission,
): Promise<ChargerDiagnosticsFileResponse> {
  let url = `/diagnostics/${charger.id}/files`
  let queryParams: { [key: string]: string } = {
    page: `${page}`,
  }
  if (defaultTenant) {
    queryParams['tenant_id'] = `${defaultTenant.id}`
  }

  url = addQueryString(url, queryParams)

  if (configProvider.config.mockEnabled) {
    return returnMock(mockChargerDiagnosticFilesResp)
  }
  return apiClient(url, {})
}

/**
 * Returns the diagnostics file for the given charger with the given name as a Blob.
 *
 * It throws an error if the file was not found on the server or the server could not be reached.
 *
 * @param charger
 * @param filename
 *
 * @returns A Blob with the file contents
 */
export async function GetChargerDiagnosticsFile(charger: ChargerRef, filename: string): Promise<Blob> {
  const session = getSession()
  if (!session) throw new Error('not logged in')

  const url = `${buildApiManageUrl()}/diagnostics/${charger.id}/download?filename=${filename}`
  const response = await fetch(url, { headers: new Headers({ Authorization: `Bearer ${session.token}` }) })

  if (response.status !== 200) throw new Error('diagnostics file request failed')

  return response.blob()
}

export async function GetChargerListings(
  charger: ChargerRef,
  defaultTenant?: TenantPermission,
): Promise<ListingsBaseResponse> {
  let url = `/chargers/${charger.id}/listings`
  if (configProvider.config.mockEnabled) {
    return returnMock(mockAllListingsResp())
  }
  let queryParams: { [key: string]: string } = {}

  if (defaultTenant) {
    queryParams['tenant_id'] = `${defaultTenant.id}`
  }

  url = addQueryString(url, queryParams)

  log('updating listing', { url: url })
  return apiClient(url, {})
}

export async function GenerateDREvent(
  start_time: string,
  end_time: string,
  charger: Charger,
  defaultTenant?: TenantPermission,
): Promise<DREventResponse> {
  let url = '/chargers/reserve_event'
  if (configProvider.config.mockEnabled) {
    return mockDREventResponse
  }

  let queryParams: { [key: string]: string } = {
    start_time: start_time,
    end_time: end_time,
    charger_id: `${charger.id}`,
    connector_id: `${charger.connectors[0].connectorId}`,
  }

  if (defaultTenant) {
    queryParams['tenant_id'] = `${defaultTenant.id}`
  }

  url = addQueryString(url, queryParams)

  log('update simulator log', { url: url })
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify(queryParams),
  })
}

export async function UpdateChargerListing(charger: Charger, listing: Listing): Promise<ListingBase> {
  const url = `/chargers/${charger.id}/listings/${listing.id}`
  log('updating listing', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockAllListings[0])
  }
  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      listing: {
        price_type: listing.price.priceType,
        price_per_unit_cents: listing.price.pricePerUnitCents,
      },
    }),
  })
}

export async function GetActiveTransaction(
  charger: Charger,
  connector: ConnectorRef,
): Promise<Transaction | undefined> {
  const url = `/chargers/${charger.id}/connectors/${connector.id}/active`
  log('remote clear cache charger', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockTransactions[0])
  }
  return apiClient(url, {})
}

export async function GetChargersPerTenant(filters: ChargersPerTenantFilter): Promise<ChargersResponse> {
  let url = '/listings/tenant_listings'

  const queryParams = chargersPerTenantQuery(filters)

  url = addQueryString(url, queryParams)

  log('fetching chargers per tenant', { url })

  if (configProvider.config.mockEnabled) {
    return returnMock(mockChargersPerTenantResponse(filters))
  }
  return apiClient(url, {})
}
