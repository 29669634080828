import { Tabs } from 'antd'
import { TabsWrapper } from '../../atom/chargers'
import { BackgroundContainer } from '../../atom/layout/backgroundContainer'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import DemandManagementTabPrograms from './tab/demand-management-tab-programs'
import { usePeakShavingTranslation } from 'hooks/translation/usePeakShavingTranslation'
import DemandManagementTabParticipants from './tab/demand-management-tab-participants'
import DemandManagementTabEvents from './tab/demand-management-tab-events'

const DemandManagementBasePage: React.FC = () => {
  document.querySelector('body')?.classList.add('redesignActive')

  const { eventsText, participantsText, programTabTitleText } = usePeakShavingTranslation()
  return (
    <BackgroundContainer>
      <TabsWrapper>
        <Tabs defaultActiveKey="events">
          <Tabs.TabPane key="events" tab={eventsText}>
            <DemandManagementTabEvents />
          </Tabs.TabPane>
          <Tabs.TabPane key="participants" tab={participantsText}>
            <DemandManagementTabParticipants />
          </Tabs.TabPane>
          <Tabs.TabPane key="programs" tab={programTabTitleText}>
            <DemandManagementTabPrograms />
          </Tabs.TabPane>
        </Tabs>
      </TabsWrapper>
    </BackgroundContainer>
  )
}

export const DemandManagementPage = withAuthenticatedLayout(DemandManagementBasePage)
