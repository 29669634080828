import { Form, Input, Select } from 'antd'

import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'
import { ServiceStatusReasons } from 'models/listing'
import { ServiceStatusSectionHeader } from './service-status-section-header'

interface props {
  loading: boolean
  currentReasons: ServiceStatusReasons[]
}

export const IssueDetailsSection: React.FC<props> = ({ loading, currentReasons }) => {
  const { characterMaxLimitText, issueDetailsText, issueSymptomText, detailsText } = useListingBasicTrans()

  const { selectReasonForStatusChangeText } = useSelectorTranslation()

  const title = issueDetailsText

  return (
    <ServiceStatusSectionHeader title={title}>
      <Form.Item name="root_cause_id" label={issueSymptomText}>
        <Select placeholder={selectReasonForStatusChangeText} disabled={loading} loading={loading}>
          {currentReasons.map((option) => (
            <Select.Option value={option.id}>{option.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="event_notes"
        rules={[{ required: false, max: 255, message: characterMaxLimitText }]}
        label={detailsText}
        className="service-status"
      >
        <Input.TextArea placeholder={detailsText} />
      </Form.Item>
    </ServiceStatusSectionHeader>
  )
}
