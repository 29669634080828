import { useFormatMessage } from '../../helpers/intl'

export const useIncidencesTranslation = () => {
  const zendeskIdText = useFormatMessage('dashboard.incidences.zendeskId', 'Zendesk ID')
  const listingTitleText = useFormatMessage('dashboard.incidences.listingTitle', 'Listing Title')
  const rootCauseText = useFormatMessage('dashboard.incidences.rootCause', 'Root Cause')
  const rootCausesText = useFormatMessage('dashboard.incidences.rootCauses', 'Root Causes')
  const downtimeText = useFormatMessage('dashboard.incidences.downtime', 'Downtime')
  const resolutionText = useFormatMessage('dashboard.incidences.resolution', 'Resolution')
  const resolutionsText = useFormatMessage('dashboard.incidences.resolutions', 'Resolutions')
  const startTimeText = useFormatMessage('dashboard.incidences.startTIme', 'Start Time')
  const endTimeText = useFormatMessage('dashboard.incidences.endTime', 'End Time')
  const durationText = useFormatMessage('dashboard.incidences.duration', 'Duration')
  const includeText = useFormatMessage('dashboard.incidences.include', 'Included')
  const excludeText = useFormatMessage('dashboard.incidences.exclude', 'Excluded')
  const editIncidenceText = useFormatMessage('dashboard.incidences.editIncidence', 'Edit Incidence')
  const updateSuccessText = useFormatMessage('dashboard.incidence.updateSuccess', 'Incidence update successful')

  return {
    zendeskIdText,
    listingTitleText,
    rootCauseText,
    rootCausesText,
    downtimeText,
    resolutionText,
    resolutionsText,
    startTimeText,
    endTimeText,
    durationText,
    includeText,
    excludeText,
    editIncidenceText,
    updateSuccessText,
  }
}
