import { Button, PageHeader } from 'antd'

import { plusSignSVG } from 'assets/svg/plusSign'
import { BackgroundContainer } from 'atom/layout/backgroundContainer'
import { AlertError } from 'components/error'
import { usePeakShavingTranslation } from 'hooks/translation/usePeakShavingTranslation'
import { useDemandManagementEvents } from 'hooks/useDemandManagementEvents'
import { DemandManagementEventModal } from '../modal/demand-management-event-modal'
import { RemoveWarningModal } from '../modal/remove-warning-modal'
import EventsSelectors from '../selectors/eventsSelectors'
import TabTable from '../table/tab-table'

interface DemandManagementTabEventsProps {}

const DemandManagementTabEvents: React.FC<DemandManagementTabEventsProps> = () => {
  document.querySelector('body')?.classList.add('redesignActive')

  const {
    error,
    loading,
    pagination,
    filter,
    events,
    columns,
    selectedEvent,
    showWarning,
    startDate,
    endDate,
    warningInfo,
    createNewEventModalVisible,
    fetchDataWithQuery,
    setFilter,
    cancelRemoval,
    handleEventModal,
    handleChangeDate,
    handlePaginationChange,
    onSubmit,
  } = useDemandManagementEvents()

  const { newEventText, eventsText } = usePeakShavingTranslation()

  return (
    <BackgroundContainer>
      <RemoveWarningModal
        visible={showWarning}
        title={warningInfo.title}
        description={warningInfo.description}
        onCancel={cancelRemoval}
        onSubmit={warningInfo.function}
      />
      {(createNewEventModalVisible || !!selectedEvent) && (
        <DemandManagementEventModal
          visible={createNewEventModalVisible}
          onCancel={handleEventModal}
          onSubmit={onSubmit}
          initialValues={selectedEvent}
        />
      )}
      <PageHeader
        title={
          <EventsSelectors
            onChangeDate={handleChangeDate}
            onFilter={setFilter}
            onFetch={fetchDataWithQuery}
            {...{ loading, filter, startDate, endDate }}
          />
        }
        extra={
          <Button
            key="new-program-btn"
            loading={loading}
            size="large"
            type="primary"
            disabled={loading}
            icon={plusSignSVG}
            onClick={handleEventModal}
          >
            <span className="regular-cap">{newEventText}</span>
          </Button>
        }
      />
      <AlertError error={error} />
      <TabTable
        title={eventsText}
        loading={loading}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        dataSource={events}
        columns={columns}
        rowHeightDynamic
      />
    </BackgroundContainer>
  )
}

export default DemandManagementTabEvents
