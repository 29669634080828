import { Button, Col, Row, Space } from 'antd'
import { useAppState } from 'state'

import { ReusableSelector } from 'components/reusableSelector/selector'

import { useSelector } from 'hooks/useSelector'

import { PeakShavingEventFilter } from 'models/filter'

import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'

import { magnifyBlackSVG } from 'assets/svg/magnify'
import { ButtonIcon } from 'atom/button'
import { LineBreak } from 'atom/line-break'
import { CustomDateRangePicker } from 'components/redesign/datepicker'
import { useCalendarTransactions } from 'hooks/translation/useCalendarTranslation'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { usePeakShavingTranslation } from 'hooks/translation/usePeakShavingTranslation'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'
import { ListingRef } from 'models/listing'
import { PeakShavingProgram } from 'models/peak-shaving'
import { Moment } from 'moment'
import { FindListings } from 'services/data-provider/listing'
import { FindAggregator, GetAllPeakShavingPrograms } from 'services/data-provider/peak-shaving'
import { FindTenants } from 'services/data-provider/tenants'

interface props {
  startDate: Moment
  endDate: Moment
  onChangeDate: (dates: Moment[]) => void
  loading: boolean
  filter: PeakShavingEventFilter
  dropdownClassName?: string
  onFilter: (filters: PeakShavingEventFilter) => void
  onFetch: () => void
}

const EventsSelectors: React.FC<props> = ({
  loading,
  filter,
  dropdownClassName,
  onFilter,
  onFetch,
  startDate,
  endDate,
  onChangeDate,
}) => {
  const { IsDesktop, IsLaptop } = useAppState()

  const { searchText } = useGeneralTranslation()
  const {
    generalDebounceFetcher,
    renderMultiOptions,
    multiHandlePlaceholder,
    multiHandleChange,
    renderSingleOption,
    handleSingleSelectChange,
  } = useSelector()

  const { locationTextPlaceholder } = useSelectorTranslation()
  const { listingTitlesText } = useListingBasicTrans()
  const { programTabProgramSelectorText, aggregatorNameText } = usePeakShavingTranslation()

  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const { calendarRanges } = useCalendarTransactions()

  const calendar = (
    <CustomDateRangePicker
      startDate={startDate}
      endDate={endDate}
      onChangeDate={onChangeDate}
      calendarRanges={calendarRanges}
      disabled={loading}
      enableFutureDate={true}
    />
  )

  const renderSitesSelector = (
    <ReusableSelector
      showSearch
      nativeSearch
      isSingle={false}
      clearSearchAfterSelect
      onClear={() => onFilter({ ...filter, tenants: undefined })}
      placeholder={locationTextPlaceholder}
      dropDownList={[]}
      defaultValues={filter?.tenants}
      fieldForValue="combineName"
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={true}
      handlePlaceholder={multiHandlePlaceholder('combineName', locationTextPlaceholder)}
      debounceFetcher={generalDebounceFetcher((value) => FindTenants(value), true, undefined, 1)}
      dropdownClassName={dropdownClassName}
      combineType="right"
      handleOptionChange={multiHandleChange('combineName')}
      renderOption={renderMultiOptions({
        val: 'combineName',
        label: 'combineName',
      })}
      onOptionsChange={(tenants) => {
        onFilter({ ...filter, tenants })
      }}
      searchButtonMerged
      customWidth="200px"
      placeholderWidth="180px"
    />
  )

  const renderListingTitlesSelector = (
    <ReusableSelector
      showSearch
      nativeSearch
      isSingle={false}
      clearSearchAfterSelect
      onClear={() => onFilter({ ...filter, listingTitle: undefined })}
      placeholder={listingTitlesText}
      dropDownList={[]}
      defaultValues={filter?.listingTitle}
      fieldForValue="title"
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={true}
      handlePlaceholder={multiHandlePlaceholder('title', listingTitlesText)}
      debounceFetcher={generalDebounceFetcher((value) => FindListings(value), true, undefined, 2)}
      dropdownClassName={dropdownClassName}
      combineType="both"
      handleOptionChange={multiHandleChange('title')}
      renderOption={renderMultiOptions({
        val: 'title',
        label: 'title',
      })}
      onOptionsChange={(listings: ListingRef[]) => {
        onFilter({ ...filter, listingTitle: listings.map(({ title }) => title) })
      }}
      searchButtonMerged
      customWidth="200px"
      placeholderWidth="120px"
    />
  )

  const renderAggregatorSelector = (
    <ReusableSelector
      showSearch
      onClear={() => onFilter({ ...filter, aggregatorName: undefined })}
      isSingle
      showArrow={false}
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={true}
      debounceFetcher={generalDebounceFetcher((value) => FindAggregator(value), true, 'data')}
      dropdownClassName={dropdownClassName}
      placeholder={aggregatorNameText}
      combineType="both"
      onOptionsChange={(selectedValue) => {
        onFilter({ ...filter, aggregatorName: selectedValue?.aggregatorName })
      }}
      handleOptionChange={handleSingleSelectChange('aggregatorName')}
      renderOption={renderSingleOption(
        {
          val: 'aggregatorName',
          label: 'aggregatorName',
        },
        false,
      )}
      customWidth="200px"
      stopClearSearchValue={true}
    />
  )

  const renderProgramsSelector = (
    <ReusableSelector
      showSearch
      nativeSearch
      isSingle={false}
      onClear={() => onFilter({ ...filter, programs: undefined })}
      clearSearchAfterSelect
      placeholder={programTabProgramSelectorText}
      dropDownList={[]}
      showMagnifySVG={false}
      fieldForValue="id"
      maxTagCount={0}
      isDebounceFetcher={true}
      handlePlaceholder={multiHandlePlaceholder('name', programTabProgramSelectorText)}
      debounceFetcher={generalDebounceFetcher(
        (programName) =>
          GetAllPeakShavingPrograms({
            programName,
          }),
        true,
        'data',
      )}
      dropdownClassName={dropdownClassName}
      combineType="left"
      handleOptionChange={multiHandleChange('id')}
      renderOption={renderMultiOptions({
        val: 'id',
        label: 'name',
      })}
      onOptionsChange={(programs: PeakShavingProgram[]) => {
        onFilter({ ...filter, programs: programs.map(({ id }) => id) })
      }}
      searchButtonMerged
      customWidth="200px"
      placeholderWidth="120px"
    />
  )

  return (
    <>
      {isDesktop || isLaptop ? (
        <Space wrap size={[0, 6]} style={{ display: 'flex' }}>
          {calendar}
          <div style={{ marginRight: '1rem' }}></div>
          {renderSitesSelector}
          <LineBreak />
          {renderListingTitlesSelector}
          <LineBreak />
          {renderAggregatorSelector}
          <LineBreak />
          {renderProgramsSelector}
          <ButtonIcon icon={magnifyBlackSVG} loading={loading} disabled={loading} fetchData={onFetch} withinSearchBar />
        </Space>
      ) : (
        <Row gutter={[8, 8]}>
          <Col xs={24} md={12}>
            {calendar}
          </Col>
          <Col xs={24} md={12}>
            {renderSitesSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderListingTitlesSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderAggregatorSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderProgramsSelector}
          </Col>
          <Col span={24}>
            <Button block type="default" disabled={loading} loading={loading} onClick={onFetch}>
              {searchText}
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default EventsSelectors
