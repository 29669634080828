import { TenantRef } from '../../models/tenant'
import { useAppState } from '../../state'
import { ListingRef } from '../../models/listing'
import { ChargerSelectorRedesign } from '../../components/redesign/charger-selector-redesign'
import { Button, Checkbox, Col, Row, Select, Space } from 'antd'
import { LineBreak } from '../../atom/line-break'
import { LocationSelector } from '../../components/redesign/location-selector'
import { ButtonIcon, ButtonIconCircle } from '../../atom/button'
import { magnifyBlackSVG } from '../../assets/svg/magnify'
import { ModifyButtonWrapper } from '../../atom/chargers'
import { editGreySVG } from '../../assets/svg/edit'
import { ChargerRef } from '../../models/charger'
import { ConnectorServiceStatus, ConnectorStatus, connectorStatus } from '../../models/connector'
import { useGeneralTranslation } from '../../hooks/translation/useGeneralTranslation'
import { useChargerTranslation } from '../../hooks/translation/useChargerTranslation'
import { theme } from 'theme'
import { useSelector } from 'hooks/useSelector'
import { ReactElement } from 'react'
import { renderFormatMessage } from 'helpers/intl'
import { renderConnectionMessage } from 'helpers/status'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { useConnectorTranslation } from 'hooks/translation/useConnectorTranslation'
import { debounce } from 'lodash'
import { MasterAccountRef } from 'models/master-account'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'
import { useMasterAccountSelector } from 'hooks/useMasterAccountSelector'

interface props {
  loading: boolean
  selectedCharger: ChargerRef[]
  defaultServiceStatus: ConnectorServiceStatus[] | undefined
  connectorServiceStatus: string[]
  vendors: string[]
  tenants: TenantRef[]
  defaultTenantRef: TenantRef | undefined
  filteredTenant: TenantRef | undefined
  defaultOCPPStatus: ConnectorStatus[] | undefined
  allListings: ListingRef[]
  setListings: (listings: ListingRef[]) => void
  setAllListings: (listings: ListingRef[]) => void
  setTenants: (tenants: TenantRef[]) => void
  setCharger: (charger: ChargerRef | undefined) => void
  setSelectedVendors: (selectedVendors: string[]) => void
  setServiceStatuses: (serviceStatuses: ConnectorServiceStatus[] | undefined) => void
  setOCPPStatuses: (ocppStatuses: ConnectorStatus[] | undefined) => void
  renderLocationSelector: (chosenCountries: string[], chosenProvinces: string[]) => void
  getConnectors: () => void
  setShowChargerModal: (showChargerModal: boolean) => void
  setClear: (clear: boolean) => void
  fetchDataWithQuery: () => void
  dropdownClassName?: string
  setMasterAccount: (masterAccount: MasterAccountRef | undefined) => void
  onTenantSelection: (tenants: TenantRef[]) => void
}

const ChargersSelectors: React.FC<props> = ({
  loading,
  vendors,
  defaultTenantRef,
  tenants,
  filteredTenant,
  connectorServiceStatus,
  defaultServiceStatus,
  defaultOCPPStatus,
  selectedCharger,
  allListings,
  setAllListings,
  setListings,
  setTenants,
  setCharger,
  setSelectedVendors,
  setServiceStatuses,
  setOCPPStatuses,
  renderLocationSelector,
  getConnectors,
  setShowChargerModal,
  setClear,
  fetchDataWithQuery,
  dropdownClassName,
  setMasterAccount,
  onTenantSelection,
}) => {
  const { searchText } = useGeneralTranslation()
  const { modifyText } = useChargerTranslation()
  const { IsDesktop, IsLaptop } = useAppState()
  const { currentUser } = useAppState()
  const { accountNameText, locationTextPlaceholder } = useSelectorTranslation()
  const { options, handleMasterAccountSelectChange, renderMasterAccountOption } = useMasterAccountSelector(
    setMasterAccount,
  )
  const { listingTitlesText, vendorstext, serviceStatusText } = useListingBasicTrans()
  const { connectorStatusText } = useConnectorTranslation()
  const {
    multiTenantDebounceFetcher,
    multiListingTitleAndIdDebounceFetcher,
    multiTenantHandleChange,
    multiListingTitleHandlePlaceholder,
    multiTenantHandlePlaceholder,
    multiTenantHandleValue,
    multiServiceStatusPlaceholder,
    multiServiceStatusHandleChange,
    multiVendorPlaceholder,
    multiOCPPStatusPlaceholder,
    multiListingTitleAndIdHandleChange,
    multiListingTitleAndIdHandleValue,
    multiVendorsHandleChange,
    multiOCPPStatusHandleChange,
  } = useSelector()
  const isAdmin = currentUser?.role === 'admin'
  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const renderMultiTenantOption = (
    { id, displayName, name: ocppName, combineName }: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={id} value={combineName} label={displayName ? displayName : ocppName}>
      <Checkbox
        onClick={handleCheckboxClick}
        checked={selectedOptions.findIndex((selectedTenant) => selectedTenant.combineName === combineName) > -1}
      >
        {displayName && (displayName !== '' || displayName !== null) ? displayName : ocppName}
      </Checkbox>
    </Select.Option>
  )

  const renderMultiListingIdOption = (
    option: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={option.id} value={option.id} label={option.title}>
      <Checkbox
        onClick={handleCheckboxClick}
        checked={selectedOptions.findIndex((listing) => listing.title === option.title) > -1}
      >
        {option.title}
      </Checkbox>
    </Select.Option>
  )

  const renderMultiListingSelector = () => (
    <ReusableSelector
      showSearch
      onOptionsChange={(listingOption: ListingRef[]) => setListings(listingOption)}
      onClear={() => setClear(true)}
      combineType="both"
      customWidth="160px"
      isSingle={false}
      placeholder={listingTitlesText}
      dropDownList={allListings}
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={true}
      debounceFetcher={multiListingTitleAndIdDebounceFetcher}
      showArrow={false}
      handlePlaceholder={multiListingTitleHandlePlaceholder}
      handleOptionChange={multiListingTitleAndIdHandleChange}
      renderOption={renderMultiListingIdOption}
      handleValue={multiListingTitleAndIdHandleValue}
    />
  )

  const renderTenantSelector = () => (
    <ReusableSelector
      showSearch
      onOptionsChange={debounce((tenants) => onTenantSelection(tenants), 1000)}
      onClear={() => setTenants([])}
      combineType="right"
      customWidth="195px"
      isSingle={false}
      placeholder={locationTextPlaceholder}
      dropDownList={tenants}
      showMagnifySVG={true}
      maxTagCount={0}
      isDebounceFetcher={true}
      debounceFetcher={multiTenantDebounceFetcher}
      showArrow={false}
      handlePlaceholder={multiTenantHandlePlaceholder}
      handleOptionChange={multiTenantHandleChange}
      handleValue={multiTenantHandleValue}
      renderOption={renderMultiTenantOption}
      dropdownClassName={dropdownClassName}
    />
  )

  const renderMasterAccountSelector = () => (
    <ReusableSelector
      showSearch={true}
      onClear={() => setMasterAccount(undefined)}
      isSingle={true}
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={false}
      handlePlaceholder={undefined}
      debounceFetcher={undefined}
      dropdownClassName={dropdownClassName}
      placeholder={accountNameText}
      dropDownList={options}
      selectAllOptions={true}
      combineType="both"
      customWidth="170px"
      showArrow={false}
      stopClearSearchValue={true}
      handleOptionChange={handleMasterAccountSelectChange}
      renderOption={renderMasterAccountOption}
    />
  )

  const renderChargerSelector = () => (
    <ChargerSelectorRedesign
      defaultTenant={filteredTenant}
      tenants={tenants}
      onChargerSelected={(c) => setCharger(c)}
      onClear={() => setCharger(undefined)}
      onSearch={(c) => setCharger(c)}
      isCombineRightAndLeft
      customHeight={'39px'}
      removeSVG
      dropdownClassName={dropdownClassName}
    />
  )

  const renderMultiVendorOption = (
    option: string,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={option} value={option} label={option}>
      <Checkbox onClick={handleCheckboxClick} checked={selectedOptions.includes(option)}>
        {option}
      </Checkbox>
    </Select.Option>
  )

  const renderMultiVendorSelector = () => (
    <ReusableSelector
      showSearch
      onOptionsChange={(opts) => setSelectedVendors(opts)}
      onClear={() => setSelectedVendors([])}
      combineType="both"
      customWidth="140px"
      isSingle={false}
      placeholder={vendorstext}
      dropdownClassName={dropdownClassName}
      renderOption={renderMultiVendorOption}
      dropDownList={vendors}
      showMagnifySVG={false}
      maxTagCount={0}
      nativeSearch
      isDebounceFetcher={false}
      debounceFetcher={undefined}
      handlePlaceholder={multiVendorPlaceholder}
      handleOptionChange={multiVendorsHandleChange}
    />
  )

  const renderServiceStatusOption = (
    option: string,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    const translateOpt = renderFormatMessage(renderConnectionMessage(option), option)
    return (
      <Select.Option key={option} value={option} label={option}>
        <Checkbox onClick={handleCheckboxClick} checked={selectedOptions.includes(option)}>
          {translateOpt}
        </Checkbox>
      </Select.Option>
    )
  }

  const renderServiceStatusSelector = () => (
    <ReusableSelector
      showSearch
      onOptionsChange={(opts) => setServiceStatuses(opts)}
      onClear={() => setServiceStatuses(undefined)}
      combineType="both"
      customWidth="140px"
      isSingle={false}
      placeholder={serviceStatusText}
      dropdownClassName={dropdownClassName}
      defaultValues={defaultServiceStatus}
      renderOption={renderServiceStatusOption}
      dropDownList={connectorServiceStatus.map((v) => v)}
      showMagnifySVG={false}
      maxTagCount={0}
      nativeSearch
      isDebounceFetcher={false}
      debounceFetcher={undefined}
      handlePlaceholder={multiServiceStatusPlaceholder}
      handleOptionChange={multiServiceStatusHandleChange}
    />
  )

  const renderOCPPStatusOption = (
    option: string,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    const translateOpt = renderFormatMessage(renderConnectionMessage(option), option)
    return (
      <Select.Option key={option} value={option} label={option}>
        <Checkbox onClick={handleCheckboxClick} checked={selectedOptions.includes(option)}>
          {translateOpt}
        </Checkbox>
      </Select.Option>
    )
  }

  const renderOCPPStatusSelector = () => (
    <ReusableSelector
      showSearch
      onOptionsChange={(opts: ConnectorStatus[]) => setOCPPStatuses(opts)}
      onClear={() => setOCPPStatuses(undefined)}
      combineType="both"
      customWidth="140px"
      isSingle={false}
      placeholder={connectorStatusText}
      dropdownClassName={dropdownClassName}
      defaultValues={defaultOCPPStatus}
      renderOption={renderOCPPStatusOption}
      dropDownList={connectorStatus.map((v) => v)}
      showMagnifySVG={false}
      maxTagCount={0}
      nativeSearch
      isDebounceFetcher={false}
      debounceFetcher={undefined}
      handlePlaceholder={multiOCPPStatusPlaceholder}
      handleOptionChange={multiOCPPStatusHandleChange}
    />
  )

  return (
    <>
      {isDesktop || isLaptop ? (
        <Space wrap size={[0, 6]} style={{ display: 'flex' }}>
          {renderTenantSelector()}
          {isAdmin && (
            <>
              <LineBreak />
              {renderMasterAccountSelector()}
            </>
          )}
          <LineBreak />
          {renderMultiListingSelector()}
          <LineBreak />
          {renderChargerSelector()}
          <LineBreak />
          {renderMultiVendorSelector()}
          <LineBreak />
          {renderServiceStatusSelector()}
          <LineBreak />
          {renderOCPPStatusSelector()}
          <LineBreak />
          <LocationSelector reportsPage isCombineLeft removeSVG onSearch={renderLocationSelector} loading={loading} />
          <ButtonIcon
            icon={magnifyBlackSVG}
            loading={loading}
            disabled={loading}
            fetchData={fetchDataWithQuery}
            withinSearchBar
          />
          {selectedCharger && selectedCharger.length >= 1 ? (
            <ModifyButtonWrapper>
              <ButtonIconCircle
                fetchData={() => setShowChargerModal(true)}
                loading={false}
                disabled={loading}
                icon={editGreySVG}
                primaryColor={theme.colors.white}
                secondaryColor={theme.colors.white}
                borderColor={theme.colors.lightGray}
              />
            </ModifyButtonWrapper>
          ) : null}
        </Space>
      ) : (
        <Row gutter={[8, 8]}>
          <Col xs={24} md={12}>
            {renderTenantSelector()}
          </Col>
          {isAdmin && (
            <Col xs={24} md={12}>
              {renderMasterAccountSelector()}
            </Col>
          )}
          <Col xs={24} md={12}>
            {renderMultiListingSelector()}
          </Col>
          <Col xs={24} md={12}>
            {renderChargerSelector()}
          </Col>
          <Col xs={24} md={12}>
            {renderMultiVendorSelector()}
          </Col>
          <Col xs={24} md={12}>
            {renderServiceStatusSelector()}
          </Col>
          <Col xs={24} md={12}>
            {renderOCPPStatusSelector()}
          </Col>
          <Col xs={24} md={12}>
            <LocationSelector reportsPage isCombineLeft onSearch={renderLocationSelector} loading={loading} />
          </Col>
          <Button block type="primary" disabled={loading} onClick={fetchDataWithQuery} style={{ margin: '0 5px' }}>
            {searchText}
          </Button>
          {selectedCharger && selectedCharger.length >= 1 ? (
            <Button
              block
              type="primary"
              disabled={loading}
              onClick={() => setShowChargerModal(true)}
              style={{ margin: '0 5px' }}
            >
              {modifyText}
            </Button>
          ) : null}
        </Row>
      )}
    </>
  )
}

export default ChargersSelectors
