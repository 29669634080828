import React from 'react'

import { Button, Form, Modal, Row, Space, Spin } from 'antd'
import { useDemandManagementEventDetail } from 'hooks/useDemandManagementEventDetail'
import { closeSVG } from '../../../assets/svg/close'
import { AlertError } from '../../../components/error'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { styled } from '../../../theme'
import { CurtailmentsDetailForm } from '../form/curtailments-detail-form'
import { EventDetailsForm } from '../form/event-details-form'
import { PeakShavingEvent } from 'models/peak-shaving'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
  initialValues?: PeakShavingEvent
}

const ProgramDetailsContainer = styled.div`
  label > p {
    margin-bottom: 0;
  }
  .ant-picker-input > input {
    font-weight: 400;
    line-height: 19px;
  }

  .ant-select-disabled .ant-select-selector {
    background-color: #f1f1f1 !important;
    border-radius: 33px;
  }
`

const formItemLayout = {
  labelCol: {
    md: {
      span: 6,
    },
    lg: {
      span: 4,
    },
    xl: {
      span: 7,
    },
    xxl: {
      span: 7,
    },
  },
  wrapperCol: {
    md: {
      span: 12,
      offset: 2,
    },
    lg: {
      span: 10,
    },
    xl: {
      span: 12,
    },
    xxl: {
      span: 12,
    },
  },
}

export const DemandManagementEventModal: React.FC<props> = ({ visible, onCancel, onSubmit, initialValues }) => {
  const { publishText, cancelText, createEventText, editEventText } = usePeakShavingTranslation()

  const {
    loading,
    disabled,
    form,
    error,
    initialCurtailments,
    formInitialValues,
    initialValueLoading,
    updatedValues,
    onSelect,
    curtailmentsUpdate,
    onUpdateValues,
    handleCancel,
    handleFormSubmit,
    validateTimeRange,
  } = useDemandManagementEventDetail(onSubmit, onCancel, initialValues)

  return initialValueLoading ? null : (
    <Modal
      title={<h5 className="heading-05-regular">{initialValues ? editEventText : createEventText}</h5>}
      visible={visible}
      confirmLoading={loading}
      footer={
        <Space>
          <Button type="ghost" onClick={handleCancel} loading={loading}>
            {cancelText}
          </Button>
          <Button type="primary" onClick={handleFormSubmit} disabled={disabled} loading={loading}>
            {publishText}
          </Button>
        </Space>
      }
      onCancel={handleCancel}
      width={1032}
      closeIcon={closeSVG}
      className={`createNewEventModalContainer ${error ? 'ant-modal-error' : ''}`}
    >
      <AlertError error={error} closable />
      <Spin spinning={loading}>
        <ProgramDetailsContainer>
          <Form
            {...formItemLayout}
            form={form}
            style={{ marginTop: '2rem' }}
            initialValues={formInitialValues}
            onValuesChange={onUpdateValues}
          >
            <Row>
              <EventDetailsForm {...{ form, formInitialValues, updatedValues, onSelect, initialValues }} />

              <CurtailmentsDetailForm
                {...{
                  initialCurtailments,
                  formInitialValues,
                  updatedValues,
                  curtailmentsUpdate,
                  validateTimeRange,
                  initialValues,
                }}
              />
            </Row>
          </Form>
        </ProgramDetailsContainer>
      </Spin>
    </Modal>
  )
}
