import { useEffect, useState } from 'react'
import { Dropdown, Menu } from 'antd'
import moment from 'moment'
import { ColumnsType } from 'antd/lib/table'

import { SwtchError } from 'models/error'
import { PaginationMeta } from 'models/pagination'
import { PeakShavingEventState, PeakShavingProgram } from 'models/peak-shaving'
import { PeakShavingProgramFilter } from 'models/filter'

import ProgramStatusBadge from 'components/peakShaving/peak-shaving-status'
import { GetAllPeakShavingPrograms } from 'services/data-provider/peak-shaving'
import { usePeakShavingTranslation } from './translation/usePeakShavingTranslation'
import { DotsSVG } from 'assets/svg/dots'

export const useDemandManagementPrograms = () => {
  const [peekShavingPrograms, setPeekShavingPrograms] = useState<PeakShavingProgram[]>([])
  const [createNewProgramModalVisible, setCreateNewProgramModalVisible] = useState(false)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [pagination, setPagination] = useState<PaginationMeta>()

  const [selectedProgram, setSelectedProgram] = useState<PeakShavingProgram>()

  const [filter, setFilter] = useState<PeakShavingProgramFilter>({ page: 1 })

  useEffect(() => {
    fetchPeakShavingPrograms()
  }, [])

  const {
    programNameText,
    aggregatorNameText,
    statusText,
    descriptionText,
    startDateText,
    endDateText,
    eventsTabEditProgramText,
  } = usePeakShavingTranslation()

  const selectProgram = (program: PeakShavingProgram) => () => {
    handleProgramModal()
    setSelectedProgram(program)
  }

  const columns: ColumnsType<PeakShavingProgram> = [
    {
      title: programNameText,
      dataIndex: 'name',
      width: '20%',
    },
    {
      title: aggregatorNameText,
      dataIndex: 'aggregatorName',
      width: '15%',
    },
    {
      title: startDateText,
      dataIndex: 'startDate',
      render: (date: string) => date && moment(date).format('YYYY-MM-DD'),
    },
    {
      title: endDateText,
      dataIndex: 'endDate',
      render: (date: string) => date && moment(date).format('YYYY-MM-DD'),
    },
    {
      title: descriptionText,
      dataIndex: 'initiative',
    },
    {
      title: statusText,
      dataIndex: 'state',
      render: (state: PeakShavingEventState) => <ProgramStatusBadge state={state} />,
      width: '15%',
    },
    {
      title: '',
      width: '80px',
      render: (program: PeakShavingProgram) => (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu className="table-dot-dropdown">
              <Menu.Item onClick={selectProgram(program)}>
                <p className="paragraph-02-regular">{eventsTabEditProgramText}</p>
              </Menu.Item>
            </Menu>
          }
        >
          <span onClick={(e) => e.preventDefault()}>{<DotsSVG className="edit-event-icon" />}</span>
        </Dropdown>
      ),
    },
  ]

  const handleProgramModal = () => {
    const changedState = !createNewProgramModalVisible
    if (!changedState) {
      setSelectedProgram(undefined)
    }
    setCreateNewProgramModalVisible(changedState)
  }

  const fetchPeakShavingPrograms = (page = 1) => {
    setLoading(true)
    setError(undefined)

    GetAllPeakShavingPrograms({ ...filter, page })
      .then(({ data, pagination }) => {
        setPeekShavingPrograms(data)
        setPagination(pagination)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  const fetchDataWithQuery = () => {
    fetchPeakShavingPrograms()
  }

  const handlePaginationChange = (page: number, pageSize?: number) => {
    fetchPeakShavingPrograms(page)
    return
  }

  const onSubmit = () => {
    handleProgramModal()
    fetchPeakShavingPrograms()
  }

  return {
    error,
    loading,
    pagination,
    filter,
    peekShavingPrograms,
    columns,
    createNewProgramModalVisible,
    selectedProgram,
    fetchDataWithQuery,
    setFilter,
    handlePaginationChange,
    handleProgramModal,
    onSubmit,
  }
}
