import { useFormatMessage } from '../../helpers/intl'

export const useChargerTranslation = () => {
  const chargerText = useFormatMessage('dashboard.text.charger', 'Charger')
  const chargerIdText = useFormatMessage('dashboard.charger.chargerId', 'Charger ID')
  const chargerIdsText = useFormatMessage('dashboard.charger.chargerIds', 'Charger ID (s)')
  const serialNumberText = useFormatMessage('dashboard.charger.serialNumber', 'Serial Number')
  const serialNumbersText = useFormatMessage('dashboard.charger.serialNumbers', 'Serial Number (s)')
  const makeAndModelText = useFormatMessage('dashboard.charger.makeAndModel', 'Make and Model')
  const vendorText = useFormatMessage('dashboard.text.vendor', 'Vendor')
  const chargerTypeText = useFormatMessage('dashboard.charger.chargerType', 'Charger Type')
  const SNText = useFormatMessage('dashboard.charger.SN#', 'SN#')
  const chargerStatusText = useFormatMessage('dashboard.chargerPage.chargerStatus.heading', 'Charger Status')
  const onlineText = useFormatMessage('dashboard.chargerPage.chargerStatus.online', 'Online')
  const offlineText = useFormatMessage('dashboard.chargerPage.chargerStatus.offline', 'Offline')
  const clearCacheTitle = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.clearCache.title',
    "Click below to clear the charger's cache",
  )
  const clearCacheBtnText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.clearCache.btn.heading',
    'Clear 1234 Cache',
  )
  const sendClearCacheRequest = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.clearCache.request',
    'Sending clear cache request to',
  )
  const clearCacheText = useFormatMessage('dashboard.chargerPage.remoteControlTab.clearCache.heading', 'Clear Cache')
  const locationWarningText = useFormatMessage(
    'dashboard.chargersPage.modal.firmware.location.warning',
    'Please input a location!',
  )
  const retrieveWarningText = useFormatMessage(
    'dashboard.chargersPage.modal.firmware.retrieveDate.warning',
    'Please retrieve a date!',
  )
  const locationTextHelp = useFormatMessage(
    'dashboard.chargersPage.modal.firmware.location.help',
    'Date after which the charger must retrieve the (new) firmware',
  )
  const updateFirmwareText = useFormatMessage('dashboard.chargersPage.modal.firmware.updatefirmware', 'Update Firmware')
  const sendingFirmwareUpdateChangesText = useFormatMessage(
    'dashboard.chargersPage.modal.configuration.sendconfigurationchanges',
    'Sending firmware update for',
  )
  const restartChargerText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.restartChargeBox.p1',
    'Click below to restart the',
  )
  const chargerLowerCaseText = useFormatMessage('dashboard.chargerPage.heading', 'Charger').toLowerCase()
  const restartBtnText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.restartChargeBox.restartbtn',
    'Restart',
  )
  const chargerHardResetRequestText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.restartChargeBox.hardResetRequest',
    'Sending Hard Reset request for',
  )
  const triggerText = useFormatMessage('dashboard.chargerPage.remoteControlTab.triggerMessage.trigger', 'Trigger')
  const messageTypePlaceHolder = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.select.placeholder',
    'Select a message type',
  )
  const sendTriggerMessageMultipleChargers = useFormatMessage(
    'dashboard.chargersPage.modal.configuration.trigger.message',
    "Sending Trigger Message '123' to selected charger(s)",
  )
  const connectorText = useFormatMessage('dashboard.chargersPage.connector.heading', 'Connector')
  const bootNotificationText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.select.bootNotification',
    'Boot Notification',
  )
  const diagnosticsStatusNotificationText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.select.diagnosticsStatusNotification',
    'Diagnostics Status Notification',
  )
  const firmwareStatusNotificationText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.select.firmwareStatusNotification',
    'Firmware Status Notification',
  )
  const heartBeatText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.select.heartbeat',
    'Heart Beat',
  )
  const meterValueText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.select.meterValue',
    'Meter Value',
  )
  const statusNotificationText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.select.statusNotification',
    'Status Notification',
  )
  const configurationTriggerText = useFormatMessage(
    'dashboard.chargersPage.modal.configuration.trigger.heading',
    'Select a message below for selected chargers to initiate a trigger message',
  )
  const sendTriggerMessageText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.btn.sendTriggerMessage',
    'Send Trigger Message',
  )
  const keyText = useFormatMessage('dashboard.chargersPage.modal.configuration.key', 'key')
  const keyWarningText = useFormatMessage(
    'dashboard.chargersPage.modal.configuration.key.warning',
    'Please input a key!',
  )
  const keyInfoText = useFormatMessage(
    'dashboard.chargersPage.modal.configuration.key.info',
    'Key of the value you want to change',
  )
  const valueText = useFormatMessage('dashboard.chargersPage.modal.configuration.value', 'Value')
  const valueInfoText = useFormatMessage('dashboard.chargersPage.modal.configuration.value.info', 'Value to change')
  const updateConfigurationText = useFormatMessage(
    'dashboard.chargersPage.modal.configuration.updateConfiguration',
    'Update Configuration',
  )
  const updateChargersText = useFormatMessage('dashboard.chargersPage.modal.updatechargers', 'Update Chargers')
  const selectedText = useFormatMessage('dashboard.chargersPage.modal.updatechargers.selected', 'selected')
  const firmwareText = useFormatMessage('dashboard.chargersPage.modal.firmware', 'Firmware')
  const configurationText = useFormatMessage('dashboard.chargersPage.modal.configuration', 'Configuration')
  const restartText = useFormatMessage('dashboard.chargerPage.remoteControlTab.restartChargeBox.restartbtn', 'Restart')
  const triggerMessageText = useFormatMessage(
    'dashboard.chargerPage.remoteControlTab.triggerMessage.trigger',
    'Trigger',
  )
  const searchText = useFormatMessage('dashboard.text.search', 'Search')
  const modifyText = useFormatMessage('dashboard.text.modify', 'Modify')
  const advancedSearchBtn = useFormatMessage('dashboard.transactionsPage.advancedSearch', 'Advanced Search')
  const connectorIdText = useFormatMessage('dashboard.chargersPage.table.heading.connectorId', 'Connector Id')
  const firmwareVersionText = useFormatMessage('dashboard.chargerPage.tab.detail.firmwareVersion', 'Firmware Version')
  const chargerServiceStatusText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.chargerServiceStatus',
    'Charger Service Status',
  )
  const serviceStatusText = useFormatMessage('dashboard.selector.serviceStatus', 'Service Status')
  const connectorStatusText = useFormatMessage('dashboard.chargersPage.table.heading.status', 'Connector Status')
  const viewChargerText = useFormatMessage('dashboard.text.viewCharger', 'View Charger')
  const viewChargerDetailsText = useFormatMessage('dashboard.text.viewChargerDetails', 'View Charger Details')
  const viewChargerInAppText = useFormatMessage('dashboard.text.viewChargerInApp', 'View Charger in App')
  const chargingStatusText = useFormatMessage('dashboard.text.chargingStatus', 'Charging Status')
  const lastChargingStatusText = useFormatMessage('dashboard.text.lastChargingStatus', 'Last Charging Status')

  const swtchCareText = useFormatMessage('dashboard.charger.swtchCare', 'SWTCH Care')
  const utilizationRateText = useFormatMessage('dashboard.charger.utilizationRate', 'Utilization Rate')
  const uptimePercentageText = useFormatMessage('dashboard.charger.uptimePercentage', 'Uptime Percentage')

  return {
    chargerText,
    chargerIdText,
    chargerIdsText,
    chargerStatusText,
    chargingStatusText,
    connectorStatusText,
    onlineText,
    offlineText,
    chargerTypeText,
    serialNumberText,
    serialNumbersText,
    SNText,
    vendorText,
    clearCacheTitle,
    clearCacheBtnText,
    sendClearCacheRequest,
    clearCacheText,
    lastChargingStatusText,
    locationWarningText,
    retrieveWarningText,
    locationTextHelp,
    updateFirmwareText,
    sendingFirmwareUpdateChangesText,
    restartChargerText,
    chargerLowerCaseText,
    restartBtnText,
    chargerHardResetRequestText,
    triggerText,
    makeAndModelText,
    messageTypePlaceHolder,
    sendTriggerMessageMultipleChargers,
    connectorText,
    bootNotificationText,
    diagnosticsStatusNotificationText,
    firmwareStatusNotificationText,
    heartBeatText,
    meterValueText,
    statusNotificationText,
    configurationTriggerText,
    sendTriggerMessageText,
    keyText,
    keyWarningText,
    keyInfoText,
    valueText,
    valueInfoText,
    updateConfigurationText,
    updateChargersText,
    selectedText,
    firmwareText,
    configurationText,
    restartText,
    triggerMessageText,
    searchText,
    modifyText,
    advancedSearchBtn,
    connectorIdText,
    firmwareVersionText,
    chargerServiceStatusText,
    serviceStatusText,
    viewChargerText,
    viewChargerDetailsText,
    viewChargerInAppText,
    swtchCareText,
    utilizationRateText,
    uptimePercentageText,
  }
}
