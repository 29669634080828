import { Checkbox, Form, Input, Select, Switch } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { renderFormatMessage } from 'helpers/intl'
import { renderConnectionMessage } from 'helpers/status'
import { renderChargerSettingText } from 'helpers/tenant-tab-render-text'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { useSelector } from 'hooks/useSelector'
import { chargerSettings } from 'models/chargerv2'
import { connectorServiceStatus } from 'models/connector'
import { ChargerSetting, Tenant } from 'models/tenant'
import { ReactElement } from 'react'
import { theme } from 'theme'

interface TenantServiceStatusInfoProps {
  form: FormInstance<any>
  tenant: Tenant
  disabled: boolean
  currentChargerSettings: string[]
  setChargerSettings: (chargerSettings: ChargerSetting[]) => void
}

const TenantServiceStatusInfo: React.FC<TenantServiceStatusInfoProps> = ({
  form,
  tenant,
  disabled,
  currentChargerSettings,
  setChargerSettings,
}) => {
  const {
    uniformServiceStatusWarningText,
    connectorServiceStatusText,
    uniformServiceStatusText,
    omOptInText,
    swtchCareText,
    caasText,
    chargerSettingsText,
  } = useTenantTranslation()
  const { omOptinNoText, noteText } = useGeneralTranslation()
  const { multiChargerSettingsHandlePlaceholder, multiChargerSettingsHandleChange } = useSelector()

  const renderMultiChargerSettingsOption = (
    option: string,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    const translateOpt = renderChargerSettingText(option)
    return (
      <Select.Option key={option} value={option} label={option}>
        <Checkbox onClick={handleCheckboxClick} checked={selectedOptions.includes(option)}>
          {translateOpt}
        </Checkbox>
      </Select.Option>
    )
  }
  return (
    <>
      <Form.Item name="omOptin" label={omOptInText} rules={[{ required: false }]} className="align-center">
        <Select disabled={disabled}>
          <Select.Option value="swtch_care">{swtchCareText}</Select.Option>
          <Select.Option value="caas">{caasText}</Select.Option>
          <Select.Option value="no">{omOptinNoText}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="chargerSetting"
        label={chargerSettingsText}
        rules={[{ required: false }]}
        className="align-center"
      >
        <ReusableSelector
          showSearch
          disabled={disabled}
          selectAllOptions
          onOptionsChange={(chargerSetting: ChargerSetting[]) => {
            setChargerSettings(chargerSetting)
            form.setFieldsValue({ chargerSetting })
          }}
          onClear={() => {
            setChargerSettings([])
            form.setFieldsValue({ chargerSetting: [] })
          }}
          isSingle={false}
          placeholder={chargerSettingsText}
          dropDownList={chargerSettings}
          showMagnifySVG={false}
          maxTagCount={0}
          isDebounceFetcher={false}
          debounceFetcher={undefined}
          defaultValues={currentChargerSettings}
          handlePlaceholder={multiChargerSettingsHandlePlaceholder}
          handleOptionChange={multiChargerSettingsHandleChange}
          renderOption={renderMultiChargerSettingsOption}
        />
      </Form.Item>
      <Form.Item name="notes" label={noteText}>
        <Input.TextArea disabled={disabled} showCount maxLength={255} style={{ height: theme.space[6] }} />
      </Form.Item>
      <Form.Item name="allowUniformServiceStatus" label={uniformServiceStatusText}>
        <Switch defaultChecked={tenant.allowUniformServiceStatus} disabled={disabled} />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.allowUniformServiceStatus !== currentValues.allowUniformServiceStatus
        }
      >
        {({ getFieldValue }) =>
          getFieldValue('allowUniformServiceStatus') === true ? (
            <Form.Item
              name="uniformServiceStatus"
              label={connectorServiceStatusText}
              rules={[{ required: false }]}
              validateStatus="warning"
              help={uniformServiceStatusWarningText}
              className="align-center-label"
            >
              <Select disabled={disabled} allowClear>
                {connectorServiceStatus.map((status) => (
                  <Select.Option key={status} value={status}>
                    {renderFormatMessage(renderConnectionMessage(status), status)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : null
        }
      </Form.Item>
    </>
  )
}

export default TenantServiceStatusInfo
