import { Space } from 'antd'
import { ButtonModal } from 'atom/button'
import { Incidences } from 'models/incidences'
import { editSVG } from 'assets/svg/edit'
import { capitalizeFirstLetter } from 'helpers/users'
import { useIncidencesTranslation } from 'hooks/translation/useIncidencesTranslation'
import { DateTimeV2 } from 'components/date-time'

export const IncidencesColumns = (setSelectedIncidence: (incidence: Incidences) => void) => {
  const {
    zendeskIdText,
    listingTitleText,
    rootCauseText,
    downtimeText,
    resolutionText,
    startTimeText,
    endTimeText,
    durationText,
  } = useIncidencesTranslation()

  const columns = [
    {
      title: zendeskIdText,
      dataIndex: 'zendeskId',
      width: '8%',
      render: (zendeskID: string) => (
        <a href={`https://swtch.zendesk.com/agent/tickets/${zendeskID}`} target="_blank" rel="noopener noreferrer">
          {zendeskID}
        </a>
      ),
    },
    { title: listingTitleText, dataIndex: 'listingTitle', width: '10%' },
    { title: rootCauseText, dataIndex: 'rootCause', width: '10%' },
    { title: downtimeText, dataIndex: 'downtime', render: (downtime: string) => `${capitalizeFirstLetter(downtime)}d` },
    { title: resolutionText, dataIndex: 'resolution', width: '15%' },
    {
      title: startTimeText,
      dataIndex: 'startTime',
      width: '10%',
      render: (timeStamp: string) => (timeStamp ? <DateTimeV2 alignLeft date={timeStamp} isSidePanel /> : '-'),
    },
    {
      title: endTimeText,
      dataIndex: 'endTime',
      width: '10%',
      render: (timeStamp: string) => (timeStamp ? <DateTimeV2 alignLeft date={timeStamp} isSidePanel /> : '-'),
    },
    {
      title: durationText,
      dataIndex: 'duration',
    },
    {
      title: '',
      key: 'action',
      render: (incidence: Incidences) => (
        <Space size="small">
          <ButtonModal
            type="link"
            icon={editSVG}
            click={() => {
              setSelectedIncidence(incidence)
            }}
          />
        </Space>
      ),
      width: '90px',
    },
  ]
  return columns
}
