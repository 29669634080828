import { Row } from 'antd'
import { styled } from 'theme'

interface props {
  title: string
  hide?: boolean
  children: React.ReactNode
}

const SectionContainer = styled(Row)`
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
  margin-top: 20px;
  margin-bottom: 20px;
`

export const ServiceStatusSectionHeader: React.FC<props> = ({ title, hide = false, children }) => {
  return (
    <>
      {!hide && (
        <SectionContainer>
          <h6 className="heading-06-regular">{title}</h6>
        </SectionContainer>
      )}
      {children}
    </>
  )
}
