import { Button, Form, Select } from 'antd'
import { NewListing, listingKindCategory } from '../../models/listing'
import { useListingBasicTrans } from '../../hooks/translation/useListingBasicTrans'
import { useAppState } from '../../state'
import { layout } from '../../atom/form/modal-layout'
import { AlertError } from '../error'
import { useGeneralTranslation } from '../../hooks/translation/useGeneralTranslation'
import { useListingCategory } from 'hooks/useListingCategory'
import { formatListingCategory } from 'helpers/listing'
import { renderFormatMessage } from 'helpers/intl'

interface props {
  listings: NewListing[]
  onOk: () => void
}

export const UpdateListingCategory: React.FC<props> = ({ listings, onOk }) => {
  const { language } = useAppState()
  const { error, loading, initialValues, onFinish } = useListingCategory(listings, onOk)
  const { listingCategoryText } = useListingBasicTrans()
  const { applyText } = useGeneralTranslation()

  const chooseLayout = () => {
    let frLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 24 },
    }
    return language === 'fr' ? frLayout : layout
  }

  return (
    <>
      <AlertError error={error} />
      <Form {...chooseLayout()} onFinish={onFinish} initialValues={initialValues}>
        <Form.Item name="listing_category" label={listingCategoryText}>
          <Select placeholder={listingCategoryText} disabled={loading} loading={loading}>
            {listingKindCategory.map((listing, index) => (
              <Select.Option key={`${listing}-${index}`} value={listing} label={listing}>
                {renderFormatMessage(
                  `dashboard.createNewListing.listingStep.listingCategory.${formatListingCategory(listing)}`,
                  formatListingCategory(listing),
                )}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {applyText}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
