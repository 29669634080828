import { Button, PageHeader } from 'antd'

import { plusSignSVG } from 'assets/svg/plusSign'
import { BackgroundContainer } from 'atom/layout/backgroundContainer'
import { AlertError } from 'components/error'
import { usePeakShavingTranslation } from 'hooks/translation/usePeakShavingTranslation'
import { useDemandManagementPrograms } from 'hooks/useDemandManagementPrograms'
import { CreateNewProgramModal } from 'pages/peakShaving/modal/create-new-program-modal'
import ProgramSelectors from '../selectors/programSelectors'
import TabTable from '../table/tab-table'

interface DemandManagementTabProgramsProps {}

const DemandManagementTabPrograms: React.FC<DemandManagementTabProgramsProps> = () => {
  document.querySelector('body')?.classList.add('redesignActive')

  const {
    error,
    loading,
    pagination,
    filter,
    peekShavingPrograms,
    columns,
    createNewProgramModalVisible,
    selectedProgram,
    fetchDataWithQuery,
    setFilter,
    handlePaginationChange,
    handleProgramModal,
    onSubmit,
  } = useDemandManagementPrograms()

  const { newProgramText } = usePeakShavingTranslation()

  return (
    <BackgroundContainer>
      {(createNewProgramModalVisible || !!selectedProgram) && (
        <CreateNewProgramModal
          visible={createNewProgramModalVisible || !!selectedProgram}
          onCancel={handleProgramModal}
          onSubmit={onSubmit}
          initialValues={selectedProgram}
        />
      )}
      <PageHeader
        title={<ProgramSelectors onFilter={setFilter} onFetch={fetchDataWithQuery} {...{ loading, filter }} />}
        extra={
          <Button
            key="new-program-btn"
            loading={loading}
            size="large"
            type="primary"
            disabled={loading}
            icon={plusSignSVG}
            onClick={handleProgramModal}
          >
            <span className="regular-cap">{newProgramText}</span>
          </Button>
        }
      />
      <AlertError error={error} />
      <TabTable
        loading={loading}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        dataSource={peekShavingPrograms}
        columns={columns}
      />
    </BackgroundContainer>
  )
}

export default DemandManagementTabPrograms
