import { theme } from '../../theme'

export const filterSVG = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.84615 9H13.1538M3 5H15M7.61538 13H10.3846"
      stroke={theme.colors.darkGray}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
