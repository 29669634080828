import ReactDOM from 'react-dom'

import App from './app'
import { ThemeProvider } from 'styled-components'
import { theme } from './theme'
import ReactGA from 'react-ga4'
ReactGA.initialize(
  process.env.REACT_APP_GA || '',
  // This is to enable debug mode
  // , {
  //   gaOptions: {
  //     debug_mode: true,
  //   },
  //   gtagOptions: {
  //     debug_mode: true,
  //   },
  // }
)

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root'),
)
