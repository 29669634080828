import { PageHeader } from 'antd'

import { BackgroundContainer } from 'atom/layout/backgroundContainer'
import { AlertError } from 'components/error'
import IncidencesSelectors from 'features/tenants/incidences/IncidencesSelectors'
import { useTenantIncidences } from 'hooks/useTenantIncidences'
import { TenantRef } from 'models/tenant'
import IncidencesView from './table/IncidencesView'
import UpdateIncidencesModal from './modal/update-incidences-modal'

interface TenantTabIncidencesProps {
  tenant: TenantRef
}

const TenantTabIncidences: React.FC<TenantTabIncidencesProps> = ({ tenant }) => {
  document.querySelector('body')?.classList.add('redesignActive')

  const {
    error,
    loading,
    startDate,
    endDate,
    incidences,
    pagination,
    filter,
    fetchDataWithQuery,
    handlePaginationChange,
    setFilter,
    handleChangeDate,
    currentReasons,
    serviceDescriptions,
    setSelectedIncidence,

    initialValues,
    updateIncidence,
  } = useTenantIncidences(tenant.id)

  return (
    <BackgroundContainer>
      {!!Object.keys(initialValues).length && (
        <UpdateIncidencesModal
          loading={loading}
          initialValues={initialValues}
          serviceDescriptions={serviceDescriptions}
          onCancel={() => setSelectedIncidence(undefined)}
          currentReasons={currentReasons}
          onFinish={updateIncidence}
        />
      )}
      <PageHeader
        title={
          <IncidencesSelectors
            onChangeDate={handleChangeDate}
            onFilter={setFilter}
            onFetch={fetchDataWithQuery}
            {...{ loading, startDate, endDate, filter, tenant, currentReasons, serviceDescriptions }}
          />
        }
      />
      <AlertError error={error} />

      <IncidencesView
        loading={loading}
        incidences={incidences}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        setSelectedIncidence={setSelectedIncidence}
      />
    </BackgroundContainer>
  )
}

export default TenantTabIncidences
