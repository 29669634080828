import { theme } from '../../theme'

export const copySVG = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.39999 6.59999H3.59999C2.60588 6.59999 1.79999 7.40587 1.79999 8.39999C1.79999 9.3941 1.79999 13.4059 1.79999 14.4C1.79999 15.3941 2.60588 16.2 3.59999 16.2C3.59999 16.2 8.60587 16.2 9.59999 16.2C10.5941 16.2 11.4 15.3941 11.4 14.4V12.15M14.4 1.79999L8.39999 1.79999C7.40588 1.79999 6.59999 2.60588 6.59999 3.59999C6.59999 4.5941 6.59999 8.60587 6.59999 9.59999C6.59999 10.5941 7.40587 11.4 8.39999 11.4C8.39999 11.4 13.4059 11.4 14.4 11.4C15.3941 11.4 16.2 10.5941 16.2 9.59999V3.59999C16.2 2.60588 15.3941 1.79999 14.4 1.79999Z"
      stroke={theme.colors.darkGray}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
