import { Button, Col, Row, Space } from 'antd'
import { magnifyBlackSVG } from 'assets/svg/magnify'
import { ButtonIcon } from 'atom/button'
import { LineBreak } from 'atom/line-break'
import MultiListingTitleSelector from 'components/redesign/MultiListingIdTitleSelector'
import MultiOptionSelector from 'components/redesign/MultiOptionSelector'
import { LocationSelector } from 'components/redesign/location-selector'
import { MultiTenantSelector } from 'components/redesign/multiple-tenant-selector'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'
import { useMasterAccountSelector } from 'hooks/useMasterAccountSelector'
import { useSelector } from 'hooks/useSelector'
import { useTagging } from 'hooks/useTagging'
import { TenantFilter } from 'models/filter'
import { ListingRef } from 'models/listing'
import { MasterAccountRef } from 'models/master-account'
import { Tagging } from 'models/tagging'
import { TenantRef, TenantStatus, tenantStatuses } from 'models/tenant'
import { useEffect } from 'react'
import { useAppState } from 'state'

interface TenantsAllSelectorsProps {
  loading: boolean
  filter: TenantFilter
  tenants: TenantRef[]
  masterAccount: MasterAccountRef | undefined
  onFilter: (filter: TenantFilter) => void
  fetchData: () => void
  onFilterChange: (filter: TenantFilter, tenants?: TenantRef[]) => void
  onLocationChange: (chosenCountries: string[], chosenProvinces: string[]) => void
  onTenantStatus: (tenantStatus: TenantStatus[]) => void
  setMasterAccount: (masterAccount: MasterAccountRef | undefined) => void
  dropdownClassName?: string
}

const TenantsAllSelectors: React.FC<TenantsAllSelectorsProps> = ({
  loading,
  filter,
  tenants,
  masterAccount,
  onFilterChange,
  fetchData,
  onFilter,
  onLocationChange,
  onTenantStatus,
  setMasterAccount,
  dropdownClassName,
}) => {
  const { IsDesktop, IsLaptop } = useAppState()
  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()
  const { currentUser } = useAppState()
  const { accountNameText, taggingText } = useSelectorTranslation()
  const { options, handleMasterAccountSelectChange, renderMasterAccountOption } = useMasterAccountSelector(
    setMasterAccount,
  )

  const { getTaggingsWithoutPagination, taggings, renderMultiTagOption } = useTagging()

  const { multiTaggingHandlePlaceholder, multiTaggingHandleChange } = useSelector()
  const isAdmin = currentUser?.role === 'admin'

  useEffect(() => {
    getTaggingsWithoutPagination('Tenant')
  }, [])

  const { searchText } = useGeneralTranslation()
  const renderMultiTenantSelector = () => (
    <MultiTenantSelector
      onOptionsChange={(tenants) => onFilterChange(filter, tenants)}
      onClear={() => onFilterChange(filter)}
      longerWidth={true}
      isCombineRight={true}
      loading={loading}
      dropdownClassName={dropdownClassName}
    />
  )

  const renderLocationSelector = () => (
    <LocationSelector
      dropdownClassName="tenant-page"
      transparent
      onSearch={onLocationChange}
      loading={loading}
      removeSVG={true}
      customWidth={145}
    />
  )

  const renderMasterAccountSelector = () => (
    <ReusableSelector
      showSearch={true}
      onClear={() => setMasterAccount(undefined)}
      isSingle={true}
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={false}
      handlePlaceholder={undefined}
      debounceFetcher={undefined}
      dropdownClassName={dropdownClassName}
      placeholder={accountNameText}
      dropDownList={options}
      selectAllOptions={true}
      combineType="both"
      handleOptionChange={handleMasterAccountSelectChange}
      renderOption={renderMasterAccountOption}
      customWidth="250px"
      stopClearSearchValue={true}
    />
  )

  const renderTenantStatusSelector = () => (
    <MultiOptionSelector
      dropdownClassName={dropdownClassName}
      options={tenantStatuses.map((v) => v)}
      onOptionsChange={(opts: TenantStatus[]) => onTenantStatus(opts)}
      onClear={() => onFilter({ ...filter, status: [] })}
      onSearch={(opts) => onTenantStatus(opts)}
      placeholder="Status (es)"
      isCombineRightAndLeft
      loading={loading}
      longerWidth={false}
      defaultValues={filter?.status}
      removeSVG={true}
    />
  )

  const renderMultiListingTitleSelector = () => (
    <MultiListingTitleSelector
      dropdownClassName={dropdownClassName}
      onOptionChange={(listingsResp: ListingRef[]) => onFilterChange({ ...filter, listings: listingsResp })}
      setClear={() => onFilterChange(filter)}
      tenants={tenants}
      isCombineLeftSelector={!isAdmin}
      isCombineSelector={isAdmin}
      width={isDesktop || isLaptop ? '120px' : '100%'}
      minWidth={120}
    />
  )

  const renderTagSelector = () => (
    <ReusableSelector
      showSearch
      isSingle={false}
      clearSearchAfterSelect
      placeholder={taggingText}
      dropDownList={taggings}
      showMagnifySVG={false}
      defaultValues={filter?.taggings}
      fieldForValue="id"
      maxTagCount={0}
      isDebounceFetcher={false}
      handlePlaceholder={multiTaggingHandlePlaceholder}
      debounceFetcher={undefined}
      dropdownClassName={dropdownClassName}
      combineType="left"
      handleOptionChange={multiTaggingHandleChange}
      renderOption={renderMultiTagOption}
      onOptionsChange={(taggings: Tagging[]) => onFilterChange({ ...filter, taggings })}
      customWidth="120px"
      searchButtonMerged
      placeholderWidth="50px"
    />
  )

  return (
    <>
      {isDesktop || isLaptop ? (
        <Space wrap size={[0, 6]} style={{ display: 'flex' }}>
          {renderMultiTenantSelector()}
          <LineBreak />
          {renderLocationSelector()}
          {isAdmin && (
            <>
              <LineBreak />
              {renderMasterAccountSelector()}
            </>
          )}
          <LineBreak />
          {renderTenantStatusSelector()}
          <LineBreak />
          {renderMultiListingTitleSelector()}
          {isAdmin && (
            <>
              <LineBreak />
              {renderTagSelector()}
            </>
          )}
          <ButtonIcon
            icon={magnifyBlackSVG}
            loading={loading}
            disabled={loading}
            fetchData={fetchData}
            withinSearchBar
          />
        </Space>
      ) : (
        <Row wrap gutter={[6, 6]}>
          <Col xs={24} sm={24} md={24}>
            {renderMultiTenantSelector()}
          </Col>
          <Col xs={24} sm={24} md={24}>
            <LocationSelector onSearch={onLocationChange} loading={loading} />
          </Col>
          {isAdmin && (
            <Col xs={24} sm={24} md={24}>
              {renderMasterAccountSelector()}
            </Col>
          )}
          <Col xs={24} sm={24} md={24}>
            {renderTenantStatusSelector()}
          </Col>
          <Col xs={24} sm={24} md={24}>
            {renderMultiListingTitleSelector()}
          </Col>
          {isAdmin && (
            <Col xs={24} sm={24} md={24}>
              {renderTagSelector()}
            </Col>
          )}
          <Col xs={24} sm={24} md={24}>
            <Button block type="primary" loading={loading} disabled={loading} onClick={fetchData}>
              {searchText}
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default TenantsAllSelectors
