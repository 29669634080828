import { theme } from 'theme'

interface UptimePercentageProps {
  percentage: number
  isColorRequired: boolean
}

const UptimePercentage: React.FC<UptimePercentageProps> = ({ isColorRequired, percentage }) => {
  document.querySelector('body')?.classList.add('redesignActive')
  if (isColorRequired) {
    const color = percentage < 97 ? theme.colors.dangerPrimary : theme.colors.greenBase

    return <h6 className="heading-06-regular-table" style={{ color }}>{`${percentage}%`}</h6>
  }
  return <h6 className="heading-06-regular-table">{`${percentage}%`}</h6>
}

export default UptimePercentage
