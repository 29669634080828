import { Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { GoodNetworkSignalSVG, NoNetworkSignalSVG, networkSVG } from 'assets/svg/network'

import { NewListing } from 'models/listing'
import ServiceStatusBadge from 'components/connectors/ServiceStatusBadge'
import { ChargerConnectorStateBadgeRedesign } from 'components/chargers/charger-connector-state-badge-redesign'

import { useChargerTranslation } from 'hooks/translation/useChargerTranslation'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { usePricingSchemasTranslation } from 'hooks/translation/usePricingSchemaTranslation'

import AccessPermission from 'pages/listing/functions/access-permission'
import ListingLoiteringStatus from 'pages/listing/functions/loitering'
import HandlePrice from 'pages/listing/functions/price'

import { useAppState } from 'state'
import { renderFormatMessage } from 'helpers/intl'
import { handleChargerPlug } from 'pages/listing/functions/charger'
import ActionsMenu from './ChargersActionsMenu'
import { DateTimeFromNow, DateTimeV2 } from 'components/date-time'

export const ChargersColumns = () => {
  const { currentUser } = useAppState()
  const isAdmin = currentUser?.role === 'admin'

  const { accessText, pricingPlanText } = useGeneralTranslation()
  const { loiteringText } = usePricingSchemasTranslation()
  const { lastChargingStatusText, serviceStatusText } = useChargerTranslation()
  const { chargerIdText, makeAndModelText, serialNumberText } = useChargerTranslation()

  const renderListingTitle = (listing: NewListing | undefined): JSX.Element | string => {
    if (!listing || !listing.id) {
      return '-'
    }

    return isAdmin ? (
      <div className="flex-and-gap-below" aria-label={`Charger ${listing.title}`}>
        {listing.title || '-'}
        {handleChargerPlug(listing.charger, listing.connector, true, true)}
      </div>
    ) : (
      listing.title || '-'
    )
  }

  const isChargerOnline = (listing: NewListing) => {
    return listing?.connectivity ? (
      <Tooltip title={renderFormatMessage('dashboard.text.online', 'Online')}>{GoodNetworkSignalSVG}</Tooltip>
    ) : (
      <>
        <Tooltip title={renderFormatMessage('dashboard.text.offline', 'Offline')}>
          <span>{NoNetworkSignalSVG}</span>
        </Tooltip>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginLeft: '2rem' }}>
          <DateTimeFromNow date={listing?.charger?.lastDisconnectedAt} />
        </div>
      </>
    )
  }

  const renderSerialNumber = (listing: NewListing) => {
    const serialNumber = listing?.charger?.chargePointSerialNumber
    return (
      <div className="flex-and-gap-below">
        <Typography.Text
          style={{ width: 150 }}
          ellipsis={{ tooltip: serialNumber }}
          aria-label={serialNumber}
          title={serialNumber}
        >
          {serialNumber}
        </Typography.Text>
        <span className="paragraph-04-light opacity-06">{listing?.charger?.firmwareVersion}</span>
      </div>
    )
  }

  const renderConnectorStatus = (listing: NewListing) => {
    if (!listing || !listing.id) {
      return null
    }
    return listing?.connector?.ocppStatus ? (
      <div className="flex-and-gap-below">
        <ChargerConnectorStateBadgeRedesign state={listing.connector.ocppStatus} />
        {listing?.connector?.lastStatusChangedAt && (
          <DateTimeV2 date={listing.connector.lastStatusChangedAt} inlineDateTime ignoreSeconds />
        )}
      </div>
    ) : null
  }

  const columns: ColumnsType<NewListing> = [
    {
      title: (
        <Tooltip
          title={renderFormatMessage(
            'dashboard.text.chargerID.description',
            'Unique ID that can be found on the front of a charger',
          )}
        >
          {chargerIdText}
        </Tooltip>
      ),
      key: 'listing_title',
      render: renderListingTitle,
      width: '9%',
    },
    {
      title: (
        <Tooltip
          title={renderFormatMessage(
            'dashboard.text.serialNumber.description',
            'Manufacturer identifier for each charger, not visible to drivers',
          )}
        >
          {serialNumberText}
        </Tooltip>
      ),
      key: 'serial_number',
      render: (listing: NewListing) => renderSerialNumber(listing),
      width: '12%',
    },
    {
      title: (
        <Tooltip
          title={renderFormatMessage(
            'dashboard.text.makeAndModel.description',
            "Make and model of the charger's manufacturer",
          )}
        >
          {makeAndModelText}
        </Tooltip>
      ),
      key: 'make_and_model',
      width: '11%',
      render: (listing: NewListing) => (
        <div className="flex-and-gap-below">
          <div>{listing?.charger?.chargePointVendor}</div>
          <span className="paragraph-04-light opacity-06">{listing?.charger?.chargePointModel}</span>
        </div>
      ),
    },
    {
      title: (
        <Tooltip title={renderFormatMessage('dashboard.text.pricingPlan.description', 'Pricing plan of the charger')}>
          {pricingPlanText}
        </Tooltip>
      ),
      key: 'pricing_plan',
      width: '10%',
      render: ({ id, ...rest }: NewListing) => (id ? <HandlePrice listing={{ id, ...rest }} /> : null),
    },
    {
      title: (
        <Tooltip title={renderFormatMessage('dashboard.text.loitering.description', 'Loitering status of the charger')}>
          {loiteringText}
        </Tooltip>
      ),
      key: 'loitering',
      width: '10%',
      render: (listing: NewListing) => (!listing || !listing.id ? null : <ListingLoiteringStatus listing={listing} />),
    },
    {
      title: (
        <Tooltip title={renderFormatMessage('dashboard.text.access.description', 'Access status of the charger')}>
          {accessText}
        </Tooltip>
      ),
      key: 'access',
      width: '8%',
      render: (listing: NewListing) =>
        !listing || !listing.id ? null : <AccessPermission permission={listing.permission} />,
    },
    {
      title: (
        <Tooltip
          title={renderFormatMessage('dashboard.text.serviceStatus.description', 'Service status of the charger')}
        >
          {serviceStatusText}
        </Tooltip>
      ),
      key: 'service_status',
      width: '10%',
      render: (listing: NewListing) =>
        !listing || !listing.id ? null : listing.connector && listing.connector.serviceStatus ? (
          <ServiceStatusBadge state={listing.connector.serviceStatus} />
        ) : null,
    },
    {
      title: (
        <Tooltip
          title={renderFormatMessage(
            'dashboard.text.connectorStatus.description',
            'The last charging status received from the charger',
          )}
        >
          {lastChargingStatusText}
        </Tooltip>
      ),
      key: 'connector_status',
      width: 185,
      render: (listing: NewListing) => renderConnectorStatus(listing),
    },
    {
      title: (
        <Tooltip
          title={renderFormatMessage(
            'dashboard.text.connectivity.description',
            'The current connectivity status of the charger',
          )}
        >
          {networkSVG}
        </Tooltip>
      ),
      key: 'connectivity',
      align: 'center',
      width: 100,
      render: (listing: NewListing) => isChargerOnline(listing),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'right',
      render: (listing: NewListing) => <ActionsMenu listing={listing} />,
    },
  ]

  return columns
}
