import { Form } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'

import moment from 'moment'
import { SwtchError } from '../models/error'
import { ListingStatusUpdate, NewListing, ServiceStatusDescription, ServiceStatusReasons } from '../models/listing'
import {
  GetServiceStatusDescriptions,
  GetServiceStatusReasons,
  UpdateListingsStatus,
} from '../services/data-provider/listing'
import { useNotifications } from './useNotification'
import { useServiceStatusReasonsTranslation } from './translation/useServiceStatusReasonsTranslation'
import { DropdownServiceStatusOptions } from 'models/connector'
import { DropdownOption } from 'models/option'
import { log } from 'logger'

export const useListingServiceStatus = (listings: NewListing[], onOk: () => void, onApply?: (values: any) => void) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [showOtherReasonField, setShowOtherReasonField] = useState(false)
  const [secondDropdownOptions, setSecondDropdownOptions] = useState<DropdownOption[]>([])
  const [currentReasons, setCurrentReasons] = useState<ServiceStatusReasons[]>([])
  const [dependentSelectorValue, setDependentSelectorValue] = useState(form.getFieldValue('reason'))
  const [serviceDescriptions, setServiceDescriptions] = useState<ServiceStatusDescription[]>([])

  const { openSuccessNotification, openErrorNotification } = useNotifications()

  const selectRef = useRef<any>(null)

  const [openDecommisionedModal, setOpenDecommisionedModal] = useState(false)

  const isSameTenant = listings.every((item) => item?.tenant?.id === listings[0]?.tenant?.id)
  const isSameStatus = listings.every(
    (item) => item?.connector?.serviceStatus === listings[0]?.connector?.serviceStatus,
  )

  const prevStatus = listings[0]?.connector?.serviceStatus || ''

  const newStatus = form.getFieldValue('new_status')

  const limitedStatus: { [key: string]: string } = {
    active: 'under_repair',
    under_repair: 'active',
  }

  const isAnyListingActive = listings.some((listing) => listing.connector?.serviceStatus === 'active')

  const statusChangable = isSameStatus && isSameTenant

  useEffect(() => {
    getDescriptions()
  }, [])

  const displayStatusDetail = (newStatus: string) =>
    isSameStatus && isSameTenant && limitedStatus[prevStatus] === newStatus && !!newStatus

  const isDisableStatus = (status: string) => {
    if (!isSameTenant) {
      return true
    }
    if (status === 'under_repair') {
      return limitedStatus[prevStatus] !== status
    }

    // Disable the 'active' option if any listing is already 'active'
    if (status === 'active' && isAnyListingActive) {
      return true
    }

    // Enable the option if none of the listings are 'active'
    if (status === 'active' && !isAnyListingActive) {
      return false
    }

    return false
  }

  const filterListings = listings.filter((v, i) => {
    return listings.map((val) => val.id).indexOf(v.id) === i
  })

  const listingWithLatestStatusChanged =
    listings.length > 0
      ? listings.reduce((latest, current) => {
          if (!latest.serviceStatusChangedSince) {
            return current
          }
          if (!current.serviceStatusChangedSince) {
            return latest
          }
          return new Date(latest.serviceStatusChangedSince) > new Date(current.serviceStatusChangedSince)
            ? latest
            : current
        })
      : null // Return null or any default value if listings is empty

  const {
    breakerResetText,
    cableReplacementText,
    initialActivationText,
    chargerIsOfflineText,
    chargerIsDamagedText,
    chargerIsFaultedText,
    chargerIsVandalisedText,
    hardwarePartsReplacementDCFCText,
    lackOfFeatureText,
    networkResetText,
    networkHardwareReplacementText,
    otherPleaseSpecifyText,
    poorCustomerServiceText,
    poorHardwareQualityText,
    productBugText,
    priceIsNotCompetitiveText,
    stationIsMalfunctioningText,
    unitReplacementText,
  } = useServiceStatusReasonsTranslation()

  // Options for the second dropdown based on the selection of the first dropdown
  const optionsForSecondDropdown: DropdownServiceStatusOptions = {
    under_repair: [
      { key: 'charger_is_offline', label: chargerIsOfflineText },
      { key: 'charger_is_faulted', label: chargerIsFaultedText },
      { key: 'charger_is_damaged', label: chargerIsDamagedText },
      { key: 'charger_is_vandalised', label: chargerIsVandalisedText },
      { key: 'station_is_malfunctioning', label: stationIsMalfunctioningText },
      { key: 'other', label: otherPleaseSpecifyText },
    ],
    decommissioned: [
      { key: 'price_is_not_competitive', label: priceIsNotCompetitiveText },
      { key: 'lack_of_feature', label: lackOfFeatureText },
      { key: 'product_bug', label: productBugText },
      { key: 'poor_customer_service', label: poorCustomerServiceText },
      { key: 'poor_hardware_quality', label: poorHardwareQualityText },
      { key: 'other', label: otherPleaseSpecifyText },
    ],
    active: [
      { key: 'initial_activation', label: initialActivationText },
      { key: 'unit_replacement', label: unitReplacementText },
      { key: 'cable_replacement', label: cableReplacementText },
      { key: 'hardware_parts_replacement', label: hardwarePartsReplacementDCFCText },
      { key: 'breaker_reset', label: breakerResetText },
      { key: 'network_reset', label: networkResetText },
      { key: 'network_hardware_replacement', label: networkHardwareReplacementText },
      { key: 'other', label: otherPleaseSpecifyText },
    ],
  }

  const closeDecommisionedModal = () => setOpenDecommisionedModal(false)
  const handleFocus = () => selectRef.current.focus()

  const getReasons = (newStatus: string) => {
    setError(undefined)
    if (!newStatus) {
      setCurrentReasons([])
      return
    }
    GetServiceStatusReasons({
      previousStatus: prevStatus,
      newStatus,
    })
      .then(({ data }) => setCurrentReasons(data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const getDescriptions = useCallback(() => {
    setLoading(true)
    setError(undefined)
    GetServiceStatusDescriptions()
      .then((data) => {
        setServiceDescriptions(data)
      })
      .catch(setError)
      .finally(() => setLoading(false))
  }, [])

  const onServiceStatusChange = (event: string) => {
    getReasons(event)
    // Set options for the second dropdown based on the selection of the first dropdown
    switch (event) {
      case 'decommissioned':
        setSecondDropdownOptions(optionsForSecondDropdown['decommissioned'])
        handleFocus()
        break
      default:
        setSecondDropdownOptions([])
        setShowOtherReasonField(false)
        break
    }
    setDependentSelectorValue(undefined)
    form.setFieldsValue({ reason: undefined })
    form.setFields([
      {
        name: 'reason',
        value: null,
      },
    ])
    //reset other fields that appear after this
    form.setFieldsValue({
      status_changed_since: moment(),
      status_tracking_notes: undefined,
      resolution_id: null,
      event_notes: undefined,
      root_cause_id: undefined,
      zendesk_id: undefined,
    })
  }

  const handleSelect = (value: string) => {
    if (value === 'other') {
      // Close the dropdown when 'other' is selected
      if (selectRef) {
        selectRef.current.blur()
      }
    }
  }

  const onReasonChange = (event: string) => {
    // Set options for the second dropdown based on the selection of the first dropdown
    setDependentSelectorValue(event)

    if (event.includes('other')) {
      setShowOtherReasonField(true)
    } else {
      setShowOtherReasonField(false)
    }
  }

  const showAlertIfDecommissioned = (values: ListingStatusUpdate) => {
    if (values['new_status'] === 'decommissioned') {
      setOpenDecommisionedModal(true)
    } else {
      onFinish(values)
    }
  }

  const decommisionedModalOnFinish = () => {
    closeDecommisionedModal()
    onFinish(form.getFieldsValue())
  }

  const onFinish = (values: ListingStatusUpdate) => {
    log('onFinish', values)
    const body: ListingStatusUpdate = {
      ...values,
      listing_ids: filterListings.map((f) => f.id),
      previous_status: prevStatus,
      status_changed_since: moment(values?.status_changed_since).utc().toISOString(),
      resolution_id: values?.resolution_id ?? null,
    }
    log('onFinish', body)
    if (onApply) {
      log('onApply', onApply)
      onApply(body)
      onOk()
    } else {
      log('else', body)
      setError(undefined)
      setLoading(true)
      UpdateListingsStatus(body)
        .then((resp) => {
          if (resp && resp.success === true) {
            openSuccessNotification(resp.msg)
          } else {
            openErrorNotification(resp.msg)
          }
          onOk()
        })
        .catch((err) => setError(err))
        .finally(() => setLoading(false))
    }
  }
  return {
    error,
    loading,
    selectRef,
    showOtherReasonField,
    secondDropdownOptions,
    dependentSelectorValue,
    handleSelect,
    onServiceStatusChange,
    onFinish,
    showAlertIfDecommissioned,
    openDecommisionedModal,
    closeDecommisionedModal,
    form,
    decommisionedModalOnFinish,
    isSameTenant,
    isSameStatus,
    isDisableStatus,
    displayStatusDetail,
    newStatus,
    statusChangable,
    currentReasons,
    serviceDescriptions,
    listingWithLatestStatusChanged,
    onReasonChange,
  }
}
