import { Button, DatePicker, Form, Input, Modal, Select } from 'antd'
import { layout } from 'atom/form/modal-layout'
import { UpdateListingModalWrapper } from 'atom/listings'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { useIncidencesTranslation } from 'hooks/translation/useIncidencesTranslation'
import { UpdateIncidenceParams } from 'models/incidences'
import moment from 'moment'
import { Heading } from '../../../atom/user-edit'
import { ServiceStatusDescription, ServiceStatusReasons } from '../../../models/listing'
import { useAppState } from '../../../state'
import { usePeakShavingTranslation } from 'hooks/translation/usePeakShavingTranslation'

interface UpdateIncidencesModalProps {
  onCancel: () => void
  currentReasons: ServiceStatusReasons[]
  initialValues: any
  serviceDescriptions: ServiceStatusDescription[]
  loading: boolean
  onFinish: (params: UpdateIncidenceParams) => void
}

const UpdateIncidencesModal: React.FC<UpdateIncidencesModalProps> = ({
  onCancel,
  currentReasons,
  initialValues,
  serviceDescriptions,
  loading,
  onFinish,
}) => {
  const { language } = useAppState()

  const [form] = Form.useForm()
  const { applyText } = useGeneralTranslation()
  const { isRquiredText } = usePeakShavingTranslation()
  const {
    zendeskIdText,
    rootCauseText,
    downtimeText,
    resolutionText,
    startTimeText,
    endTimeText,
    editIncidenceText,
    includeText,
    excludeText,
  } = useIncidencesTranslation()

  const chooseLayout = () => {
    let frLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 24 },
    }
    return language === 'fr' ? frLayout : layout
  }

  const modalTitle = editIncidenceText

  const downtimeReportDropdown = [
    {
      key: 'include',
      label: includeText,
    },
    {
      key: 'exclude',
      label: excludeText,
    },
  ]

  return (
    <Modal
      title={<Heading>{modalTitle}</Heading>}
      visible={true}
      onCancel={onCancel}
      footer={null}
      width={language === 'fr' ? 800 : 700}
      bodyStyle={{ padding: '10px 24px 24px 24px' }}
    >
      <UpdateListingModalWrapper language={language}>
        <Form form={form} {...chooseLayout()} onFinish={onFinish} initialValues={initialValues}>
          <Form.Item name="zendesk_id" rules={[{ required: false }]} label={zendeskIdText} className="service-status">
            <Input placeholder={zendeskIdText} />
          </Form.Item>
          <Form.Item
            name="root_cause_id"
            rules={[{ required: true, message: isRquiredText.replace('-1', rootCauseText) }]}
            label={rootCauseText}
            className="service-status shorter-red"
          >
            <Select
              optionFilterProp="children"
              showSearch
              placeholder={rootCauseText}
              disabled={loading}
              loading={loading}
            >
              {currentReasons.map((option) => (
                <Select.Option value={option.id}>{option.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="downtime" label={downtimeText}>
            <Select placeholder={downtimeText} disabled={loading} loading={loading}>
              {downtimeReportDropdown.map((option) => (
                <Select.Option value={option.key}>{option.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="resolution_id"
            rules={[{ required: true, message: isRquiredText.replace('-1', resolutionText) }]}
            label={resolutionText}
            className="service-status shorter-red"
          >
            <Select
              showSearch
              optionFilterProp="children"
              placeholder={resolutionText}
              disabled={loading}
              loading={loading}
            >
              {serviceDescriptions.map((option) => (
                <Select.Option value={option.id}>{option.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="start_time" label={startTimeText} className="service-status">
            <DatePicker
              format="YYYY/MM/DD hh:mm A"
              disabled
              dropdownClassName={'inside-modal time-date-picker'}
              showTime={{ use12Hours: true }}
            />
          </Form.Item>
          <Form.Item name="end_time" label={endTimeText} className="service-status">
            <DatePicker
              format="YYYY/MM/DD hh:mm A"
              dropdownClassName={'inside-modal time-date-picker'}
              disabledDate={(current) => current <= moment(initialValues.start_time)}
              showTime={{ use12Hours: true }}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {applyText}
            </Button>
          </Form.Item>
        </Form>
      </UpdateListingModalWrapper>
    </Modal>
  )
}

export default UpdateIncidencesModal
