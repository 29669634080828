export type DownTimeType = 'include' | 'exclude'

export const defaultStatus = {
  previousStatus: 'under_repair',
  newStatus: 'active',
}
export interface Incidences {
  id: number
  category: string
  externalReference?: string
  zendeskId?: string
  duration: string
  downtime: string
  notes?: string
  createdBy?: string
  updatedBy?: string
  listingTitle: string
  rootCause?: string
  startTime: string
  resolution?: string
  endTime?: string
}

export interface UpdateIncidenceParams {
  zendesk_id?: string
  root_cause_id: number
  resolution_id?: number
  downtime: string
  end_time?: string
  start_time: string
}
