import { ListingFull, ListingKind } from '../models/listing'
import caseConverter from '../services/data-provider/case-converter'

export const formatListingCategory = (kind: ListingKind) => {
  let result = ''
  switch (kind) {
    case 'no_reservation':
    case 'non_reservation':
    case 'non-reservation':
      result = 'Non Reservation'
      break
    case 'reservation':
      result = 'Reservation'
      break
    case 'destination':
      result = 'Destination'
      break
    case 'non_network':
      result = 'Non Network'
      break
  }
  return result
}

export const sanitizeCreateNewListingObj = (listing: ListingFull): any => {
  let listingParams = undefined
  const snakeCasedListingModel = caseConverter.camelToSnakeCase(listing)

  if (snakeCasedListingModel.funding_sources) {
    const convertObjectToArray = Object.values(snakeCasedListingModel.funding_sources)
    snakeCasedListingModel.funding_sources = convertObjectToArray
  }

  if (!snakeCasedListingModel.enable_time_of_day_pricing) {
    const notAllowed = ['time_of_day_price_weekend', 'time_of_day_price_weekday', 'funding_sources']

    const filtered = Object.keys(snakeCasedListingModel)
      .filter((key) => !notAllowed.includes(key))
      .reduce((obj: any, key) => {
        obj[key] = snakeCasedListingModel[key]
        return obj
      }, {})
    listingParams = filtered
  }

  if (snakeCasedListingModel.price_type === 'kWh') {
    const notAllowed = ['enable_load_management_pricing']
    const filtered = Object.keys(snakeCasedListingModel)
      .filter((key) => !notAllowed.includes(key))
      .reduce((obj: any, key) => {
        obj[key] = snakeCasedListingModel[key]
        return obj
      }, {})
    listingParams = filtered
  }

  if (snakeCasedListingModel.enable_time_of_day_pricing) {
    snakeCasedListingModel.time_of_day_price_weekday = [
      ...Object.values(snakeCasedListingModel.time_of_day_price_weekday),
    ]
    snakeCasedListingModel.time_of_day_price_weekend = [
      ...Object.values(snakeCasedListingModel.time_of_day_price_weekend),
    ]
    listingParams = snakeCasedListingModel
  } else {
    delete snakeCasedListingModel.time_of_day_price_weekday
    delete snakeCasedListingModel.time_of_day_price_weekend
    listingParams = snakeCasedListingModel
  }

  return listingParams
}
