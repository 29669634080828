import React from 'react'

import { Col, Form, FormInstance, Input } from 'antd'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { PeakShavingAggregatorNameSelector } from 'components/selector/peak-shaving-aggregator-name-selector'
import { useSelector } from 'hooks/useSelector'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { PeakShavingEvent, PeakShavingParticipant } from '../../../models/peak-shaving'
import { GetAllParticipants, GetAllPeakShavingPrograms } from '../../../services/data-provider/peak-shaving'

interface props {
  form: FormInstance<any>
  formInitialValues: any
  updatedValues: any
  initialValues?: PeakShavingEvent
  onSelect: (fieldName: string, fieldToReturn?: string, isSingle?: boolean) => (selectedOptions: any[] | any) => void
}

export const EventDetailsForm: React.FC<props> = ({
  form,
  formInitialValues,
  updatedValues,
  onSelect,
  initialValues,
}) => {
  const {
    programText,
    frequencyText,
    notestText,
    participantsText,
    isRquiredText,
    aggregatorNameText,
    cannotExceedText,
    oneTimeText,
  } = usePeakShavingTranslation()

  const {
    generalDebounceFetcher,

    handleSingleSelectChange,
    renderSingleOption,

    renderMultiOptions,
    multiHandlePlaceholder,
    multiHandleChange,
  } = useSelector()

  return (
    <Col span={10}>
      <Form.Item
        label={<p className="paragraph-02-regular">{aggregatorNameText}</p>}
        name="aggregatorName"
        className="program"
        rules={[{ required: true, message: isRquiredText.replace('-1', aggregatorNameText) }]}
      >
        <PeakShavingAggregatorNameSelector
          disabled={!!initialValues}
          placeholder={aggregatorNameText}
          showMagnifySVG={false}
        />
      </Form.Item>
      <Form.Item
        label={<p className="paragraph-02-regular">{programText}</p>}
        name="program"
        className="program"
        rules={[{ required: true, message: isRquiredText.replace('-1', programText) }]}
      >
        <ReusableSelector
          showSearch
          disabled={!updatedValues['aggregatorName'] || !!initialValues}
          showArrow={false}
          onOptionsChange={onSelect('program')}
          customWidth="250px"
          isSingle
          defaultValues={formInitialValues?.program ? [formInitialValues?.program.name] : undefined}
          dropDownList={formInitialValues?.program ? [formInitialValues?.program] : undefined}
          oneTimeUpdateDefaultValues
          placeholder={programText}
          showMagnifySVG={false}
          maxTagCount={0}
          isDebounceFetcher={true}
          debounceFetcher={generalDebounceFetcher(
            (programName) =>
              GetAllPeakShavingPrograms({
                programName,
                aggregatorName: updatedValues['aggregatorName'],
              }),
            true,
            'data',
          )}
          handleOptionChange={handleSingleSelectChange('name')}
          renderOption={renderSingleOption(
            {
              val: 'name',
              label: 'name',
            },
            false,
          )}
        />
      </Form.Item>
      <Form.Item label={<p className="paragraph-02-regular">{frequencyText}</p>} name="frequency">
        <p className="paragraph-04-regular">{oneTimeText}</p>
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: isRquiredText.replace('-1', participantsText) }]}
        label={<p className="paragraph-02-regular">{participantsText}</p>}
        name="participants"
      >
        <ReusableSelector
          showSearch
          onOptionsChange={onSelect('participants', 'id', false)}
          customWidth="250px"
          fieldForValue="id"
          disabled={!updatedValues['aggregatorName'] || !!initialValues}
          defaultValues={
            formInitialValues?.participants
              ? formInitialValues?.participants?.map((val: PeakShavingParticipant) => ({
                  ...val,
                  id: val.participantId,
                }))
              : []
          }
          dropDownList={
            formInitialValues?.participants
              ? formInitialValues?.participants?.map((val: PeakShavingParticipant) => ({
                  ...val,
                  id: val.participantId,
                }))
              : []
          }
          isSingle={false}
          showArrow={false}
          placeholder={participantsText}
          showMagnifySVG={false}
          maxTagCount={0}
          isDebounceFetcher={true}
          handlePlaceholder={multiHandlePlaceholder('participantRef', 'Participants')}
          debounceFetcher={generalDebounceFetcher((refs) => GetAllParticipants({ refs }))}
          handleOptionChange={multiHandleChange('id')}
          renderOption={renderMultiOptions({
            val: 'id',
            label: 'participantRef',
          })}
        />
      </Form.Item>
      <Form.Item
        label={<p className="paragraph-02-regular">{notestText}</p>}
        name="notes"
        className="notes"
        rules={[{ required: false, max: 255, message: cannotExceedText.replace('-1', notestText) }]}
      >
        <Input.TextArea placeholder={notestText} />
      </Form.Item>
    </Col>
  )
}
