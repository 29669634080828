import { Button, Col, Modal, Row, Space } from 'antd'

import { useListingBasicTrans } from '../../../hooks/translation/useListingBasicTrans'

import { ArrowRightOutlined, WarningOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { theme } from 'theme'
import { Heading } from '../../../atom/user-edit'
interface props {
  visible: boolean
  onOk: () => void
  onCancel: () => void
  description: string
  options: {
    left: string
    right: string
  }[]
}

const ReminderModalContainer = styled.div`
  p {
    margin: 0;
    margin-top: 5px;
  }
`

export const ReminderModal: React.FC<props> = ({ visible, onOk, onCancel, description, options }) => {
  const { reminder, acknowledge, cancel } = useListingBasicTrans()
  return (
    <Modal
      title={<Heading>{reminder}</Heading>}
      visible={visible}
      onCancel={onCancel}
      style={{ top: 130 }}
      footer={
        <Space>
          <Button type="primary" onClick={onOk}>
            {acknowledge}
          </Button>
          <Button type="ghost" onClick={onCancel}>
            {cancel}
          </Button>
        </Space>
      }
      width={400}
      bodyStyle={{ padding: '10px 24px 24px 24px' }}
    >
      <ReminderModalContainer>
        <Row align="middle">
          <WarningOutlined style={{ fontSize: '60px', color: theme.colors.alert1000 }} />
          <Col flex="1" style={{ marginLeft: 10 }}>
            <p className="paragraph-03-regular">{description}</p>
            {options.map((option) => (
              <p className="paragraph-03-regular">
                {option.left} <ArrowRightOutlined />
                {option.right}
              </p>
            ))}
          </Col>
        </Row>
      </ReminderModalContainer>
    </Modal>
  )
}
