import { Col, Row, Space } from 'antd'
import { useAppState } from '../../../../state'
import { ButtonIcon, ButtonModal } from '../../../../atom/button'
import { LineBreak } from '../../../../atom/line-break'
import { InputsContainer, MobileInputsContainer } from '../../../../atom/reports'
import { LocationSelector } from '../../../../components/redesign/location-selector'
import { CustomDateRangePicker } from '../../../../components/redesign/datepicker'
import { MultiTenantSelector } from '../../../../components/redesign/multiple-tenant-selector'
import MultiListingTitleSelector from 'components/redesign/MultiListingIdTitleSelector'
import { magnifyBlackSVG } from '../../../../assets/svg/magnify'
import { TenantRef } from '../../../../models/tenant'
import { useCalendarTransactions } from '../../../../hooks/translation/useCalendarTranslation'
import { ListingRef } from 'models/listing'
import { MasterAccountRef } from 'models/master-account'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'
import { useMasterAccountSelector } from 'hooks/useMasterAccountSelector'
import { ReusableSelector } from 'components/reusableSelector/selector'

interface ReportsSelectorsProps {
  tenants: TenantRef[]
  loading: boolean
  startDate: moment.Moment
  endDate: moment.Moment
  getReports: () => void
  handleChangeDate: (dates: moment.Moment[]) => void
  setTenants: (tenants: TenantRef[]) => void
  setListingTitles: (listingTitles: string[]) => void
  handleLocation: (chosenCountries: string[], chosenProvinces: string[]) => void
  dropdownClassName?: string
  setMasterAccount: (masterAccount: MasterAccountRef | undefined) => void
}

const ReportsSelectors: React.FC<ReportsSelectorsProps> = ({
  tenants,
  loading,
  startDate,
  endDate,
  getReports,
  handleChangeDate,
  setTenants,
  setListingTitles,
  handleLocation,
  dropdownClassName,
  setMasterAccount,
}) => {
  const { IsMobile, IsTablet, IsLaptop, IsDesktop } = useAppState()
  const { currentUser } = useAppState()
  const { accountNameText } = useSelectorTranslation()
  const { options, handleMasterAccountSelectChange, renderMasterAccountOption } = useMasterAccountSelector(
    setMasterAccount,
  )
  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()
  const isAdmin = currentUser?.role === 'admin'

  const { calendarRanges } = useCalendarTransactions()

  return (
    <>
      {(isDesktop || isLaptop) && (
        <InputsContainer>
          <Space wrap size={[8, 8]}>
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChangeDate={handleChangeDate}
              calendarRanges={calendarRanges}
            />
            <MultiTenantSelector
              onOptionsChange={(tenants) => setTenants([...tenants])}
              onClear={() => setTenants([])}
              isCombineRight
              longerWidth
              dropdownClassName={dropdownClassName}
            />
            {isAdmin && (
              <>
                <LineBreak />
                <div className="master-account-selector">
                  <ReusableSelector
                    showSearch={true}
                    onClear={() => setMasterAccount(undefined)}
                    isSingle={true}
                    showMagnifySVG={false}
                    maxTagCount={0}
                    isDebounceFetcher={false}
                    handlePlaceholder={undefined}
                    debounceFetcher={undefined}
                    dropdownClassName={dropdownClassName}
                    placeholder={accountNameText}
                    dropDownList={options}
                    selectAllOptions={true}
                    combineType="both"
                    showArrow={false}
                    stopClearSearchValue={true}
                    handleOptionChange={handleMasterAccountSelectChange}
                    renderOption={renderMasterAccountOption}
                  />
                </div>
              </>
            )}
            <LineBreak />
            <div className="multi-listing-title-selector">
              <MultiListingTitleSelector
                dropdownClassName={dropdownClassName}
                onOptionChange={(listingTitle: ListingRef[]) => setListingTitles(listingTitle.map((val) => val.title))}
                setClear={() => setListingTitles([])}
                tenants={tenants}
                isCombineSelector
              />
            </div>
            <LineBreak />
            <LocationSelector reportsPage isCombineLeft removeSVG onSearch={handleLocation} loading={loading} />
            <ButtonIcon
              icon={magnifyBlackSVG}
              loading={loading}
              disabled={loading}
              fetchData={getReports}
              withinSearchBar
            />
          </Space>
        </InputsContainer>
      )}
      {(isMobile || isTablet) && (
        <MobileInputsContainer tablet={isTablet}>
          <Row gutter={[8, 8]} justify={isMobile ? 'start' : 'space-between'} align="middle">
            <Col xs={24} sm={24} md={24}>
              <CustomDateRangePicker
                startDate={startDate}
                endDate={endDate}
                onChangeDate={handleChangeDate}
                calendarRanges={calendarRanges}
              />
            </Col>
            <Col xs={24} sm={24} md={24}>
              <MultiTenantSelector
                onOptionsChange={(tenants) => setTenants([...tenants])}
                onClear={() => setTenants([])}
                isCombineRight
                longerWidth
                dropdownClassName={dropdownClassName}
              />
            </Col>
            {isAdmin && (
              <Col xs={24} sm={24} md={24}>
                <ReusableSelector
                  showSearch={true}
                  onClear={() => setMasterAccount(undefined)}
                  isSingle={true}
                  showMagnifySVG={false}
                  maxTagCount={0}
                  isDebounceFetcher={false}
                  handlePlaceholder={undefined}
                  debounceFetcher={undefined}
                  dropdownClassName={dropdownClassName}
                  placeholder={accountNameText}
                  dropDownList={options}
                  selectAllOptions={true}
                  combineType="both"
                  stopClearSearchValue={true}
                  handleOptionChange={handleMasterAccountSelectChange}
                  renderOption={renderMasterAccountOption}
                />
              </Col>
            )}
            <Col xs={24} sm={24} md={24}>
              <div className="multi-listing-title-selector">
                <MultiListingTitleSelector
                  dropdownClassName={dropdownClassName}
                  onOptionChange={(listingTitle: ListingRef[]) =>
                    setListingTitles(listingTitle.map((val) => val.title))
                  }
                  setClear={() => setListingTitles([])}
                  tenants={tenants}
                  isCombineSelector
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <LocationSelector reportsPage isCombineLeft onSearch={handleLocation} loading={loading} />
            </Col>
            <div className="button-row">
              <ButtonModal size="large" click={getReports} icon={magnifyBlackSVG} text={'Search'} type="primary" />
            </div>
          </Row>
        </MobileInputsContainer>
      )}
    </>
  )
}

export default ReportsSelectors
