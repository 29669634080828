import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'

export const useListingBasicTrans = () => {
  const characterMaxLimitText = useFormatMessage(
    'dashboard.createNewListing.listingStep.detailedDescription.warning',
    '255 characters is the limit',
  )
  const listingAlreadyText = useFormatMessage(
    'dashboard.listing.listingAlreadyExistsText',
    'Listing title already exists',
  )
  const listingDescriptionText = useFormatMessage(
    'dashboard.createNewListing.listingStep.detailedDescription.info',
    'If your description contains YouTube links, the videos will be shown below the description',
  )
  const listingInfoText = useFormatMessage(
    'dashboard.createNewListing.listingStep.detailedDescription.heading',
    'Listing Info',
  )
  const listingTitleRequiredText = useFormatMessage(
    'dashboard.listing.createListingRequiredText',
    'Listing Title is required',
  )
  const listingTitleText = renderFormatMessage('dashboard.createNewListing.listingStep.heading', 'Listing Title')
  const listingCategoryText = useFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.Txt',
    'Listing Category',
  )
  const listingNonResText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.nonreservation',
    'Non Reservation',
  )
  const listingResText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.Reservation',
    'Reservation',
  )
  const listingDestText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.Destination',
    'Destination',
  )
  const listingNonNetworkText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.listingCategory.Non Network',
    'Non Network',
  )
  const listingWalletFuncText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.walletCategory.txt',
    'Wallet Functionality',
  )
  const listingWalletText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.walletCategory.walletOption',
    'Wallet',
  )
  const listingNoWalletText = renderFormatMessage(
    'dashboard.createNewListing.listingStep.walletCategory.nowalletOption',
    'No Wallet',
  )
  const listingText = renderFormatMessage('dashboard.text.listing', 'Listing')

  const listingTitlesText = useFormatMessage('dashboard.text.listingTitles', 'Listing Title (s)')

  const listingIDText = useFormatMessage('dashboard.text.listingID', 'Listing ID')
  const listingFundingSourcesText = useFormatMessage('dashboard.text.fundingSources', 'Funding Sources')

  const trailerAccessibleText = useFormatMessage('dashboard.text.trailerAccessible', 'Charge vehicle with trailer')
  const fundingSourcesPlaceholderText = useFormatMessage(
    'dashboard.text.fundingSourcesPlaceholder',
    'funding sources placeholder',
  )
  const updateListingText = useFormatMessage('dashboard.listingPage.modal.updateListing', 'Update Listing')
  const selectedText = useFormatMessage('dashboard.chargersPage.modal.updateListing.selected', 'selected')
  const serviceStatusText = useFormatMessage('dashboard.selector.serviceStatus', 'Service Status')
  const updateServiceStatusText = useFormatMessage(
    'dashboard.createNewListing.listingStep.model.title',
    'Update Listing Service Status',
  )
  const editStatusButtonText = useFormatMessage(
    'dashboard.createNewListing.listingStep.serviceStatus',
    'Edit Service Status',
  )
  const statusIsRequired = useFormatMessage(
    'dashboard.listingPage.modal.serviceStatus.isrequired',
    'Status is required',
  )
  const statusUpdateSuccessMessage = useFormatMessage(
    'dashboard.listingPage.modal.serviceStatus.successMessage',
    'Status for selected listings has been updated.',
  )
  const statusUpdateFailedMessage = useFormatMessage(
    'dashboard.listingPage.modal.serviceStatus.successMessage',
    'Status for selected listings has not been updated.',
  )
  const pricingSchemaCanNotUpdate = useFormatMessage(
    'dashboard.listingPage.modal.pricingSchema.pricingSchemaCanNotUpdate',
    'To apply a pricing schema to a group of listings, all listings must belong to the same site.',
  )
  const pricingSchemaIsRequired = useFormatMessage(
    'dashboard.listingPage.modal.pricingSchema.isrequired',
    'Pricing Schema is required',
  )

  const reminder = useFormatMessage('dashboard.listingPage.modal.decommisioned.reminder', 'Reminder')

  const decommissionedDescription = useFormatMessage(
    'dashboard.chargersPage.modal.decommisioned.description',
    'Please note that changing Listing Service Status to Decommisioned will also update the following:',
  )
  const decommissionedOption1Left = useFormatMessage(
    'dashboard.chargersPage.modal.decommisioned.option1.left',
    '• Access ',
  )
  const decommissionedOption1Right = useFormatMessage(
    'dashboard.chargersPage.modal.decommisioned.option1.right',
    'Restricted',
  )
  const decommissionedOption2Left = useFormatMessage(
    'dashboard.chargersPage.modal.decommisioned.option2.left',
    '• Hidden from Map ',
  )
  const decommissionedOption2Right = useFormatMessage(
    'dashboard.chargersPage.modal.decommisioned.option2.right',
    'True',
  )
  const acknowledge = useFormatMessage('dashboard.chargersPage.modal.decommisioned.acknowledge', 'Acknowledge')
  const cancel = useFormatMessage('dashboard.chargersPage.modal.decommisioned.cancel', 'Cancel')
  const accessType = useFormatMessage('dashboard.createNewListing.reviewStep.listing.accessType', 'Access Type')
  const settlementText = useFormatMessage('dashboard.listingPage.modal.settlement', 'Settlement')
  const fromText = useFormatMessage('dashboard.chargersPage.modal.updateListing.from', 'from')
  const updateText = useFormatMessage('dashboard.chargersPage.modal.updateListing.update', 'Update')
  const vendorstext = useFormatMessage('dashboard.selector.vendors', 'Vendor (s)')
  const makesText = useFormatMessage('dashboard.selector.makes', 'Make (s)')

  const swtchCareText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.swtchCare', 'SWTCH Care')
  const buildingWifiText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.buildingWifi', 'Building Wifi')
  const currentStatusText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.currentStatus', 'Current Status')
  const sinceText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.since', '-1 since -2')
  const aboutText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.about', 'About selected listing: -1')
  const issueDetailsText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.issueDetails', 'Issue Details')
  const issueSymptomText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.issueSymptom', 'Issue Symptom')
  const detailsText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.details', 'Details')
  const serviceEventText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.serviceEvent', 'Service Event')
  const excludeText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.exclude', 'Exclude')
  const includeText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.include', 'Include')
  const resolutionText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.resolution', 'Resolution')
  const rootCauseText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.rootCause', 'Root Cause')
  const zendeskIdText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.zendeskId', 'Zendesk ID')
  const uptimeReportText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.uptimeReport', 'Uptime Report')
  const statusChangeText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.statusChange', 'Status Change')
  const warningText = useFormatMessage(
    'dashboard.listingPage.modal.bulkUpdate.warning',
    'Listings can only be modified if they share the same site and service status',
  )
  const helpText = useFormatMessage(
    'dashboard.listingPage.modal.bulkUpdate.help',
    'For selected listing above, the logged symptom when the status was changed to Under Repair are: Offline',
  )
  const newStatusSinceText = useFormatMessage(
    'dashboard.listingPage.modal.bulkUpdate.newStatusSince',
    'New Status Since',
  )
  const underRepairSinceText = useFormatMessage(
    'dashboard.listingPage.modal.bulkUpdate.underRepairSince',
    'Under Repair Since',
  )
  const notesText = useFormatMessage('dashboard.listingPage.modal.bulkUpdate.notes', 'Notes')

  return {
    listingText,
    characterMaxLimitText,
    listingAlreadyText,
    listingDescriptionText,
    listingInfoText,
    listingTitleRequiredText,
    listingTitleText,
    listingCategoryText,
    listingNonResText,
    listingResText,
    listingDestText,
    listingNonNetworkText,
    listingWalletFuncText,
    listingWalletText,
    listingNoWalletText,
    listingTitlesText,
    listingIDText,
    listingFundingSourcesText,
    trailerAccessibleText,
    fundingSourcesPlaceholderText,
    updateListingText,
    statusIsRequired,
    selectedText,
    serviceStatusText,
    statusUpdateSuccessMessage,
    statusUpdateFailedMessage,
    pricingSchemaCanNotUpdate,
    pricingSchemaIsRequired,
    editStatusButtonText,
    updateServiceStatusText,
    reminder,
    decommissionedDescription,
    decommissionedOption1Left,
    decommissionedOption1Right,
    decommissionedOption2Left,
    decommissionedOption2Right,
    acknowledge,
    cancel,
    accessType,
    settlementText,
    fromText,
    updateText,
    vendorstext,
    makesText,
    swtchCareText,
    buildingWifiText,
    currentStatusText,
    sinceText,
    aboutText,
    issueDetailsText,
    issueSymptomText,
    detailsText,
    serviceEventText,
    excludeText,
    includeText,
    resolutionText,
    rootCauseText,
    zendeskIdText,
    uptimeReportText,
    statusChangeText,
    warningText,
    helpText,
    newStatusSinceText,
    underRepairSinceText,
    notesText,
  }
}
