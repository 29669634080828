import { theme } from '../../theme'

export const resetSVG = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M15.2303 5.40005C13.9794 3.24796 11.6412 1.80005 8.9633 1.80005C5.92701 1.80005 3.32756 3.6614 2.25429 6.30005M13.0329 6.30005H16.6504V2.70005M2.77047 12.6C4.02142 14.7521 6.35954 16.2 9.03748 16.2C12.0738 16.2 14.6732 14.3387 15.7465 11.7M4.96785 11.7H1.35039V15.3"
      stroke={theme.colors.darkGray}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
