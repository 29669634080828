import { Form } from 'antd'
import { CustomSuccessMessage } from 'components/peakShaving/message'
import { usePeakShavingTranslation } from 'hooks/translation/usePeakShavingTranslation'
import { SwtchApiError } from 'models/error'
import { CurtailmentType, PeakShavingCurtailment, PeakShavingEvent } from 'models/peak-shaving'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  CreateCurtailmentScheduleForEvent,
  CreateNewPeakShavingEvent,
  DeleteCurtailmentScheduleForEvent,
  GetAllCurtailmentSchedulesForEvent,
  PublishPeakShavingEvent,
  UpdateCurtailmentScheduleForEvent,
} from 'services/data-provider/peak-shaving'

export const useDemandManagementEventDetail = (
  onSubmit: () => void,
  onCancel: () => void,
  initialValues?: PeakShavingEvent,
) => {
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [initialValueLoading, setInitialValueLoading] = useState(true)

  const [form] = Form.useForm()
  const [error, setError] = useState<SwtchApiError>()
  const eventSchedules = Array.from(Array(24), (_, index) => index)

  const initialCurtailments = { curtailmentPercent: undefined, from: undefined, to: undefined }

  const formatInitialValues = (values: PeakShavingEvent) => ({
    ...values,
    aggregatorName: values?.program?.aggregatorName,
    participants: values?.participant ? [values?.participant] : undefined,
  })
  const [formInitialValues, setFormInitialValues] = useState<any>({})

  const [updatedValues, setUpdatedValues] = useState({})

  const timeFormat = (time: number) => (time < 10 ? `0${time}:00` : `${time}:00`)

  const successKey = !!initialValues ? 'UpdateEventAndSchedule' : 'CreateNewEventAndSchedule'
  const {
    eventCreationSuccessText,
    eventUpdateSuccessText,
    chargingText,
    fullSpeedText,
    timeRangeWarningText,
  } = usePeakShavingTranslation()

  const curtailmentPercent = [
    { label: chargingText.replace('-1', 'No'), value: 1 }, // 0% is represented as 1 just to show on the dropdown. It will be converted to 0 before submitting
    { label: chargingText.replace('-1', '40%'), value: 40 },
    { label: chargingText.replace('-1', '60%'), value: 60 },
    { label: chargingText.replace('-1', '80%'), value: 80 },
    { label: fullSpeedText.replace('-1', '100%'), value: 100 },
  ]

  const valuesToBeChecked = [
    { field: 'aggregatorName' },
    { field: 'date' },
    { field: 'curtailments', fieldIsArray: true, subFields: ['curtailmentPercent', 'from', 'to'] },
    { field: 'participants', fieldIsArray: true },
    { field: 'program' },
  ]

  useEffect(() => {
    if (initialValues) {
      fetchCurtailmentsForEvent(initialValues.id)
    } else {
      setFormInitialValues({
        date: moment().format('YYYY-MM-DD'),
        frequency: 'manual',
        curtailments: [initialCurtailments],
      })
      setInitialValueLoading(false)
    }
  }, [initialValues])

  useEffect(() => {
    setUpdatedValues(formInitialValues)
  }, [formInitialValues])

  const fetchCurtailmentsForEvent = (id: number) => {
    setLoading(true)
    setError(undefined)
    GetAllCurtailmentSchedulesForEvent(id)
      .then((curtailments) => {
        // convert 0% to 1% to show on the dropdown but convert back to 0% before submitting
        curtailments.forEach((curtailment: PeakShavingCurtailment) => {
          if (curtailment.curtailmentPercent === 0) {
            curtailment.curtailmentPercent = 1
          }
          return curtailment
        })
        setFormInitialValues({
          date: moment().format('YYYY-MM-DD'),
          frequency: 'manual',
          curtailments,
          ...(initialValues && formatInitialValues(initialValues)),
        })
      })
      .catch(setError)
      .finally(() => {
        setLoading(false)
        setInitialValueLoading(false)
      })
  }

  const returnField = (value: any, fieldToReturn?: string) => {
    if (fieldToReturn) return value[fieldToReturn]
    return value
  }

  const onSelect = (fieldName: string, fieldToReturn?: string, isSingle = true) => (selectedOptions: any[] | any) => {
    const updatedValue = isSingle
      ? returnField(selectedOptions, fieldToReturn)
      : (selectedOptions as any[]).map((option) => returnField(option, fieldToReturn))

    form.setFieldsValue({
      [fieldName]: updatedValue,
    })
    onUpdateValues(undefined, { ...updatedValues, [fieldName]: updatedValue })
  }

  const curtailmentsUpdate = (index: number, field: string) => ({ value }: { value: any }) => {
    const currentCurtailments = form.getFieldValue('curtailments') || []
    currentCurtailments[index] = {
      ...currentCurtailments[index],
      [field]: value,
    }

    onSelect('curtailments')(currentCurtailments)
  }

  const valueCheck = (values: any) => {
    return valuesToBeChecked.every(({ field, fieldIsArray, subFields }) => {
      const arrayValues = values?.[field]

      if (fieldIsArray) {
        if (!Array.isArray(arrayValues) || arrayValues.length === 0) {
          return false
        }

        // If subFields are specified, ensure all subfields are defined
        if (subFields) {
          return arrayValues.every((item: any) => subFields.every((subField) => item?.[subField] !== undefined))
        }

        return true
      }

      return !!arrayValues
    })
  }

  const onUpdateValues = (changedValues: any, values: any) => {
    if (changedValues?.curtailments) {
      values.curtailments = changedValues?.curtailments
    }
    setUpdatedValues(values)

    if (valueCheck(values)) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const validateTimeRange = (index: number) => {
    return (_: any, value: any) => {
      const curtailments = form.getFieldValue('curtailments') || []

      const currentFrom = form.getFieldValue(['curtailments', index, 'from'])
      const currentTo = form.getFieldValue(['curtailments', index, 'to'])

      // Check if both from and to are defined
      if (currentFrom === undefined || currentTo === undefined) {
        return Promise.resolve()
      }

      // Check for overlaps with other time ranges
      const hasOverlap = curtailments.some((item: any, idx: number) => {
        if (idx !== index) {
          // Skip current index
          return (
            (currentFrom < item.to && currentTo > item.from) || // Check overlap
            (currentTo > item.from && currentFrom < item.to)
          )
        }
        return false
      })

      if (hasOverlap) {
        return Promise.reject(new Error(timeRangeWarningText))
      }

      return Promise.resolve()
    }
  }

  const handleCancel = () => {
    setUpdatedValues(formInitialValues)
    form.resetFields()
    onCancel()
    setError(undefined)
    setDisabled(true)
  }

  const handleNoChargingCurtailmentChange = (values: any) => {
    // Check if curtailmentPercent is 1 and convert it to 0
    values.curtailments.forEach((curtailment: CurtailmentType) => {
      if (curtailment.curtailmentPercent === 1) {
        curtailment.curtailmentPercent = 0
      }
      return curtailment
    })
  }

  const handleFormSubmit = () => {
    form.validateFields().then(async (values) => {
      try {
        setDisabled(true)
        setLoading(true)
        setError(undefined)
        const { program, participants, notes, date, curtailments } = values

        // Check if the date is a MomentJS object
        let modifiedDate = values.date
        if (moment.isMoment(values.date)) {
          modifiedDate = values.date.format('YYYY-MM-DD')
        }

        // Check if curtailmentPercent is 1 and convert it to 0
        handleNoChargingCurtailmentChange(values)

        //Update event
        if (initialValues) {
          const cultailmentWithId = (curtailments as PeakShavingCurtailment[]).filter(({ id }) => id !== undefined)

          const newCurtailments = (curtailments as PeakShavingCurtailment[]).filter(({ id }) => id === undefined)
          let modifiedCurtailments: PeakShavingCurtailment[] = []
          let removedCurtailments: PeakShavingCurtailment[] = []
          formInitialValues.curtailments.forEach((initialCurtailment: PeakShavingCurtailment) => {
            const matchingCurtailment = cultailmentWithId.find(({ id }) => id === initialCurtailment.id)
            if (matchingCurtailment) {
              //Check if there is a modification
              const hasModifiedFields =
                matchingCurtailment.from !== initialCurtailment.from ||
                matchingCurtailment.to !== initialCurtailment.to ||
                matchingCurtailment.curtailmentPercent !== initialCurtailment.curtailmentPercent

              if (hasModifiedFields) {
                modifiedCurtailments.push(matchingCurtailment)
              }
            } else {
              removedCurtailments.push(initialCurtailment)
            }
          })

          await Promise.all([
            ...removedCurtailments.map(
              (curtailment: PeakShavingCurtailment) =>
                DeleteCurtailmentScheduleForEvent(formInitialValues.id, curtailment.id as number) as Promise<any>,
            ),
            ...newCurtailments.map(
              (curtailment: PeakShavingCurtailment) =>
                CreateCurtailmentScheduleForEvent(formInitialValues.id, curtailment) as Promise<any>,
            ),
            ...modifiedCurtailments.map(
              ({ id, dayType, zone, ...rest }: PeakShavingCurtailment) =>
                UpdateCurtailmentScheduleForEvent(formInitialValues.id, id as number, rest) as Promise<any>,
            ),
          ])
        } else {
          const events: PeakShavingEvent[] = await Promise.all(
            participants.map((participant: number) =>
              CreateNewPeakShavingEvent({
                date: modifiedDate,
                frequency: 'manual',
                programId: program.id,
                notes,
                participantId: participant,
                source: 'demand response',
              }),
            ),
          )
          //Create curtailment schedule
          await Promise.all(
            events.flatMap(({ id }: PeakShavingEvent) =>
              (curtailments as CurtailmentType[]).map(({ curtailmentPercent, ...rest }: CurtailmentType) =>
                CreateCurtailmentScheduleForEvent(id, {
                  ...rest,
                  curtailmentPercent,
                  dayType: moment(date).day() === 0 || moment(date).day() === 6 ? 'weekend' : 'weekday',
                }),
              ),
            ),
          )
          //publish
          await Promise.all(events.map(({ id }: PeakShavingEvent) => PublishPeakShavingEvent(id)))
        }
        CustomSuccessMessage({
          key: successKey,
          response: initialValues ? eventUpdateSuccessText : eventCreationSuccessText,
        })
        onSubmit()
      } catch (e) {
        setError(e as SwtchApiError)
      } finally {
        setLoading(false)
        setDisabled(false)
        form.resetFields()
      }
    })
  }

  return {
    loading,
    disabled,
    form,
    error,
    eventSchedules,
    timeFormat,
    curtailmentPercent,
    initialCurtailments,
    formInitialValues,
    updatedValues,
    initialValueLoading,
    onSelect,
    curtailmentsUpdate,
    onUpdateValues,
    handleCancel,
    handleFormSubmit,
    validateTimeRange,
  }
}
