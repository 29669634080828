import { Price } from '../models/price'

// NOTE: flatFee is dollar
export const mockPrice = [
  {
    preferedPricePerUnitCents: 300,
    pricePerUnitCents: 200,
    priceType: 'Hour',
    preferedPricingPriceType: 'Hour',
    flatFee: 0,
    enableTimeOfDayPricing: true,
    timeOfDayPriceWeekday: [...Array(8).fill(100), ...Array(7).fill(150), ...Array(9).fill(80), ...Array(24).fill(200)],
    timeOfDayPriceWeekend: [...Array(24).fill(200), ...Array(24).fill(300)],
  },
  {
    preferedPricePerUnitCents: 350,
    pricePerUnitCents: 250,
    priceType: 'kWh',
    preferedPricingPriceType: 'Hour',
    flatFee: 5,
    enableTimeOfDayPricing: true,
    timeOfDayPriceWeekday: [...Array(48).fill(100)],
    timeOfDayPriceWeekend: [...Array(48).fill(100)],
  },
  {
    preferedPricePerUnitCents: 400,
    pricePerUnitCents: 5,
    priceType: 'Hour',
    preferedPricingPriceType: 'Hour',
    flatFee: 0,
    enableTimeOfDayPricing: false,
    timeOfDayPriceWeekday: [...Array(48).fill(100)],
    timeOfDayPriceWeekend: [...Array(48).fill(100)],
  },
  {
    preferedPricePerUnitCents: 223,
    pricePerUnitCents: 223,
    priceType: 'Hour',
    preferedPricingPriceType: 'Hour',
    flatFee: 1.23,
    enableTimeOfDayPricing: true,
    timeOfDayPriceWeekday: [...Array(24).fill(223)],
    timeOfDayPriceWeekend: [...Array(24).fill(223)],
  },
  {
    preferedPricePerUnitCents: 0,
    pricePerUnitCents: 0,
    priceType: 'kWh',
    preferedPricingPriceType: 'Hour',
    flatFee: 5,
    enableTimeOfDayPricing: false,
    timeOfDayPriceWeekday: [...Array(48).fill(100)],
    timeOfDayPriceWeekend: [...Array(48).fill(100)],
  },
  {
    preferedPricePerUnitCents: 0,
    pricePerUnitCents: 0,
    priceType: 'hour',
    preferedPricingPriceType: 'Hour',
    flatFee: '0.0',
    enableTimeOfDayPricing: false,
    timeOfDayPriceWeekday: [...Array(48).fill(100)],
    timeOfDayPriceWeekend: [...Array(48).fill(100)],
  },
  {
    preferedPricePerUnitCents: 300,
    pricePerUnitCents: 400,
    priceType: 'kWh',
    preferedPricingPriceType: 'kWh',
    flatFee: 0,
    enableTimeOfDayPricing: false,
    timeOfDayPriceWeekday: [...Array(48).fill(100)],
    timeOfDayPriceWeekend: [...Array(48).fill(100)],
  },
] as Price[]
