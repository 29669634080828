import { Col, Row } from 'antd'

import { Section } from '../../../../atom/box'
import { TrxSummary } from '../../../../models/transaction'
import TransactionMetrics from './TransactionMetric'
import RevenueMetrics from './RevenueMetric'
import EnergyMetrics from './EnergyMetric'
import GHGMetrics from './GHGMetric'

interface props {
  loading: boolean
  trxProfileSummary?: TrxSummary
}

const CardComponents = [TransactionMetrics, RevenueMetrics, EnergyMetrics, GHGMetrics]

const MetricsSummary: React.FC<props> = ({ loading, trxProfileSummary }) => {
  return (
    <Section style={{ margin: '0px auto' }}>
      <Row justify="start" gutter={[24, 15]}>
        {CardComponents.map((Card, index) => (
          <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} key={index}>
            <Card key={index} loading={loading} trxProfileSummary={trxProfileSummary} />
          </Col>
        ))}
      </Row>
    </Section>
  )
}

export default MetricsSummary
