import { theme } from '../../theme'

export const networkSVG = (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.80005 15.7V14.1M6.60005 15.7V10.1M11.4 15.7V6.09999M16.2 15.7V1.29999"
      stroke={theme.colors.darkGray}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const GoodNetworkSignalSVG = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.80005 16.2V14.6M6.60005 16.2V10.6M11.4 16.2V6.59999M16.2 16.2V1.79999"
      stroke={theme.colors.greenBase}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const NoNetworkSignalSVG = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.80005 16.2V14.6M6.60005 16.2V10.6M11.4 16.2V6.6M16.2 16.2V1.8"
      stroke={theme.colors.dangerPrimary}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2 2L18 18" stroke="white" strokeWidth="4" strokeLinecap="round" />
    <path d="M2 2L18 18" stroke={theme.colors.dangerPrimary} strokeWidth="1.5" strokeLinecap="round" />
  </svg>
)
