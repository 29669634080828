import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'

import { SwtchError } from 'models/error'
import { IncidencesFilter } from 'models/filter'
import { PaginationMeta } from 'models/pagination'

import { Incidences, UpdateIncidenceParams, defaultStatus } from 'models/incidences'
import { ServiceStatusDescription, ServiceStatusReasons } from 'models/listing'
import { GetServiceStatusDescriptions, GetServiceStatusReasons } from 'services/data-provider/listing'
import { GetTenantIncidences, UpdateTenantIncidences } from 'services/data-provider/tenants'
import { useNotifications } from './useNotification'
import { useIncidencesTranslation } from './translation/useIncidencesTranslation'

export const useTenantIncidences = (tenantId: string) => {
  const [selectedIncidence, setSelectedIncidence] = useState<Incidences>()
  const [startDate, setStartDate] = useState(moment().subtract(6, 'days'))
  const [endDate, setEndDate] = useState(moment().endOf('day'))
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [incidences, setIncidences] = useState<Incidences[]>([])
  const [showSearchQuery, setShowSearchQuery] = useState(false)
  const [pagination, setPagination] = useState<PaginationMeta>({ currentPage: 1, totalEntries: 0, perPage: 20 })

  const [currentReasons, setCurrentReasons] = useState<ServiceStatusReasons[]>([])
  const [serviceDescriptions, setServiceDescriptions] = useState<ServiceStatusDescription[]>([])

  const [initialValues, setInitialValues] = useState({})

  const [filter, setFilter] = useState<IncidencesFilter>({
    page: 1,
    listings: [],
    rootCauseIds: [],
    resolutionIds: [],
    downtime: '',
    previousStatus: defaultStatus.previousStatus,
    newStatus: defaultStatus.newStatus,
  })

  const { openSuccessNotification } = useNotifications()
  const { updateSuccessText } = useIncidencesTranslation()

  useEffect(() => {
    fetchTenantIncidences()
    getReasons()
    getDescriptions()
  }, [])

  useEffect(() => {
    if (!selectedIncidence) {
      setInitialValues({})
      return
    }
    const rootCause = currentReasons.find((reason) => reason.name === selectedIncidence.rootCause)
    const resolution = serviceDescriptions.find((description) => description.name === selectedIncidence.resolution)

    let initialValues = {
      zendesk_id: selectedIncidence.zendeskId,
      root_cause_id: rootCause?.id,
      downtime: selectedIncidence.downtime,
      resolution_id: resolution?.id,
      start_time: selectedIncidence.startTime ? moment(selectedIncidence.startTime) : undefined,
      end_time: selectedIncidence.endTime ? moment(selectedIncidence.endTime) : undefined,
    }
    setInitialValues(initialValues)
  }, [selectedIncidence, currentReasons, serviceDescriptions])

  const getDescriptions = useCallback(() => {
    setLoading(true)
    setError(undefined)
    GetServiceStatusDescriptions()
      .then((data) => {
        setServiceDescriptions(data)
      })
      .catch(setError)
      .finally(() => setLoading(false))
  }, [])

  const getReasons = useCallback(() => {
    setLoading(true)
    setError(undefined)
    GetServiceStatusReasons(defaultStatus)
      .then(({ data }) => {
        setCurrentReasons(data)
      })
      .catch(setError)
      .finally(() => setLoading(false))
  }, [])

  const fetchTenantIncidences = (page = 1) => {
    setLoading(true)
    setError(undefined)

    GetTenantIncidences(tenantId, startDate, endDate, { ...filter, page })
      .then((response) => {
        setIncidences(response.data)
        setPagination(response.pagination)
        setFilter({ ...filter, page })
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  const updateIncidence = useCallback(
    (updatedValues: UpdateIncidenceParams) => {
      if (!selectedIncidence) return

      setLoading(true)
      setError(undefined)
      UpdateTenantIncidences(`${tenantId}`, selectedIncidence.id, updatedValues)
        .then(() => {
          fetchTenantIncidences()
          setSelectedIncidence(undefined)
          openSuccessNotification(updateSuccessText)
        })
        .catch(setError)
        .finally(() => setLoading(false))
    },
    [tenantId, selectedIncidence, fetchTenantIncidences],
  )

  const handleChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) {
      setStartDate(dates[0])
    }
    if (dates[1] !== endDate) {
      setEndDate(dates[1])
    }
  }

  const fetchDataWithQuery = () => {
    setShowSearchQuery(true)
    fetchTenantIncidences()
  }

  const handlePaginationChange = (page: number, pageSize?: number) => {
    fetchTenantIncidences(page)
    return
  }

  return {
    error,
    loading,
    startDate,
    endDate,
    incidences,
    pagination,
    filter,
    showSearchQuery,
    fetchTenantIncidences,
    fetchDataWithQuery,
    handleChangeDate,
    setFilter,
    handlePaginationChange,
    currentReasons,
    serviceDescriptions,
    selectedIncidence,
    setSelectedIncidence,
    initialValues,
    updateIncidence,
  }
}
