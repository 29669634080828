import { useState } from 'react'
import { message } from 'antd'

import { ReusableAutoComplete } from 'components/reusableSelector/autoComplete'
import { SwtchError } from '../../models/error'
import { FindAggregator } from '../../services/data-provider/peak-shaving'
import debounce from 'lodash/debounce'

interface props {
  onSelect?: (value: string) => void
  onClear?: () => void
  onEnter?: () => void
  placeholder?: string
  style?: React.CSSProperties
  disabled?: boolean
  showMagnifySVG?: boolean
}

interface OptionProps {
  id: number
  value: string
  label: string
}

export const PeakShavingAggregatorNameSelector: React.FC<props> = ({
  onSelect,
  onClear,
  onEnter,
  placeholder,
  style,
  disabled,
  showMagnifySVG = true,
  ...defaultProps
}) => {
  const [options, setOptions] = useState<OptionProps[]>([])

  const [loading, setLoading] = useState(false)

  const searchAggregatorName = (inputValue: string) => {
    setLoading(true)
    FindAggregator(inputValue)
      .then((programs) => {
        //Changed to use program data so filtering out the programs that doesn't have aggregatorName
        let uniqueAggregators = new Set()

        // if programs return with data as null, return empty array
        if (programs.data[0] === undefined) {
          setOptions([])
          return
        }

        // filter out the programs that doesn't have aggregatorName
        const filteredAggregators = programs?.data.filter((aggregator) => aggregator.aggregatorName)

        // if there are no programs with aggregatorName, return empty array
        if (!filteredAggregators) {
          setOptions([])
          return
        }

        const options = filteredAggregators
          .map((aggregator) => ({
            id: aggregator.id,
            value: aggregator.aggregatorName,
            label: aggregator.aggregatorName,
          }))
          .filter((val) => {
            if (val.value && !uniqueAggregators.has(val.value)) {
              uniqueAggregators.add(val.value)
              return true
            }
            return false
          })
        setOptions(options)
      })
      .catch((err: SwtchError) => {
        setOptions([])
        message.error(err.description)
      })
      .finally(() => setLoading(false))
  }

  return (
    <ReusableAutoComplete
      {...defaultProps}
      disabled={disabled}
      onSelect={onSelect}
      onClear={onClear}
      onEnter={onEnter}
      loading={loading}
      onSearch={debounce(searchAggregatorName, 800)}
      allowClear
      showMagnifySVG={showMagnifySVG}
      options={options}
      placeholder={placeholder}
      style={style}
      id="aggregatorName"
    />
  )
}
