import { Form, Input, Select } from 'antd'

import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { ServiceStatusDescription, ServiceStatusReasons } from 'models/listing'
import { ServiceStatusSectionHeader } from './service-status-section-header'
import { usePeakShavingTranslation } from 'hooks/translation/usePeakShavingTranslation'

interface props {
  loading: boolean
  currentReasons: ServiceStatusReasons[]
  serviceDescriptions: ServiceStatusDescription[]
}

export const ServiceEventSection: React.FC<props> = ({ loading, currentReasons, serviceDescriptions }) => {
  const {
    serviceEventText,
    excludeText,
    includeText,
    resolutionText,
    rootCauseText,
    zendeskIdText,
    uptimeReportText,
  } = useListingBasicTrans()
  const { isRquiredText } = usePeakShavingTranslation()

  const title = serviceEventText

  const uptimeReportDropdown = [
    {
      key: 'exclude',
      label: excludeText,
    },
    {
      key: 'include',
      label: includeText,
    },
  ]

  return (
    <ServiceStatusSectionHeader title={title}>
      <Form.Item
        name="root_cause_id"
        rules={[{ required: true, message: isRquiredText.replace('-1', rootCauseText) }]}
        label={rootCauseText}
        className="service-status shorter-red"
      >
        <Select showSearch optionFilterProp="children" placeholder={rootCauseText} disabled={loading} loading={loading}>
          {currentReasons.map((option) => (
            <Select.Option value={option.id}>{option.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="resolution_id"
        rules={[{ required: true, message: isRquiredText.replace('-1', resolutionText) }]}
        label={resolutionText}
        className="service-status shorter-red"
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder={resolutionText}
          disabled={loading}
          loading={loading}
        >
          {serviceDescriptions.map((option) => (
            <Select.Option value={option.id}>{option.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="zendesk_id" rules={[{ required: false }]} label={zendeskIdText} className="service-status">
        <Input style={{ height: '38px' }} placeholder={zendeskIdText} />
      </Form.Item>
      <Form.Item name="uptime_report" label={uptimeReportText}>
        <Select placeholder={uptimeReportText} disabled={loading} loading={loading}>
          {uptimeReportDropdown.map((option) => (
            <Select.Option value={option.key}>{option.label}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    </ServiceStatusSectionHeader>
  )
}
