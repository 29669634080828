import { theme } from '../../theme'

export const trashcanSVG = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 4.47059H16M7.25 12.7059V7.76471M10.75 12.7059V7.76471M12.5 16H5.5C4.5335 16 3.75 15.2626 3.75 14.3529V5.29412C3.75 4.83929 4.14175 4.47059 4.625 4.47059H13.375C13.8582 4.47059 14.25 4.83929 14.25 5.29412V14.3529C14.25 15.2626 13.4665 16 12.5 16ZM7.25 4.47059H10.75C11.2332 4.47059 11.625 4.10188 11.625 3.64706V2.82353C11.625 2.36871 11.2332 2 10.75 2H7.25C6.76675 2 6.375 2.36871 6.375 2.82353V3.64706C6.375 4.10188 6.76675 4.47059 7.25 4.47059Z"
      stroke={theme.colors.darkGray}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
