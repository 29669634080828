import { AutoComplete, Input, Spin } from 'antd'
import { searchSVG } from 'assets/svg/search'
import { ReusableAutoCompleteContainer } from 'atom/select'
import { debounce } from 'lodash'

interface props {
  loading?: boolean
  options: {
    id: number
    value: string
    label: string
  }[]
  placeholder?: string
  showMagnifySVG?: boolean
  allowClear?: boolean
  onSearch: (value: string) => void
  onSelect?: (value: string) => void
  onClear?: () => void
  onEnter?: () => void
  style?: React.CSSProperties
  id?: string
  disabled?: boolean
}

//Initialization - need to modify going forward
export const ReusableAutoComplete: React.FC<props> = ({
  loading,
  options,
  placeholder,
  showMagnifySVG,
  allowClear,
  onSearch,
  onSelect,
  onClear,
  onEnter,
  style,
  id,
  disabled,
  ...defaultAutoCompleteProps
}) => {
  return (
    <ReusableAutoCompleteContainer id={'aggregatorName-wrapper'}>
      <AutoComplete
        {...defaultAutoCompleteProps}
        id={id}
        allowClear={allowClear}
        dropdownMatchSelectWidth={false}
        options={options}
        onSearch={debounce(onSearch, 800)}
        notFoundContent={loading ? <Spin size="small" /> : null}
        onSelect={onSelect}
        onClear={onClear}
        disabled={disabled}
        onKeyDown={(e) => {
          if (e.code === 'Enter' && onEnter) onEnter()
        }}
        style={style}
      >
        <Input prefix={showMagnifySVG ? searchSVG : undefined} placeholder={placeholder} />
      </AutoComplete>
    </ReusableAutoCompleteContainer>
  )
}
