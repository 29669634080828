import { Modal, Skeleton, Tabs } from 'antd'
import { NewListing } from '../../../models/listing'
import { useListingBasicTrans } from '../../../hooks/translation/useListingBasicTrans'
import { Heading } from '../../../atom/user-edit'
import { UpdateListingServiceStatus } from '../../../components/listings/update-listing-service-status'
import { ListingPricingSchema } from '../../../components/listings/listing-pricing-schema'
import { TabsWrapper } from '../../../atom/chargers'
import { useEffect, useState } from 'react'
import { UpdateListingCategory } from 'components/listings/update-listing-category'
import { UpdateListingAccess } from 'components/listings/update-listing-access'
import { UpdateListingSettlement } from 'components/listings/update-listing-settlement'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { useUserTranslation } from 'hooks/translation/useUserTranslation'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { useAppState } from 'state'
import { UpdateListingModalWrapper } from 'atom/listings'
import { useTaggingTranslation } from 'hooks/translation/useTaggingTranslation'
import { UpdateListingTagging } from 'components/listings/update-listing-tagging'
import { log } from 'logger'

interface props {
  loading: boolean
  selectedListings: NewListing[]
  onOk: () => void
  onCancel: () => void
}

export const ListingModel: React.FC<props> = ({ loading, selectedListings, onOk, onCancel }) => {
  const [numberOfTenants, setNumberOfTenants] = useState(1)
  const {
    updateListingText,
    serviceStatusText,
    listingCategoryText,
    settlementText,
    fromText,
    updateText,
  } = useListingBasicTrans()

  const { taggingText } = useTaggingTranslation()
  const { accessText } = useTenantTranslation()
  const { tenantsTxt } = useUserTranslation()
  const { listingsText, pricingPlanText } = useGeneralTranslation()
  const { language } = useAppState()

  useEffect(() => {
    const uniqueTenants: { [key: number]: boolean } = {}

    selectedListings.forEach((listing) => {
      if (listing.tenant?.id) {
        uniqueTenants[Number(listing.tenant.id)] = true
      }
    })

    const numberOfUniqueTenants = Object.keys(uniqueTenants).length
    setNumberOfTenants(numberOfUniqueTenants)
  }, [])

  const modalTitle =
    selectedListings.length === 1
      ? `${updateListingText}`
      : `${updateText} ${selectedListings.length} ${listingsText}` +
        (numberOfTenants > 1 ? ` ${fromText} ${numberOfTenants} ${tenantsTxt}` : '')

  log('selected listings', selectedListings)
  log(
    'selected listings service status:',
    selectedListings.map((listing) => listing.title),
    selectedListings.map((listing) => listing?.connector?.serviceStatus),
  )
  return (
    <Modal
      title={<Heading>{modalTitle}</Heading>}
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
      footer={null}
      width={language === 'fr' ? 800 : 700}
      bodyStyle={{ padding: '10px 24px 24px 24px' }}
      confirmLoading={loading}
    >
      <UpdateListingModalWrapper language={language}>
        <TabsWrapper>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab={serviceStatusText} key="listingStatus">
              <Skeleton loading={loading}>
                {selectedListings && <UpdateListingServiceStatus listings={selectedListings} onOk={onOk} />}
              </Skeleton>
            </Tabs.TabPane>
            <Tabs.TabPane tab={pricingPlanText} key="pricingSchema">
              <Skeleton loading={loading}>
                {selectedListings && <ListingPricingSchema listings={selectedListings} onOk={onOk} />}
              </Skeleton>
            </Tabs.TabPane>
            <Tabs.TabPane tab={listingCategoryText} key="listingCategory">
              <Skeleton loading={loading}>
                {selectedListings && <UpdateListingCategory listings={selectedListings} onOk={onOk} />}
              </Skeleton>
            </Tabs.TabPane>
            <Tabs.TabPane tab={accessText} key="access">
              <Skeleton loading={loading}>
                {selectedListings && <UpdateListingAccess listings={selectedListings} onOk={onOk} />}
              </Skeleton>
            </Tabs.TabPane>
            <Tabs.TabPane tab={settlementText} key="settlement">
              <Skeleton loading={loading}>
                {selectedListings && <UpdateListingSettlement listings={selectedListings} onOk={onOk} />}
              </Skeleton>
            </Tabs.TabPane>
            <Tabs.TabPane tab={taggingText} key="tagging">
              <Skeleton loading={loading}>
                {selectedListings && <UpdateListingTagging listings={selectedListings} onOk={onOk} />}
              </Skeleton>
            </Tabs.TabPane>
          </Tabs>
        </TabsWrapper>
      </UpdateListingModalWrapper>
    </Modal>
  )
}
