import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBolt,
  faExclamationTriangle,
  faStopCircle,
  faWifi,
} from '@fortawesome/free-solid-svg-icons'

import { serviceStatus } from '../models/charger'
import { connectorStatus } from '../models/connector'
import { theme } from '../theme'

import {
  BatteryEmptyWrapper,
  BatteryFullWrapper,
  BatteryHalfWrapper,
  ChargingIconWrapper,
  LightingBoltWrapper,
} from '../atom/battery-animation'

export const renderConnectionMessage = (connectorStatus: string): string => {
  let status = ''
  switch (connectorStatus) {
    case 'Available':
    case 'available':
      status = 'dashboard.chargerPage.chargerStatus.available'
      break
    case 'Charging':
    case 'charging':
      status = 'dashboard.chargerPage.chargerStatus.charging'
      break
    case 'Preparing':
    case 'preparing':
      status = 'dashboard.chargerPage.chargerStatus.preparing'
      break
    case 'Finishing':
    case 'finishing':
      status = 'dashboard.chargerPage.chargerStatus.finishing'
      break
    case 'SuspendedEV':
    case 'suspendedEV':
      status = 'dashboard.chargerPage.chargerStatus.suspendedEV'
      break
    case 'SuspendedEVSE':
    case 'suspendedEVSE':
      status = 'dashboard.chargerPage.chargerStatus.suspendedEVSE'
      break
    case 'Faulted':
    case 'faulted':
      status = 'dashboard.chargerPage.chargerStatus.faulted'
      break
    case 'Unavailable':
    case 'unavailable':
      status = 'dashboard.chargerPage.chargerStatus.unavailable'
      break
    case 'in_maintenance':
    case 'Commissioning':
    case 'commissioning':
      status = 'dashboard.chargerPage.chargerStatus.commissioning'
      break
    case 'Offline':
    case 'offline':
      status = 'dashboard.chargerPage.chargerStatus.offline'
      break
    case 'Reserved':
    case 'reserved':
      status = 'dashboard.chargerPage.chargerStatus.reserved'
      break
    case 'service_mode':
    case 'Active':
    case 'active':
      status = 'dashboard.charger.serviceStatus.active'
      break
    case 'under_repair':
    case 'Under Repair':
      status = 'dashboard.charger.serviceStatus.underRepair'
      break
    case 'activating':
    case 'Activating':
      status = 'dashboard.charger.serviceStatus.activating'
      break
    case 'Online':
    case 'online':
      status = 'dashboard.text.online'
      break
    case 'On Hold':
    case 'on_hold':
      status = 'dashboard.serviceStatus.onHold'
      break
    case 'commissioned':
    case 'Commissioned':
      status = 'dashboard.serviceStatus.commissioned'
      break
    case 'Awaiting Commissioning':
    case 'awaiting_commissioning':
      status = 'dashboard.serviceStatus.awaitingCommissioning'
      break
    case 'Decommissioned':
    case 'decommissioned':
      status = 'dashboard.serviceStatus.decommissioned'
      break
    case 'Testing':
    case 'testing':
      status = 'dashboard.serviceStatus.testing'
      break
    case 'Activating - Pending Property':
    case 'property_activating':
    case 'activating_pending_property':
      status = 'dashboard.serviceStatus.activatingPendingProperty'
      break
    case 'Activating - Pending Driver Subscription':
    case 'driver_activating':
    case 'activating_pending_driver_subscription':
      status = 'dashboard.serviceStatus.activatingPendingDriverSub'
      break
    case 'Other':
    case 'other':
      status = 'dashboard.serviceStatus.other'
      break
    case 'Troubleshooting':
    case 'troubleshooting':
      status = 'dashboard.serviceStatus.troubleshooting'
      break
    case 'Terminated':
    case 'terminated':
      status = 'dashboard.serviceStatus.terminated'
      break
    case 'Unknown':
    case 'unknown':
      status = 'dashboard.text.unknown'
      break
    case 'Inactive':
    case 'inactive':
      status = 'dashboard.text.inactive'
      break
    case 'suspended':
      status = 'dashboard.text.suspended'
      break
    case 'draft':
      status = 'dashboard.text.draft'
      break
    case 'deleted':
      status = 'dashboard.text.deleted'
      break
    case 'pending_sa':
      status = 'dashboard.text.pending_sa'
      break
    case 'configuration':
      status = 'dashboard.text.configuration'
      break
    case 'direct_deposit_information':
    case 'directDepositInformation':
      status = 'dashboard.text.directDepositInformation'
      break
    case 'onboardingComplete':
    case 'onboarding_complete':
      status = 'dashboard.text.onboardingComplete'
      break
    case 'Tenant':
      status = 'dashboard.text.tenant'
      break
    case 'Listing':
      status = 'dashboard.text.listing'
      break
    case 'Users':
      status = 'dashboard.text.users'
      break
    case 'Pricing Schema':
      status = 'dashboard.text.pricingSchema'
      break
    case 'Account':
      status = 'dashboard.text.account'
      break
  }
  return status
}

export const allStatus = [...connectorStatus, ...serviceStatus] as const
export type AllStatus = typeof allStatus[number]

export const statusIcon = (state: AllStatus | undefined) => {
  let classStr = theme.colors.gray
  let icon = faStopCircle
  switch (state) {
    case 'Available':
      classStr = theme.colors.neonGreen
      icon = faWifi
      break
    case 'Charging':
      return (
        <ChargingIconWrapper>
          <BatteryEmptyWrapper>
            <FontAwesomeIcon color={theme.colors.blue} icon={faBatteryEmpty} />
          </BatteryEmptyWrapper>
          <BatteryHalfWrapper>
            <FontAwesomeIcon color={theme.colors.blue} icon={faBatteryHalf} />
          </BatteryHalfWrapper>
          <BatteryFullWrapper>
            <FontAwesomeIcon color={theme.colors.blue} icon={faBatteryFull} />
          </BatteryFullWrapper>
          <LightingBoltWrapper>
            <FontAwesomeIcon color={theme.colors.yellow} icon={faBolt} />
          </LightingBoltWrapper>
        </ChargingIconWrapper>
      )
    case 'Unavailable':
    case 'Faulted':
    case 'under_repair':
    case 'Offline':
      classStr = `${theme.colors.red}`
      icon = faExclamationTriangle
      break
    case 'Preparing':
    case 'SuspendedEV':
    case 'SuspendedEVSE':
    case 'Finishing':
    case 'activating':
      classStr = theme.colors.orange
      break
    case 'in_maintenance':
      classStr = theme.colors.gray
      break
    default:
      classStr = theme.colors.gray
  }
  return <FontAwesomeIcon color={classStr} icon={icon} />
}

export const statusColor = (state: AllStatus | undefined) => {
  switch (state) {
    case 'Unavailable':
    case 'Faulted':
    case 'under_repair':
    case 'Offline':
      return theme.colors.red
    case 'Charging':
      return theme.colors.blue
    case 'Available':
    case 'service_mode':
      return theme.colors.neonGreen
    case 'Preparing':
    case 'SuspendedEV':
    case 'SuspendedEVSE':
    case 'Finishing':
    case 'activating':
      return theme.colors.orange
    case 'in_maintenance':
      return theme.colors.gray
    default:
      return theme.colors.gray
  }
}

export const renderPeakShavingStatus = (status: string): string => {
  switch (status.toLocaleLowerCase()) {
    case 'draft':
      return 'dashboard.peakShaving.programsPage.status.draft'
    case 'published':
      return 'dashboard.peakShaving.programsPage.status.published'
    case 'archived':
      return 'dashboard.peakShaving.programsPage.status.archived'
    case 'cancelled':
      return 'dashboard.text.cancelled'
    case 'finished':
      return 'dashboard.peakShaving.programsPage.statis.finished'
    case 'completed':
      return 'dashboard.transactionsPage.advancedSearch.state.completed'
    case 'in_progress':
      return 'dashboard.tenantPage.overviewTab.status.inprogress'
    default:
      return 'dashboard.peakShavingProgram.status.unknown'
  }
}
