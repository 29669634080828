import { Button, Col, Row, Space } from 'antd'
import { useAppState } from 'state'

import { ReusableSelector } from 'components/reusableSelector/selector'

import { useSelector } from 'hooks/useSelector'

import { PeakShavingProgramFilter } from 'models/filter'

import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'

import { magnifyBlackSVG } from 'assets/svg/magnify'
import { ButtonIcon } from 'atom/button'
import { LineBreak } from 'atom/line-break'
import { usePeakShavingTranslation } from 'hooks/translation/usePeakShavingTranslation'
import { PeakShavingProgram } from 'models/peak-shaving'
import { FindAggregator, GetAllPeakShavingPrograms } from 'services/data-provider/peak-shaving'

interface props {
  loading: boolean
  filter: PeakShavingProgramFilter
  dropdownClassName?: string
  onFilter: (filters: PeakShavingProgramFilter) => void
  onFetch: () => void
}

const ProgramSelectors: React.FC<props> = ({ loading, filter, dropdownClassName, onFilter, onFetch }) => {
  const { IsDesktop, IsLaptop } = useAppState()

  const { searchText } = useGeneralTranslation()
  const {
    generalDebounceFetcher,

    handleSingleSelectChange,
    renderSingleOption,

    renderMultiOptions,
    multiHandlePlaceholder,
    multiHandleChange,
  } = useSelector()

  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()
  const { aggregatorNameText, programTabProgramSelectorText } = usePeakShavingTranslation()

  const renderAggregatorSelector = (
    <ReusableSelector
      showSearch
      onClear={() => onFilter({ ...filter, aggregatorName: undefined })}
      isSingle
      showArrow={false}
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={true}
      debounceFetcher={generalDebounceFetcher((value) => FindAggregator(value), true, 'data')}
      dropdownClassName={dropdownClassName}
      placeholder={aggregatorNameText}
      combineType="right"
      onOptionsChange={(selectedValue) => onFilter({ ...filter, aggregatorName: selectedValue?.aggregatorName })}
      handleOptionChange={handleSingleSelectChange('aggregatorName')}
      renderOption={renderSingleOption(
        {
          val: 'aggregatorName',
          label: 'aggregatorName',
        },
        false,
      )}
      customWidth="200px"
      stopClearSearchValue={true}
    />
  )

  const renderProgramsSelector = (
    <ReusableSelector
      showSearch
      nativeSearch
      onClear={() => onFilter({ ...filter, programs: undefined })}
      isSingle={false}
      clearSearchAfterSelect
      placeholder={programTabProgramSelectorText}
      dropDownList={[]}
      showMagnifySVG={false}
      fieldForValue="id"
      maxTagCount={0}
      isDebounceFetcher={true}
      handlePlaceholder={multiHandlePlaceholder('name', programTabProgramSelectorText)}
      debounceFetcher={generalDebounceFetcher(
        (programName) =>
          GetAllPeakShavingPrograms({
            programName,
          }),
        true,
        'data',
      )}
      dropdownClassName={dropdownClassName}
      combineType="left"
      handleOptionChange={multiHandleChange('id')}
      renderOption={renderMultiOptions({
        val: 'id',
        label: 'name',
      })}
      onOptionsChange={(programs: PeakShavingProgram[]) => {
        onFilter({ ...filter, programs: programs.map(({ id }) => id) })
      }}
      searchButtonMerged
      customWidth="200px"
      placeholderWidth="120px"
    />
  )

  return (
    <>
      {isDesktop || isLaptop ? (
        <Space wrap size={[0, 6]} style={{ display: 'flex' }}>
          {renderAggregatorSelector}
          <LineBreak />
          {renderProgramsSelector}
          <ButtonIcon icon={magnifyBlackSVG} loading={loading} disabled={loading} fetchData={onFetch} withinSearchBar />
        </Space>
      ) : (
        <Row gutter={[8, 8]}>
          <Col xs={24} md={12}>
            {renderAggregatorSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderProgramsSelector}
          </Col>
          <Col span={24}>
            <Button block type="default" disabled={loading} loading={loading} onClick={onFetch}>
              {searchText}
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ProgramSelectors
