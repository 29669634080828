import {
  AutoComplete,
  Form,
  FormInstance,
  message,
  Radio,
  Select,
  SelectProps,
  Space,
  Spin,
  Button,
  Input,
  Divider,
  Switch,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { debounce } from 'lodash'
import { useState } from 'react'

import { useListingBasicTrans } from '../../hooks/translation/useListingBasicTrans'
import { SwtchError } from '../../models/error'
import { fundingSource, ListingRef } from '../../models/listing'
import { FindListings } from '../../services/data-provider/listing'
import { AlertError } from '../error'
import { MustBeRequired, MustMaxOut } from '../rules/rules'
import { EditButtonRowWrapper, EditButtonWrapper } from 'atom/tenants'
import { useAppState } from 'state'
import { editGreySVG } from 'assets/svg/edit'
import { MultiSelectWrapper } from 'atom/chargers'
import { theme } from 'theme'
import { TextAreaWrapper } from 'atom/input'
import { useFormatMessage } from 'helpers/intl'
import { renderConnectionMessage } from 'helpers/status'

interface props {
  form: FormInstance<any>
  onEditListingStatusModal?: (value: boolean) => void
  serviceStatus: string
}

interface ListingOption {
  value: string
  label: JSX.Element
  listing: ListingRef
}

const renderListing = (listing: ListingRef): ListingOption => {
  return {
    value: listing.title,
    listing,
    label: <div>{listing.title}</div>,
  }
}

export const ListingBasic: React.FC<props> = ({ form, onEditListingStatusModal, serviceStatus }) => {
  const { IsTablet, IsMobile } = useAppState()
  const isTablet = IsTablet()
  const isMobile = IsMobile()

  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)

  const [kind, setKind] = useState(form.getFieldValue('kind'))
  const [wallet, setWallet] = useState(form.getFieldValue('paymentType'))
  const [options, setOptions] = useState<SelectProps<object>['options']>([])
  const [isTrailerAccessible, setIsTrailerAccessible] = useState(form.getFieldValue('trailerAccessible') || false)

  const {
    characterMaxLimitText,
    listingAlreadyText,
    listingDescriptionText,
    listingInfoText,
    listingTitleRequiredText,
    listingTitleText,
    listingCategoryText,
    listingNonResText,
    listingResText,
    listingDestText,
    listingNonNetworkText,
    listingWalletFuncText,
    listingWalletText,
    listingNoWalletText,
    listingFundingSourcesText,
    fundingSourcesPlaceholderText,
    editStatusButtonText,
    serviceStatusText,
    listingText,
    trailerAccessibleText,
  } = useListingBasicTrans()

  const checkListingTitleAlreadyPresent = (term: string) => {
    setLoading(true)
    setError(undefined)
    FindListings(term)
      .then((listings) => {
        const opts = listings.map((listing) => renderListing(listing))
        setOptions(opts)
      })
      .catch((err: SwtchError) => {
        setOptions([])
        setError(err)
        message.error(err.description)
      })
      .finally(() => setLoading(false))
  }

  const handleSearch = (value: string) => {
    value && value.length > 0 && checkListingTitleAlreadyPresent(value)
  }

  const debouncedTitleSearch = debounce(handleSearch, 1000)
  return (
    <>
      <AlertError error={error} />
      <Divider>{listingText}</Divider>
      <Form.Item
        label={listingTitleText}
        name="title"
        rules={[
          MustBeRequired(listingTitleRequiredText),
          () => ({
            validator(_, value: string) {
              if (options?.map((opt) => String(opt.value).toLowerCase()).includes(String(value).toLowerCase())) {
                return Promise.reject(new Error(listingAlreadyText))
              }
              return Promise.resolve()
            },
          }),
        ]}
      >
        <AutoComplete
          options={options}
          onSearch={debouncedTitleSearch}
          placeholder={listingTitleText}
          filterOption
          allowClear
          notFoundContent={loading ? <Spin size="small" /> : null}
        />
      </Form.Item>
      <TextAreaWrapper>
        <Form.Item label={listingInfoText} name="description" rules={[MustMaxOut(characterMaxLimitText)]}>
          <TextArea maxLength={255} showCount placeholder={listingDescriptionText} style={{ height: theme.space[6] }} />
        </Form.Item>
      </TextAreaWrapper>
      <EditButtonRowWrapper isTablet={isTablet} isMobile={isMobile} spaceBetween="-18">
        <Form.Item name="serviceStatus" label={serviceStatusText}>
          <Input
            defaultValue={serviceStatus}
            disabled={true}
            value={useFormatMessage(
              renderConnectionMessage(serviceStatus ? serviceStatus : 'awaiting_commissioning'),
              serviceStatus ? serviceStatus : 'awaiting_commissioning',
            )}
            placeholder="Select service status"
            style={{ height: '38px' }}
          />
          {onEditListingStatusModal ? (
            isMobile || isTablet ? (
              <EditButtonWrapper>
                <Button type="primary" icon={editGreySVG} onClick={() => onEditListingStatusModal(true)} />
              </EditButtonWrapper>
            ) : (
              <Button
                type="primary"
                onClick={() => onEditListingStatusModal(true)}
                style={{ justifyContent: 'flex-start', alignContent: 'center' }}
                size="middle"
              >
                {editStatusButtonText}
              </Button>
            )
          ) : (
            <></>
          )}
        </Form.Item>
      </EditButtonRowWrapper>

      <Form.Item label={listingCategoryText} name="kind">
        <Radio.Group value={kind} onChange={(e) => setKind(e.target.value)} buttonStyle="solid">
          {form.getFieldValue('priceType') === 'kWh' ? (
            <Radio.Button value="no_reservation" className="radio-button">
              {listingNonResText}
            </Radio.Button>
          ) : (
            <Space wrap>
              <Radio.Button value="reservation" className="radio-button">
                {listingResText}
              </Radio.Button>
              <Radio.Button value="no_reservation" className="radio-button">
                {listingNonResText}
              </Radio.Button>
              <Radio.Button value="destination" className="radio-button">
                {listingDestText}
              </Radio.Button>
              <Radio.Button value="non_network" className="radio-button">
                {listingNonNetworkText}
              </Radio.Button>
            </Space>
          )}
        </Radio.Group>
      </Form.Item>
      <Form.Item label={listingWalletFuncText} name="paymentType" required>
        <Radio.Group value={wallet} onChange={(e) => setWallet(e.target.value)} buttonStyle="solid">
          <Space>
            <Radio.Button value="wallet" className="radio-button">
              {listingWalletText}
            </Radio.Button>
            <Radio.Button value="no_wallet" className="radio-button">
              {listingNoWalletText}
            </Radio.Button>
          </Space>
        </Radio.Group>
      </Form.Item>
      <MultiSelectWrapper>
        <Form.Item label={listingFundingSourcesText} name="fundingSources">
          <Select mode="multiple" allowClear showArrow placeholder={fundingSourcesPlaceholderText}>
            {fundingSource.map((source) => (
              <Select.Option key={source} value={source}>
                {source.toUpperCase()}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={trailerAccessibleText} name="trailerAccessible" valuePropName="checked" required>
          <Switch checked={isTrailerAccessible} onChange={setIsTrailerAccessible} />
        </Form.Item>
      </MultiSelectWrapper>
    </>
  )
}
