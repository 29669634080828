import { Button, Space } from 'antd'

import { editBlackSVG } from 'assets/svg/edit'
import { SelectionInfoContainer } from 'atom/box'
import { ButtonModal } from 'atom/button'

import { NewListing } from 'models/listing'
import { renderFormatMessage } from 'helpers/intl'
import { capitalizeFirstLetter } from 'helpers/users'

interface props {
  text: string
  loading: boolean
  selectAllData?: () => void
  totalEntries: number
  areAllChargersSelected: boolean
  selectedListings: NewListing[]
  onShowModal: (modalType: 'listing' | 'charger', value: boolean) => void
  resetSelectionAndCloseEditOptions: () => void
}

const ChargerEditOption: React.FC<props> = ({
  text,
  loading,
  selectAllData,
  totalEntries,
  areAllChargersSelected,
  selectedListings,
  onShowModal,
  resetSelectionAndCloseEditOptions,
}) => {
  // Check if there is at least one charger in the selected listings
  const hasChargers = selectedListings.some((listing) => listing.charger !== undefined)

  const hasListings = selectedListings.some((listing) => listing.id !== undefined)

  const count = selectedListings.length

  const isCapitalizedAndPlural = (text: string) => {
    return capitalizeFirstLetter(text) + (totalEntries >= 2 ? 's' : '')
  }

  const selectAllText = renderFormatMessage(
    `dashboard.text.selectAll${isCapitalizedAndPlural(text)}`,
    `Select all ${totalEntries} ${text}`,
    { totalEntries },
  )

  const renderChargerOrChargers = (count: number) => {
    return (
      <Space>
        <span>
          {areAllChargersSelected && selectAllData && `All ${totalEntries} chargers selected`}
          {areAllChargersSelected &&
            !selectAllData &&
            renderFormatMessage('dashboard.text.allChargersSelected', 'All {count} chargers on this page selected', {
              count,
            })}
          {!areAllChargersSelected &&
            renderFormatMessage(
              'dashboard.text.someChargersSelected',
              '{count} {chargerCount, plural, one {charger} other {chargers}} on this page selected',
              {
                count,
                chargerCount: count,
              },
            )}
        </span>
        <span>
          {selectedListings && selectAllData && selectedListings.length !== totalEntries && (
            <button className="paragraph-01-regular link" onClick={selectAllData}>
              {selectAllText}
            </button>
          )}
        </span>
      </Space>
    )
  }
  return (
    <SelectionInfoContainer align="middle" justify="space-between" style={{ display: loading ? 'none' : 'flex' }}>
      <Space>
        <span className="paragraph-01-regular">{renderChargerOrChargers(count)}</span>
      </Space>
      <Space>
        <ButtonModal
          icon={editBlackSVG}
          type="primary"
          text={renderFormatMessage('dashboard.text.editListing', 'Edit Listing')}
          click={() => onShowModal('listing', true)}
          disabled={!hasListings}
        />
        <ButtonModal
          icon={editBlackSVG}
          type="primary"
          text={renderFormatMessage('dashboard.loadManagementTab.editCharger', 'Edit Charger')}
          click={() => onShowModal('charger', true)}
          disabled={!hasChargers}
        />
        <Button type="ghost" onClick={resetSelectionAndCloseEditOptions}>
          {renderFormatMessage('dashboard.text.cancel', 'Cancel')}
        </Button>
      </Space>
    </SelectionInfoContainer>
  )
}

export default ChargerEditOption
