import { Table } from 'antd'
import { useState } from 'react'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

import { PaginationMeta } from 'models/pagination'
import { ColumnGroupType, ColumnType } from 'antd/lib/table'

interface TenantIncidencesProps {
  loading: boolean
  title?: string
  dataSource: any[]
  pagination?: PaginationMeta
  onPaginationChange: (page: number) => void
  columns: (ColumnGroupType<any> | ColumnType<any>)[]
  rowHeightDynamic?: boolean
}

const TabTable: React.FC<TenantIncidencesProps> = ({
  loading,
  title,
  dataSource,
  pagination,
  onPaginationChange,
  columns,
  rowHeightDynamic = false,
}) => {
  const [expandableRowKeys, setExpandableRowKeys] = useState<{ [dataKey: number]: boolean }>({})

  const onExpand = (expanded: boolean, record: any) => {
    setExpandableRowKeys({ ...expandableRowKeys, [record.id]: expanded })
  }

  const rowExpandable = (record: any) => {
    if (Object.keys(expandableRowKeys).includes(`${record.id}`)) return true

    const rowElement = document.querySelector(`[data-row-key="${record.id}"]`)
    const currentRowHeight = (rowElement as HTMLElement)?.offsetHeight
    const childElements = rowElement?.querySelectorAll('.custom-row-collapse-tenant-activity-tracking > td > *')

    let expandable = false

    childElements?.forEach((el: Element) => {
      expandable = expandable || el.scrollHeight > currentRowHeight || (el.scrollHeight >= 63 && el.scrollHeight < 73)
    })

    return expandable
  }

  return (
    <>
      {title && (
        <h6 className={'heading-06-bold'} style={{ opacity: 1, margin: '15px 0px' }}>
          {title}
        </h6>
      )}
      <Table
        sticky
        loading={loading}
        dataSource={dataSource}
        rowKey={(record) => record.id}
        columns={columns}
        className="demand-management-table"
        expandable={{
          onExpand,
          expandedRowRender: () => <></>,
          rowExpandable,
          expandIcon: ({ expandable, expanded, record, onExpand }) =>
            expandable ? (
              expanded ? (
                <MinusCircleOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <PlusCircleOutlined onClick={(e) => onExpand(record, e)} />
              )
            ) : undefined,
        }}
        pagination={
          pagination
            ? {
                hideOnSinglePage: true,
                position: ['bottomCenter'],
                total: pagination?.totalEntries,
                current: pagination?.currentPage,
                pageSize: pagination?.perPage,
                showSizeChanger: false,
                onChange: onPaginationChange,
              }
            : false
        }
        rowClassName={(record) =>
          expandableRowKeys[record.id] || rowHeightDynamic ? '' : 'custom-row-collapse-tenant-activity-tracking'
        }
      />
    </>
  )
}

export default TabTable
