export const pinSVG = (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.69333 15.4005L7.74 15.4272L7.75867 15.4378C7.83269 15.4779 7.91552 15.4988 7.99967 15.4988C8.08382 15.4988 8.16664 15.4779 8.24067 15.4378L8.25933 15.4278L8.30667 15.4005C8.56741 15.2459 8.82184 15.0809 9.06933 14.9058C9.71005 14.4535 10.3087 13.9443 10.858 13.3845C12.154 12.0578 13.5 10.0645 13.5 7.49985C13.5 6.04116 12.9205 4.64221 11.8891 3.61076C10.8576 2.57931 9.45869 1.99985 8 1.99985C6.54131 1.99985 5.14236 2.57931 4.11091 3.61076C3.07946 4.64221 2.5 6.04116 2.5 7.49985C2.5 10.0638 3.84667 12.0578 5.142 13.3845C5.6911 13.9443 6.28952 14.4535 6.93 14.9058C7.17771 15.0809 7.43236 15.2459 7.69333 15.4005ZM8 9.49985C8.53043 9.49985 9.03914 9.28913 9.41421 8.91406C9.78929 8.53899 10 8.03028 10 7.49985C10 6.96941 9.78929 6.46071 9.41421 6.08563C9.03914 5.71056 8.53043 5.49985 8 5.49985C7.46957 5.49985 6.96086 5.71056 6.58579 6.08563C6.21071 6.46071 6 6.96941 6 7.49985C6 8.03028 6.21071 8.53899 6.58579 8.91406C6.96086 9.28913 7.46957 9.49985 8 9.49985Z"
        fill="#242E2C"
      />
    </g>
  </svg>
)
