import { theme } from '../../theme'

export const ConnectorAvailabilityIcon: React.FC = () => {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4872_444)">
        <path
          d="M4.41872 29.9913C3.59213 30.039 2.77051 29.8346 2.06275 29.4049C1.35499 28.9752 0.794468 28.3407 0.455441 27.5853C0.0863518 26.8888 -0.0666307 26.0981 0.0159663 25.3142C0.0985634 24.5303 0.413014 23.7889 0.919159 23.1846C1.58291 22.4618 2.29938 21.7892 3.06265 21.1724L4.37499 22.7296L2.98389 23.9108C2.58304 24.2006 2.28716 24.6128 2.14077 25.0853C1.99438 25.5579 2.00542 26.0651 2.17217 26.5309C2.33892 26.9966 2.65239 27.3956 3.06543 27.6678C3.47847 27.94 3.96872 28.0707 4.46245 28.0402L4.41872 29.9913Z"
          fill={theme.colors.primary}
        />
        <path
          d="M25.6426 11.5135C25.6426 12.7121 25.6426 13.9108 25.6426 15.1094C25.6426 16.1067 25.2051 16.4917 24.199 16.5441C23.1929 16.5966 22.1693 16.5442 21.7231 15.3368C21.6706 15.2056 21.3644 15.1094 21.1806 15.1006C20.3057 15.1006 19.4309 15.1006 18.556 15.1006C18.2411 15.1363 17.9402 15.2506 17.6811 15.4331C15.0564 17.1829 12.4317 18.9326 9.85955 20.6824C8.98466 21.2948 8.55599 21.2336 7.86482 20.4024C7.17366 19.5713 6.54374 18.8276 5.89632 18.0315C5.77171 17.9266 5.67342 17.7941 5.60935 17.6444C5.54529 17.4947 5.51723 17.332 5.52744 17.1695C5.53765 17.007 5.58585 16.8491 5.66815 16.7086C5.75044 16.5681 5.86455 16.4489 6.0013 16.3605C8.67847 13.7358 11.3557 11.1111 14.0678 8.53893C15.4647 7.20158 17.3309 6.46636 19.2646 6.49166C20.9094 6.49166 22.5455 6.49166 24.1903 6.49166C25.2401 6.49166 25.6426 6.90285 25.6514 7.96147L25.6426 11.5135Z"
          fill={theme.colors.primary}
        />
        <path
          d="M26.4922 15.0831V7.3316C27.5508 7.3316 28.5657 7.3316 29.5806 7.3316C29.8384 7.36029 30.0747 7.48918 30.2383 7.6905C30.402 7.89182 30.48 8.14941 30.4555 8.4077C30.4555 10.245 30.4555 12.0822 30.4555 13.9195C30.4675 14.0627 30.4498 14.2068 30.4035 14.3428C30.3572 14.4788 30.2832 14.6037 30.1862 14.7097C30.0893 14.8157 29.9714 14.9005 29.84 14.9588C29.7087 15.017 29.5668 15.0474 29.4231 15.0481C28.487 15.1269 27.5071 15.0831 26.4922 15.0831Z"
          fill={theme.colors.primary}
        />
        <path
          d="M5.06576 18.189L7.69044 21.3911C7.08844 21.7477 6.46622 22.0691 5.82692 22.3535C5.58195 22.4497 5.22324 22.2572 4.95202 22.1872C4.95202 22.1872 4.8908 22.1172 4.8558 22.0822C3.07977 19.8338 2.90478 20.5599 5.06576 18.189Z"
          fill={theme.colors.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_4872_444">
          <rect width="30.4987" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
