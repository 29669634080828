import { Button, Col, Row, Space } from 'antd'
import { useAppState } from 'state'

import { CustomDateRangePicker } from 'components/redesign/datepicker'
import { useCalendarTransactions } from 'hooks/translation/useCalendarTranslation'
import { Moment } from 'moment'

interface props {
  startDate: Moment
  endDate: Moment
  onChangeDate: (dates: Moment[]) => void
  loading: boolean
  fetchChargers: () => void
}

const ChargerHeader: React.FC<props> = ({ loading, startDate, endDate, onChangeDate, fetchChargers }) => {
  const { IsDesktop, IsLaptop } = useAppState()

  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const { calendarRanges } = useCalendarTransactions()

  const calendar = (
    <CustomDateRangePicker
      startDate={startDate}
      endDate={endDate}
      onChangeDate={onChangeDate}
      calendarRanges={calendarRanges}
      disabled={loading}
    />
  )

  return (
    <>
      {isDesktop || isLaptop ? (
        <Space wrap size={[0, 6]} style={{ display: 'flex' }}>
          {calendar}
          <Button type="primary" onClick={() => fetchChargers()} style={{ marginLeft: '1rem' }}>
            Search
          </Button>
        </Space>
      ) : (
        <Row gutter={[8, 8]}>
          <Col xs={24} md={16} lg={12}>
            {calendar}
          </Col>
          <Col xs={24} md={8} lg={12}>
            <Button type="primary" onClick={() => fetchChargers()} style={{ marginLeft: '1rem' }}>
              Search
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ChargerHeader
