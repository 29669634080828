import { PageHeader, Table } from 'antd'
import { useParams } from 'react-router'

import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useTenantChargers } from 'hooks/useTenantChargers'
import { ChargersUptimeColumns } from './table/ChargersUptimeColumns'
import { AlertError } from 'components/error'
import ChargerHeader from './header/chargerHeader'

export const TenantTabChargers: React.FC = () => {
  // @ts-ignore
  const { tenantId } = useParams()

  const {
    error,
    loading,
    pagination,
    chargerUptimes,
    handlePaginationChange,
    handleExpand,
    rowExpandable,
    expandableRowKeys,
    startDate,
    endDate,
    handleChangeDate,
    fetchChargers,
  } = useTenantChargers(tenantId)

  return (
    <>
      <AlertError error={error} />
      <PageHeader
        title={<ChargerHeader onChangeDate={handleChangeDate} {...{ loading, startDate, endDate, fetchChargers }} />}
      />
      <Table
        sticky
        loading={loading}
        dataSource={chargerUptimes}
        rowKey={(record) => record.id}
        columns={ChargersUptimeColumns()}
        className="tenants-table"
        expandable={{
          onExpand: handleExpand,
          expandedRowRender: () => <></>,
          rowExpandable,
          expandIcon: ({ expandable, expanded, record, onExpand }) =>
            expandable ? (
              expanded ? (
                <MinusCircleOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <PlusCircleOutlined onClick={(e) => onExpand(record, e)} />
              )
            ) : undefined,
        }}
        pagination={{
          hideOnSinglePage: true,
          position: ['bottomCenter'],
          total: pagination?.totalEntries,
          current: pagination?.currentPage,
          pageSize: pagination?.perPage,
          showSizeChanger: false,
          onChange: handlePaginationChange,
        }}
        rowClassName={(record) => (expandableRowKeys[record.id] ? '' : 'custom-row-collapse-tenant-activity-tracking')}
      />
    </>
  )
}
