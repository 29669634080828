import { Col, Space } from 'antd'
import { renderFormatMessage } from '../../../helpers/intl'
import HandlePricingSchemaPrice from './handle-pricing-schema-price'
import PricingSchemaLoiteringStatus from './pricing-schema-loitering-status'
import { theme } from '../../../theme'
import { ListingInfo, PricingSchema } from '../../../models/price'
import CoreBadge from 'components/badge/Badge'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

export const PricingSchemaColumns = (
  setSelectedPricingSchema: (selectedPricingScema: PricingSchema) => void,
  expandableRowKeys: { [dataKey: string]: boolean },
  expanded: { [dataKey: string]: boolean },
  handleRowExpand: (rowId: number) => () => void,
) => [
  {
    title: renderFormatMessage('dashboard.usersPage.table.heading.id', 'ID'),
    width: '11%',
    render: (price: PricingSchema) => {
      return (
        <span
          onClick={() => setSelectedPricingSchema(price)}
          style={{ color: theme.colors.primary, cursor: 'pointer' }}
        >
          {price.id}
        </span>
      )
    },
  },
  {
    title: renderFormatMessage('dashboard.discountSchema.planName', 'Plan Name'),
    width: '24%',
    dataIndex: 'name',
    render: (schemaName: string) => <>{schemaName}</>,
  },
  {
    title: renderFormatMessage('dashboard.text.generalPrice', 'General Price'),
    key: 'price_type',
    width: '20%',
    render: (price: PricingSchema) => <HandlePricingSchemaPrice price={price} />,
  },
  {
    title: renderFormatMessage('dashboard.text.loitering', 'Loitering Price'),
    key: 'loitering_type',
    width: '15%',
    render: (price: PricingSchema) => <PricingSchemaLoiteringStatus price={price} />,
  },
  {
    title: renderFormatMessage('dashboard.listing.ListingsText', 'Listings'),
    render: (price: PricingSchema) => {
      return (
        <Space wrap>
          {price.appliedListings
            ?.slice(0, expandableRowKeys[price.id] && !expanded[price.id] ? 5 : price.appliedListings.length)
            .map((listing: ListingInfo) => (
              <CoreBadge key={listing.listingId} title={listing.listingTitle} />
            ))}
        </Space>
      )
    },
  },
  {
    render: (price: PricingSchema) => {
      return (
        expandableRowKeys[price.id] && (
          <Col>
            {expanded[price.id] ? (
              <MinusCircleOutlined onClick={handleRowExpand(price.id)} />
            ) : (
              <PlusCircleOutlined onClick={handleRowExpand(price.id)} />
            )}
          </Col>
        )
      )
    },
  },
]
