import { IncidencesFilter } from '../../models/filter'

export const incidencesQuery = (
  f: IncidencesFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {
    start: `${startDate.format('DD-MM-YYYY')}`,
    end: `${endDate.format('DD-MM-YYYY')}`,
    previous_status: f.previousStatus,
    new_status: f.newStatus,
  }
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.zendeskId) {
    queryParams['zendesk_id'] = f.zendeskId
  }

  if (f.listings && f.listings.length > 0) {
    queryParams['listing_ids'] = `${f.listings.map((t) => t.id)}`
  }

  if (f.rootCauseIds && f.rootCauseIds.length > 0) {
    queryParams['root_cause_ids'] = f.rootCauseIds?.map((t) => t).join(',')
  }

  if (f.resolutionIds && f.resolutionIds.length > 0) {
    queryParams['resolution_ids'] = f.resolutionIds?.map((t) => t).join(',')
  }

  if (f.downtime) {
    queryParams['downtime'] = f.downtime
  }

  return queryParams
}
