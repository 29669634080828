interface props {
  children: React.ReactNode
  inlineDateTime?: boolean
}

export const AlignedContentRight: React.FC<props> = ({ children, inlineDateTime }) => (
  <div className={`flex-and-gap-below-right ${inlineDateTime ? 'inline-datetime' : ''}`}>{children}</div>
)

export const AlignedContentLeft: React.FC<props> = ({ children, inlineDateTime }) => (
  <div className={`flex-and-gap-below-left ${inlineDateTime ? 'inline-datetime' : ''}`}>{children}</div>
)
