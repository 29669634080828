import { Button, Form, FormInstance, Input, Select } from 'antd'
import { editBlackSVG } from 'assets/svg/edit'
import { EditButtonRowWrapper, EditButtonWrapper } from 'atom/tenants'
import { MasterAccountSelector } from 'components/selector/master-account-selector'
import { RevenueSharePartnerSelector } from 'components/selector/revenue-share-partner-selector'
import { useListingCommissionFeeTranslation } from 'hooks/translation/useListingCommissionFeeTrans'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { MasterAccountRef } from 'models/master-account'
import { Tenant } from 'models/tenant'
import { useAppState } from 'state'

interface TenantAccountInfoProps {
  form: FormInstance
  tenant: Tenant
  disabled: boolean
  masterAccount?: MasterAccountRef
  setMasterAccount: (masterAccount: MasterAccountRef | undefined) => void
  setEditAccountDetailModal: (masterAccount: boolean) => void
}

const TenantAccountInfo: React.FC<TenantAccountInfoProps> = ({
  form,
  tenant,
  disabled,
  masterAccount,
  setMasterAccount,
  setEditAccountDetailModal,
}) => {
  const { IsTablet, IsMobile } = useAppState()
  const isTablet = IsTablet()
  const isMobile = IsMobile()
  const {
    accountText,
    taxRegistrationText,
    taxRegistrationOptionText,
    taxRegistrationOptionNotRegisteredText,
    taxRegistrationOptionJointElectedText,
    remittanceEmailText,
    remittanceFrequencyText,
    editAccountDetailsText,
  } = useTenantTranslation()

  const { partnerText } = useListingCommissionFeeTranslation()
  return (
    <>
      <EditButtonRowWrapper isTablet={isTablet} isMobile={isMobile} spaceBetween="-20">
        <Form.Item id="masterAccount" label={accountText} className="align-center">
          <MasterAccountSelector
            masterAccount={masterAccount}
            onOptionChange={(selectedMasterAccount) => setMasterAccount(selectedMasterAccount)}
            disabled={disabled}
          />
          {isMobile || isTablet ? (
            <EditButtonWrapper>
              <Button
                type="primary"
                disabled={disabled}
                icon={editBlackSVG}
                onClick={() => setEditAccountDetailModal(true)}
              />
            </EditButtonWrapper>
          ) : (
            <Button disabled={disabled} type="primary" onClick={() => setEditAccountDetailModal(true)}>
              {editAccountDetailsText}
            </Button>
          )}
        </Form.Item>
      </EditButtonRowWrapper>

      <Form.Item
        name="remittanceEmails"
        label={remittanceEmailText}
        rules={[{ required: false }]}
        className="align-center"
      >
        <Input disabled={true} />
      </Form.Item>
      <Form.Item
        name="remittanceFrequency"
        label={remittanceFrequencyText}
        rules={[{ required: false }]}
        className="align-center"
      >
        <Input disabled={true} />
      </Form.Item>
      <Form.Item name="taxRegistration" label={taxRegistrationText} className="align-center">
        <Select disabled={disabled} allowClear>
          <Select.Option key="registered" value="registered" label={taxRegistrationOptionText}>
            {taxRegistrationOptionText}
          </Select.Option>
          <Select.Option key="not_registered" value="not_registered" label={taxRegistrationOptionNotRegisteredText}>
            {taxRegistrationOptionNotRegisteredText}
          </Select.Option>
          <Select.Option key="joint_elected" value="joint_elected" label={taxRegistrationOptionJointElectedText}>
            {taxRegistrationOptionJointElectedText}
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.taxRegistration !== currentValues.taxRegistration}
      >
        {({ getFieldValue }) =>
          getFieldValue('taxRegistration') === 'registered' || getFieldValue('taxRegistration') === 'joint_elected' ? (
            <Form.Item name="taxNumber" label="Tax Number" rules={[{ required: true }]}>
              <Input disabled={disabled} />
            </Form.Item>
          ) : null
        }
      </Form.Item>
      <Form.Item className="align-center" name="revSharePartner" label={partnerText}>
        <RevenueSharePartnerSelector
          defaultSelectedPartner={tenant?.revSharePartner}
          disabled={disabled}
          placeholder={partnerText}
          onSelect={(revSharePartner) => {
            form.setFieldsValue({ revSharePartner: revSharePartner ?? null })
          }}
        />
      </Form.Item>
    </>
  )
}

export default TenantAccountInfo
