import { useEffect, useState } from 'react'

import { SwtchError } from 'models/error'
import { PaginationMeta } from 'models/pagination'

import { ChargerUptime } from 'models/charger'
import { GetChargersUptimeForTenant } from 'services/data-provider/charger'
import moment from 'moment'

const initialPagination = { currentPage: 1, perPage: 1, totalEntries: 2 }

export const useTenantChargers = (tenantId: number) => {
  const [chargerUptimes, setChargerUptimes] = useState<ChargerUptime[]>([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState<PaginationMeta>(initialPagination)
  const [error, setError] = useState<SwtchError>()

  const [startDate, setStartDate] = useState(moment().subtract(6, 'days'))
  const [endDate, setEndDate] = useState(moment().endOf('day'))

  const [expandableRowKeys, setExpandableRowKeys] = useState<{ [dataKey: number]: boolean }>({})

  useEffect(() => {
    fetchChargers()
  }, [])

  const handleChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) {
      setStartDate(dates[0])
    }
    if (dates[1] !== endDate) {
      setEndDate(dates[1])
    }
  }

  const handleExpand = (expanded: boolean, record: ChargerUptime) => {
    setExpandableRowKeys({ ...expandableRowKeys, [record.id]: expanded })
  }

  const rowExpandable = (record: ChargerUptime) => {
    if (Object.keys(expandableRowKeys).includes(`${record.id}`)) return true

    const rowElement = document.querySelector(`[data-row-key="${record.id}"]`)
    const currentRowHeight = (rowElement as HTMLElement)?.offsetHeight
    const childElements = rowElement?.querySelectorAll('.custom-row-collapse-tenant-activity-tracking > td > *')

    let expandable = false

    childElements?.forEach((el: Element) => {
      expandable = expandable || el.scrollHeight > currentRowHeight || (el.scrollHeight >= 63 && el.scrollHeight < 73)
    })

    return expandable
  }

  const fetchChargers = (page = 1) => {
    setLoading(true)
    setError(undefined)
    GetChargersUptimeForTenant(tenantId, page, startDate, endDate)
      .then((resp) => {
        setChargerUptimes(resp.data)
        setPagination(resp.pagination)
      })
      .catch((err: SwtchError) => setError(err))
      .finally(() => setLoading(false))
  }

  const handlePaginationChange = (page: number, pageSize?: number) => {
    fetchChargers(page)
    return
  }

  return {
    error,
    loading,
    pagination,
    chargerUptimes,
    expandableRowKeys,
    startDate,
    endDate,
    fetchChargers,
    handleExpand,
    rowExpandable,
    handleChangeDate,
    handlePaginationChange,
  }
}
