import { useIntl, FormattedMessage } from 'react-intl'

export const useFormatMessage = (messageId: string, defaultMessage: string): string => {
  const intl = useIntl()
  return intl.formatMessage({ id: messageId, defaultMessage })
}

export const renderFormatMessage = (
  messageId: string,
  defaultMessage: string,
  values?: Record<string, any>,
): JSX.Element => {
  return <FormattedMessage id={messageId} defaultMessage={defaultMessage} values={values} />
}
