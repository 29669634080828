import { Button, Modal, Space } from 'antd'

import { closeSVG } from '../../../assets/svg/close'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { HeadingWrapper, TextBodyWrapper } from '../../../atom/plans'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
  title: string
  description?: string
}

export const RemoveWarningModal: React.FC<props> = ({ visible, onCancel, onSubmit, title, description }) => {
  const { cancelText } = usePeakShavingTranslation()
  return (
    <Modal
      visible={visible}
      title={<HeadingWrapper>{title}</HeadingWrapper>}
      footer={
        <Space>
          <Button onClick={onCancel} type="ghost">
            {cancelText}
          </Button>
          <Button danger onClick={onSubmit}>
            {title}
          </Button>
        </Space>
      }
      onCancel={onCancel}
      closeIcon={closeSVG}
    >
      <TextBodyWrapper>{description}</TextBodyWrapper>
    </Modal>
  )
}
