import { Modal } from 'antd'
import { UpdateListingModalWrapper } from 'atom/listings'
import { UpdateListingServiceStatus } from 'components/listings/update-listing-service-status'
import { Heading } from '../../../atom/user-edit'
import { useListingBasicTrans } from '../../../hooks/translation/useListingBasicTrans'
import { ListingStatusUpdate, NewListing } from '../../../models/listing'
import { useAppState } from '../../../state'

interface ListingStatusModalProps {
  onCancel: () => void
  listing: NewListing
  onApply: (value: ListingStatusUpdate) => void
}

const ListingStatusModal: React.FC<ListingStatusModalProps> = ({ onCancel, listing, onApply }) => {
  const { updateServiceStatusText } = useListingBasicTrans()
  const modalTitle = updateServiceStatusText
  const { language } = useAppState()

  return (
    <Modal
      title={<Heading>{modalTitle}</Heading>}
      visible={true}
      onCancel={onCancel}
      footer={null}
      width={language === 'fr' ? 800 : 700}
      bodyStyle={{ padding: '10px 24px 24px 24px' }}
    >
      <UpdateListingModalWrapper language={language}>
        <UpdateListingServiceStatus listings={[listing]} onOk={onCancel} onApply={onApply} />
      </UpdateListingModalWrapper>
    </Modal>
  )
}

export default ListingStatusModal
