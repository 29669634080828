import { useEffect, useState } from 'react'

import { SwtchApiError, SwtchError } from 'models/error'
import { PeakShavingEventFilter } from 'models/filter'
import { PaginationMeta } from 'models/pagination'

import { Dropdown, Menu } from 'antd'
import { DotsSVG } from 'assets/svg/dots'
import { CustomSuccessMessage } from 'components/peakShaving/message'
import EventStatusBadge from 'components/peakShaving/peak-shaving-status'
import { PeakShavingEvent, PeakShavingEventState, PeakShavingProgram } from 'models/peak-shaving'
import moment from 'moment'
import {
  CancelShavingEvent,
  DeletePeakShavingEvent,
  GetAllPeakShavingEvents,
  UnPublishPeakShavingEvent,
} from 'services/data-provider/peak-shaving'
import { theme } from 'theme'
import { usePeakShavingTranslation } from './translation/usePeakShavingTranslation'
import { TenantRef } from 'models/tenant'
import { ColumnsType } from 'antd/lib/table'

export const useDemandManagementEvents = () => {
  const [events, setEvents] = useState<PeakShavingEvent[]>([])
  const [createNewEventModalVisible, setCreateNewEventModalVisible] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [pagination, setPagination] = useState<PaginationMeta>()

  const [startDate, setStartDate] = useState(moment().subtract(6, 'days'))
  const [endDate, setEndDate] = useState(moment().endOf('day'))

  const [selectedEvent, setSelectedEvent] = useState<PeakShavingEvent>()

  const [warningInfo, setWarningInfo] = useState({
    title: '',
    description: '',
    function: () => {},
  })

  const [filter, setFilter] = useState<PeakShavingEventFilter>({
    page: 1,
    perPage: 20,
  })

  useEffect(() => {
    fetchEvents()
  }, [])

  const removemMessageKey = 'removeEventMessageKey'
  const cancelMessageKey = 'cancelEventMessageKey'

  const {
    aggregatorNameText,
    dateText,
    tenantText,
    listingsText,
    programText,
    eventStatusText,
    updatedByText,
    idText,
    editEventText,
    cancelEventText,
    eventsTabDeleteEventText,
    eventsTabDeleteEventWarningText,
    eventsTabCancelEventText,
  } = usePeakShavingTranslation()

  const selectEvent = (event: PeakShavingEvent) => () => {
    handleEventModal()
    setSelectedEvent(event)
  }

  const selectEventToCancel = (event: PeakShavingEvent) => () => {
    setSelectedEvent(event)
    setWarningInfo({
      title: cancelEventText,
      description: eventsTabCancelEventText,
      function: () => cancelEvent(event),
    })
    setShowWarning(true)
  }

  const selectEventToRemove = (event: PeakShavingEvent) => () => {
    setSelectedEvent(event)
    setWarningInfo({
      title: eventsTabDeleteEventText,
      description: eventsTabDeleteEventWarningText,
      function: () => removeEvent(event),
    })
    setShowWarning(true)
  }

  const cancelRemoval = () => {
    setSelectedEvent(undefined)
    setShowWarning(false)
  }

  const dropDownDisplay = {
    edit: 'draft|published',
    delete: 'draft|published',
    cancel: 'published|in_progress',
    disabled: 'finished|cancelled',
  }

  const columns: ColumnsType<PeakShavingEvent> = [
    {
      title: idText,
      width: '8%',
      dataIndex: 'id',
    },
    {
      title: dateText,
      dataIndex: 'date',
      render: (date: string) => moment(date).format('YYYY-MM-DD'),
    },
    {
      title: tenantText,
      dataIndex: 'tenant',
      render: (tenant: TenantRef) => tenant?.displayName || tenant?.name,
    },
    {
      title: listingsText,
      render: (event: PeakShavingEvent) => (event?.participant?.allListing ? 'All' : event?.listing?.title),
    },
    {
      title: aggregatorNameText,
      dataIndex: 'program',
      render: (program: PeakShavingProgram) => program?.aggregatorName,
    },
    {
      title: programText,
      dataIndex: 'program',
      render: (program: PeakShavingProgram) => program?.name,
    },
    {
      title: eventStatusText,
      width: '9%',
      dataIndex: 'state',
      render: (eventState: PeakShavingEventState) => <EventStatusBadge state={eventState} />,
    },
    {
      title: updatedByText,
      width: '16%',
      render: (event: PeakShavingEvent) => {
        const { adminPerson, modificationDateTime } = event
        return (
          <div className="updatedby flex-and-gap-below">
            <p className={`paragraph-02-regular ${adminPerson ? '' : 'adminUndefined'}`} style={{ marginBottom: 0 }}>
              {adminPerson ? adminPerson.name : '...'}
            </p>
            {modificationDateTime && (
              <p className="paragraph-04-light" style={{ opacity: 0.6, color: theme.colors.softBlack }}>
                {moment(modificationDateTime).format('MMMM DD, Y')}
              </p>
            )}
          </div>
        )
      },
    },
    {
      title: '',
      render: (event: PeakShavingEvent) => {
        const optionDisabled = dropDownDisplay.disabled.includes(event.state)
        return (
          <Dropdown
            overlay={
              <Menu className="table-dot-dropdown">
                {dropDownDisplay.edit.includes(event.state) && (
                  <Menu.Item onClick={selectEvent(event)}>
                    <p className="paragraph-02-regular">{editEventText}</p>
                  </Menu.Item>
                )}
                {dropDownDisplay.cancel.includes(event.state) && (
                  <Menu.Item onClick={selectEventToCancel(event)}>
                    <p className="paragraph-02-regular">{cancelEventText}</p>
                  </Menu.Item>
                )}
                {dropDownDisplay.delete.includes(event.state) && (
                  <Menu.Item onClick={selectEventToRemove(event)}>
                    <p className="paragraph-02-regular">{eventsTabDeleteEventText}</p>
                  </Menu.Item>
                )}
              </Menu>
            }
            trigger={['click']}
          >
            <span className={optionDisabled ? 'disable-link' : ''} onClick={(e) => e.preventDefault()}>
              {<DotsSVG disabled={optionDisabled} className="edit-event-icon" />}
            </span>
          </Dropdown>
        )
      },
      width: '80px',
    },
  ]

  const handleEventModal = () => {
    const changedState = !createNewEventModalVisible
    if (!changedState) {
      setSelectedEvent(undefined)
    }
    setCreateNewEventModalVisible(changedState)
  }

  const removeEvent = async (event: PeakShavingEvent) => {
    if (!event) return
    setLoading(true)
    setError(undefined)
    try {
      if (event.state !== 'draft') {
        await UnPublishPeakShavingEvent(event.id)
      }
      const { response } = await DeletePeakShavingEvent(event.id)
      cancelRemoval()
      CustomSuccessMessage({ key: removemMessageKey, response })
      fetchEvents()
    } catch (error) {
      setError(error as SwtchApiError)
    } finally {
      setLoading(false)
    }
  }

  const cancelEvent = async (event: PeakShavingEvent) => {
    if (!event) return
    setLoading(true)
    setError(undefined)
    CancelShavingEvent(event.id)
      .then(({ response }) => {
        cancelRemoval()
        CustomSuccessMessage({ key: cancelMessageKey, response: response })
        fetchEvents()
      })
      .catch(setError)
      .finally(() => setLoading(false))
  }

  const fetchEvents = (page = 1) => {
    setLoading(true)
    setError(undefined)

    GetAllPeakShavingEvents({ ...filter, page }, startDate, endDate)
      .then(({ data }) => {
        setEvents(data)
        setPagination(pagination)
      })
      .catch(setError)
      .finally(() => setLoading(false))
  }

  const fetchDataWithQuery = () => {
    fetchEvents()
  }

  const handlePaginationChange = (page: number, pageSize?: number) => {
    fetchEvents(page)
    return
  }

  const onSubmit = () => {
    handleEventModal()
    fetchEvents()
  }

  const handleChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) {
      setStartDate(dates[0])
    }
    if (dates[1] !== endDate) {
      setEndDate(dates[1])
    }
  }

  return {
    error,
    loading,
    pagination,
    filter,
    events,
    columns,
    createNewEventModalVisible,
    fetchEvents,
    fetchDataWithQuery,
    setFilter,
    handlePaginationChange,
    handleEventModal,
    selectedEvent,
    onSubmit,
    removeEvent,
    cancelRemoval,
    showWarning,
    handleChangeDate,
    startDate,
    endDate,
    warningInfo,
  }
}
