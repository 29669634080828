import { Button, PageHeader } from 'antd'

import { plusSignSVG } from 'assets/svg/plusSign'
import { BackgroundContainer } from 'atom/layout/backgroundContainer'
import { AlertError } from 'components/error'
import { usePeakShavingTranslation } from 'hooks/translation/usePeakShavingTranslation'
import { useDemandManagementParticipants } from 'hooks/useDemandManagementParticipants'
import { DemandManagementAddParticipantModal } from '../modal/demand-management-add-participant-modal'
import { RemoveWarningModal } from '../modal/remove-warning-modal'
import ParticipantsSelectors from '../selectors/participantsSelectors'
import TabTable from '../table/tab-table'

interface DemandManagementTabParticipantsProps {}

const DemandManagementTabParticipants: React.FC<DemandManagementTabParticipantsProps> = () => {
  document.querySelector('body')?.classList.add('redesignActive')

  const {
    error,
    loading,
    pagination,
    filter,
    participants,
    columns,
    newParticipantsModalVisible,
    fetchDataWithQuery,
    setFilter,
    handlePaginationChange,
    handleParticipantModal,
    onSubmit,
    removeParticipant,
    cancelRemoval,
    showWarning,
    selectedParticipant,
  } = useDemandManagementParticipants()
  const {
    participantsTabNewText,
    participantsText,
    participantsTabDeleteText,
    participantsTabDeleteWarningText,
  } = usePeakShavingTranslation()

  return (
    <BackgroundContainer>
      <RemoveWarningModal
        visible={showWarning}
        title={participantsTabDeleteText}
        description={participantsTabDeleteWarningText}
        onCancel={cancelRemoval}
        onSubmit={removeParticipant}
      />
      {(newParticipantsModalVisible || !!selectedParticipant) && (
        <DemandManagementAddParticipantModal
          visible={newParticipantsModalVisible}
          onCancel={handleParticipantModal}
          onSubmit={onSubmit}
          initialValues={selectedParticipant}
        />
      )}
      <PageHeader
        title={<ParticipantsSelectors onFilter={setFilter} onFetch={fetchDataWithQuery} {...{ loading, filter }} />}
        extra={
          <Button
            key="new-program-btn"
            loading={loading}
            size="large"
            type="primary"
            disabled={loading}
            icon={plusSignSVG}
            onClick={handleParticipantModal}
          >
            <span className="regular-cap">{participantsTabNewText}</span>
          </Button>
        }
      />
      <AlertError error={error} />
      <TabTable
        title={participantsText}
        loading={loading}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        dataSource={participants}
        columns={columns}
        rowHeightDynamic
      />
    </BackgroundContainer>
  )
}

export default DemandManagementTabParticipants
