import { Table } from 'antd'

import { NewListing } from 'models/listing'
import moment from 'moment'
import { ServiceStatusSectionHeader } from './service-status-section-header'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { useFormatMessage } from 'helpers/intl'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'

interface props {
  selectedListings: NewListing[]
  loading: boolean
  newStatus: string
  displayStatusDetail: (newStatus: string) => boolean
}

export const AboutSelectedListingSection: React.FC<props> = ({
  selectedListings,
  loading,
  newStatus,
  displayStatusDetail,
}) => {
  const { swtchCareText, currentStatusText, sinceText, aboutText } = useListingBasicTrans()
  const { yesText, noText } = useGeneralTranslation()

  const ListingStatusSince = ({ listing }: { listing: NewListing }) => {
    const currentStatusText = useFormatMessage(
      `dashboard.serviceStatus.${listing.connector?.serviceStatus}`,
      'Service Status',
    )

    return (
      <>
        {sinceText
          .replace('-1', currentStatusText)
          .replace('-2', moment(listing.serviceStatusChangedSince).format('MMMM D, YYYY h:mma'))}
      </>
    )
  }

  const ListingColumns = [
    {
      title: '',
      dataIndex: 'title',
    },
    {
      title: swtchCareText,
      render: (listing: NewListing) => (listing.tenant?.swtchCare ? yesText : noText),
    },
    {
      title: currentStatusText,
      render: (listing: NewListing) => <ListingStatusSince listing={listing} />,
    },
  ]

  const title = aboutText.replace('-1', selectedListings.map((listing) => listing.title).join(', '))

  return displayStatusDetail(newStatus) ? (
    <ServiceStatusSectionHeader title={title}>
      <Table
        loading={loading}
        rowKey="title"
        columns={ListingColumns}
        dataSource={selectedListings}
        pagination={false}
      />
    </ServiceStatusSectionHeader>
  ) : null
}
