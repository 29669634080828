import { theme } from '../../theme'

export const calendarSVG = (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.90625 6.42855H13.7188M3.43304 1.5V2.78586M12.0312 1.5V2.78571M14.5625 4.78571L14.5625 14.5C14.5625 15.6046 13.6671 16.5 12.5625 16.5H3.0625C1.95793 16.5 1.0625 15.6046 1.0625 14.5V4.78571C1.0625 3.68114 1.95793 2.78571 3.0625 2.78571H12.5625C13.6671 2.78571 14.5625 3.68114 14.5625 4.78571Z"
      stroke={theme.colors.darkGray}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const datePickerCalendarSVG = (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.90625 6.92855H14.7188M4.43304 2V3.28586M13.0312 2V3.28571M15.5625 5.28571L15.5625 15C15.5625 16.1046 14.6671 17 13.5625 17H4.0625C2.95793 17 2.0625 16.1046 2.0625 15V5.28571C2.0625 4.18114 2.95793 3.28571 4.0625 3.28571H13.5625C14.6671 3.28571 15.5625 4.18114 15.5625 5.28571Z"
      stroke={theme.colors.darkGray}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
