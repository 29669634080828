import { useFormatMessage } from '../../helpers/intl'

export const useSelectorTranslation = () => {
  const searchTenantPlaceholder = useFormatMessage('dashboard.selector.searchTenants', 'Search Site(s)')
  const locationTextPlaceholder = useFormatMessage('dashboard.selector.Tenants', 'Site (s)')
  const startTypingText = useFormatMessage('dashboard.selector.startTyping', 'Start typing to search')
  const usernameText = useFormatMessage('dashboard.transactionsPage.advancedSearch.username', 'Username')

  const serialNumberSelectedText = useFormatMessage('dashboard.text.selected.serialNumber', 'Serial Numbers Selected')

  const selectAReasonText = useFormatMessage('dashboard.serviceStatus.selectReason', 'Select a reason')

  const serviceStatusText = useFormatMessage('dashboard.selector.serviceStatus', 'Service Status')

  const selectReasonForStatusChangeText = useFormatMessage(
    'dashboard.serviceStatus.selectReasonForStatusChange',
    'Select reason for status change',
  )

  const specificANewReasonText = useFormatMessage('dashboard.serviceStatus.specificANewReason', 'Specific a new reason')

  const typeAReasonText = useFormatMessage('dashboard.serviceStatus.typeAReason', 'Type a reason')
  const accountNameText = useFormatMessage('dashboard.tenantPage.tenantTabOverview.accountName', 'Account Name')
  const accountNamesText = useFormatMessage('dashboard.text.accountNames', 'Account Name(s)')

  const tenantSelectedText = useFormatMessage('dashboard.text.selected.tenant', '-1 Sites Selected')
  const masterAccountSelectedText = useFormatMessage('dashboard.text.masterAccount', '-1 Accounts Selected')

  const taggingText = useFormatMessage('dashboard.tagging', 'Tagging')
  const tagsText = useFormatMessage('dashboard.tags', 'Tag (s)')
  const chargingStatusesSelectedText = useFormatMessage(
    'dashboard.text.chargingStatusesSelected',
    '-1 Charging Statuses Selected',
  )
  const serviceStatusesSelectedText = useFormatMessage(
    'dashboard.text.serviceStatusesSelected',
    '-1 Service Statuses Selected',
  )
  const accountNamesSelectedText = useFormatMessage('dashboard.text.accountNamesSelected', '-1 Account Names Selected')
  const tagsSelectedText = useFormatMessage('dashboard.text.tagsSelected', '-1 Tags Selected')

  return {
    accountNamesSelectedText,
    searchTenantPlaceholder,
    locationTextPlaceholder,
    startTypingText,
    usernameText,
    serialNumberSelectedText,
    serviceStatusText,
    selectAReasonText,
    selectReasonForStatusChangeText,
    specificANewReasonText,
    typeAReasonText,
    accountNameText,
    accountNamesText,
    masterAccountSelectedText,
    tenantSelectedText,
    taggingText,
    tagsText,
    tagsSelectedText,
    chargingStatusesSelectedText,
    serviceStatusesSelectedText,
  }
}
