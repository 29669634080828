import { theme } from '../../theme'

export const refreshIconSVG = (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2303 5.54824C13.9794 3.39616 11.6412 1.94824 8.9633 1.94824C5.92701 1.94824 3.32756 3.80959 2.25429 6.44824M13.0329 6.44824H16.6504V2.84824M2.77047 12.7482C4.02142 14.9003 6.35954 16.3482 9.03748 16.3482C12.0738 16.3482 14.6732 14.4869 15.7465 11.8482M4.96785 11.8482H1.35039V15.4482"
      stroke={theme.colors.primary}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
