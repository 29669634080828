export const mockIncidencesTracking = [
  {
    id: 1,
    category: 'uptime',
    externalReference: undefined,
    zendeskId: undefined,
    duration: '0 d 0 h 0 m 0 s',
    downtime: 'include',
    notes: undefined,
    createdBy: 'System',
    updatedBy: undefined,
    listingTitle: 'Listing 1',
    rootCause: undefined,
    startTime: '2024-08-08T19:18:35.000Z',
  },
  {
    id: 2,
    category: 'uptime',
    externalReference: undefined,
    zendeskId: undefined,
    duration: '0 d 0 h 0 m 0 s',
    downtime: 'include',
    notes: undefined,
    createdBy: 'System',
    updatedBy: undefined,
    listingTitle: 'Listing 2',
    rootCause: undefined,
    startTime: '2024-08-08T19:18:35.000Z',
  },
]

export const mockIncidencesResponse = {
  data: mockIncidencesTracking,
  pagination: {
    currentPage: 1,
    totalEntries: mockIncidencesTracking.length,
    perPage: 4,
  },
}

export const mockIncidenceUpdateResponse = {
  id: 26,
  category: 'uptime',
  externalReference: 'MyString',
  zendeskId: 'Zen-123',
  duration: '2 d 0 h 0 m 0 s',
  downtime: 'include',
  notes: undefined,
  createdBy: undefined,
  updatedBy: undefined,
  listingTitle: 'Listing 1',
  rootCause: 'Low Voltag',
  startTime: '2024-08-06T20:13:41.000Z',
}
