import { Table } from 'antd'
import { useState } from 'react'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

import { PaginationMeta } from 'models/pagination'
import { IncidencesColumns } from './IncidencesColumns'
import { Incidences } from 'models/incidences'

interface TenantIncidencesProps {
  loading: boolean
  incidences: Incidences[]
  pagination: PaginationMeta
  onPaginationChange: (page: number) => void
  setSelectedIncidence: (incidence: Incidences) => void
}

const IncidencesView: React.FC<TenantIncidencesProps> = ({
  loading,
  incidences,
  pagination,
  onPaginationChange,
  setSelectedIncidence,
}) => {
  const [expandableRowKeys, setExpandableRowKeys] = useState<{ [dataKey: number]: boolean }>({})

  const onExpand = (expanded: boolean, record: Incidences) => {
    setExpandableRowKeys({ ...expandableRowKeys, [record.id]: expanded })
  }

  const rowExpandable = (record: Incidences) => {
    if (Object.keys(expandableRowKeys).includes(`${record.id}`)) return true

    const rowElement = document.querySelector(`[data-row-key="${record.id}"]`)
    const currentRowHeight = (rowElement as HTMLElement)?.offsetHeight
    const childElements = rowElement?.querySelectorAll('.custom-row-collapse-tenant-activity-tracking > td > *')

    let expandable = false

    childElements?.forEach((el: Element) => {
      expandable = expandable || el.scrollHeight > currentRowHeight || (el.scrollHeight >= 63 && el.scrollHeight < 73)
    })

    return expandable
  }

  return (
    <Table
      sticky
      loading={loading}
      dataSource={incidences}
      rowKey={(record) => record.id}
      columns={IncidencesColumns(setSelectedIncidence)}
      className="tenants-table"
      expandable={{
        onExpand,
        expandedRowRender: () => <></>,
        rowExpandable,
        expandIcon: ({ expandable, expanded, record, onExpand }) =>
          expandable ? (
            expanded ? (
              <MinusCircleOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <PlusCircleOutlined onClick={(e) => onExpand(record, e)} />
            )
          ) : undefined,
      }}
      pagination={{
        hideOnSinglePage: true,
        position: ['bottomCenter'],
        total: pagination?.totalEntries,
        current: pagination?.currentPage,
        pageSize: pagination?.perPage,
        showSizeChanger: false,
        onChange: onPaginationChange,
      }}
      rowClassName={(record) => (expandableRowKeys[record.id] ? '' : 'custom-row-collapse-tenant-activity-tracking')}
    />
  )
}

export default IncidencesView
