import { Form } from 'antd'
import { CustomSuccessMessage } from 'components/peakShaving/message'
import { ColumnTypes } from 'components/table/editable-table'
import { usePeakShavingTranslation } from 'hooks/translation/usePeakShavingTranslation'
import { SwtchApiError } from 'models/error'
import { ListingRef } from 'models/listing'
import { Participants, PeakShavingProgram } from 'models/peak-shaving'
import { useEffect, useState } from 'react'
import { AddParticipant, AddParticipantToProgramV2 } from 'services/data-provider/peak-shaving'

interface DataType {
  key: React.Key
  title: string
  id: number
}

export const useDemandManagementParticipantDetail = (
  onSubmit: () => void,
  onCancel: () => void,
  initialValues?: Participants,
) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [error, setError] = useState<SwtchApiError>()
  const messageKey = initialValues ? 'updateParticipantMessageKey' : 'addParticipantMessageKey'

  const { allListingsText, selectListingsText, listingText, addParticipantSuccessMessage } = usePeakShavingTranslation()
  const [allListingStatus, setAllListingStatus] = useState<string | undefined>(undefined)
  const [selectedListings, setSelectedListings] = useState<(ListingRef & { participantId?: string })[]>([])
  const [dataSource, setDataSource] = useState<DataType[]>([])

  const allListingOptions = [
    { value: 'allListings', label: allListingsText },
    { value: 'selectListings', label: selectListingsText },
  ]

  const tableColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string; placeholder?: string })[] = [
    {
      title: listingText,
      dataIndex: 'title',
    },
  ]

  useEffect(() => {
    //For populating Listing Table
    if (selectedListings.length !== dataSource.length) {
      let updatedDataSource = [...dataSource]
      selectedListings.forEach((listing, index) => {
        updatedDataSource[index] = {
          key: `${index}`,
          title: listing.title,
          id: listing.id,
        }
      })
      setDataSource(updatedDataSource.slice(0, selectedListings.length))
    }
  }, [selectedListings])

  //Future implementation for Edit participant
  // useEffect(() => {
  //   if (initialValues) {
  //     let listingStatus = initialValues ? allListingOptions[initialValues?.allListing ? 1 : 0].value : undefined
  //     setAllListingStatus(listingStatus)
  //     setSelectedListings(initialValues ? initialValues?.listings : [])
  //   }
  // }, [initialValues])

  const handleFormSubmit = () => {
    form.validateFields().then(async (values) => {
      try {
        const { tenant, allListing, listings, programs } = values
        setLoading(true)
        setError(undefined)
        let participants: Participants[] = []
        if (allListing === 'allListings') {
          participants = [
            await AddParticipant({
              participantType: 'tenant',
              tenantId: tenant.id,
            }),
          ]
        } else {
          participants = await Promise.all(
            listings.map((listing: ListingRef) => {
              return AddParticipant({
                participantType: 'listing',
                listingId: listing.id,
              })
            }),
          )
        }
        if (programs) {
          await Promise.all(
            programs.flatMap(({ id: programId }: PeakShavingProgram) =>
              participants.map(({ id: participantId }: Participants) => {
                return AddParticipantToProgramV2(programId, participantId)
              }),
            ),
          )
        }

        CustomSuccessMessage({ key: messageKey, response: addParticipantSuccessMessage })
        onSubmit()
      } catch (error) {
        setError(error as SwtchApiError)
      } finally {
        setLoading(false)
      }
    })
  }

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  }

  const returnField = (value: any, fieldToReturn?: string) => {
    if (fieldToReturn) return value[fieldToReturn]
    return value
  }

  const onSelect = (fieldName: string, fieldToReturn?: string, isSingle = true) => (selectedOptions: any[] | any) => {
    const updatedValue = isSingle
      ? returnField(selectedOptions, fieldToReturn)
      : (selectedOptions as any[]).map((option) => returnField(option, fieldToReturn))
    form.setFieldsValue({
      [fieldName]: updatedValue,
    })
    if (fieldName === 'listings') {
      setSelectedListings(updatedValue)
    }
  }

  return {
    loading,
    handleFormSubmit,
    error,
    form,
    allListingOptions,
    formItemLayout,
    onSelect,
    setAllListingStatus,
    allListingStatus,
    tableColumns,
    dataSource,
  }
}
