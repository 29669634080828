import { Button, Form, Modal, Select } from 'antd'

import { FooterLayout } from 'atom/user-edit'
import { renderFormatMessage } from 'helpers/intl'
import { renderConnectionMessage } from 'helpers/status'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { TenantRef, TenantStatus, tenantStatuses } from 'models/tenant'

interface TenantStatusModalProps {
  onCancel: () => void
  onStatus: (status: TenantStatus) => void
  listingCount: number
  status?: TenantStatus
}

const TenantStatusModal: React.FC<TenantStatusModalProps> = ({ onCancel, onStatus, listingCount, status }) => {
  const [form] = Form.useForm()
  const { cancelText, confirmText } = useGeneralTranslation()
  const { cannotAssignTenantAsDeletedText, editTenantStatusText } = useTenantTranslation()

  const onOk = () => {
    form.validateFields().then((values: TenantRef) => {
      if (values.status) {
        onStatus(values.status)
      }
      if (values.status !== 'inactive' && values.status !== 'deleted') {
        onCancel()
      }
    })
  }

  return (
    <Modal
      title={editTenantStatusText}
      visible={true}
      onCancel={onCancel}
      footer={
        <FooterLayout>
          <Button onClick={onCancel}>{cancelText}</Button>
          <Button onClick={onOk} type="primary" htmlType="submit">
            {confirmText}
          </Button>
        </FooterLayout>
      }
    >
      <Form form={form} initialValues={{ status }}>
        <Form.Item label="Status" name="status" help={listingCount !== 0 ? cannotAssignTenantAsDeletedText : ''}>
          <Select showSearch placeholder="Select a status">
            {tenantStatuses.map((tenantStatus) => (
              <Select.Option
                key={tenantStatus}
                value={tenantStatus}
                disabled={tenantStatus === 'deleted' && listingCount !== 0}
              >
                {renderFormatMessage(renderConnectionMessage(tenantStatus), tenantStatus)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default TenantStatusModal
