import { styled, theme } from 'theme'
interface LegendContainerProps {
  hoveredIndex: number
  index: number
  color: string
}

const StyledLegendWrapper = styled.div`
  p {
    margin: 0;
    margin-left: 8px;
    flex: 1;
  }
  span {
    margin-left: 8px;
  }

  .label {
    color: ${theme.colors.darkGray};
  }

  .clickable {
    cursor: pointer; /* Change cursor to pointer when hoverable */

    /* Hover effect to show underline */
    &:hover {
      text-decoration: underline;
    }
  }

  position: absolute;
  width: 100%;
  top: calc(50% + 90px);
`

const LegendContainer = styled.div<LegendContainerProps>`
  display: flex;
  padding: 8px 0px 8px 20px;
  background-color: ${({ hoveredIndex, index }) => (hoveredIndex === index ? theme.colors.grey13 : 'transparent')};
  align-items: flex-start;
  flex-direction: row;

  .legend-block {
    margin-top: 3px;
    width: 11px;
    height: 11px;
    border-radius: 3px;
    background-color: ${({ color }) => color};
  }
`

interface props {
  hoveredIndex: number
  dataFormatter?: (data: string) => string
  legends: {
    label: string
    data?: number
    onClick?: () => void
    color: string
  }[]
}

const defaultDataFormatter = (data: string) => `(${data})`

export const LegendsContainer: React.FC<props> = ({ hoveredIndex, legends, dataFormatter = defaultDataFormatter }) => {
  return (
    <StyledLegendWrapper>
      {legends.map((legend, index) => (
        <LegendContainer
          key={index} // Or better, use a unique identifier from the legend object like legend.id
          hoveredIndex={hoveredIndex}
          index={index}
          color={legend.color}
        >
          <div className="legend-block" />
          <p className={`paragraph-01-regular label ${legend?.onClick && 'clickable'}`} onClick={legend.onClick}>
            {legend.label}
            <span className="paragraph-01-bold">{dataFormatter(`${legend.data}`)}</span>
          </p>
        </LegendContainer>
      ))}
    </StyledLegendWrapper>
  )
}
