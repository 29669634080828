import { camelCase, snakeCase } from 'lodash'

export class CaseConverter {
  camelToSnakeCase(model: any) {
    const apiModel: any = {}
    if (model) {
      for (const key of Object.keys(model)) {
        if (model[key] instanceof Array) {
          apiModel[snakeCase(key)] = model[key].map((value: any) =>
            typeof value === 'object' && value !== null ? this.camelToSnakeCase(value) : value,
          )
        } else if (typeof model[key] === 'object' && model[key] !== null && !(model[key] instanceof Date)) {
          apiModel[snakeCase(key)] = this.camelToSnakeCase(model[key])
        } else {
          apiModel[snakeCase(key)] = model[key]
        }
      }
    }
    return apiModel
  }

  snakeToCamelCase(apiModel: any) {
    if (!apiModel) {
      return
    }
    const modelData: any = {}
    for (const key of Object.keys(apiModel)) {
      if (apiModel[key] === null && apiModel[key] === undefined) {
        return
      }
      if (Array.isArray(apiModel[key])) {
        const array = []
        for (const item of apiModel[key]) {
          switch (typeof item) {
            case 'string':
              array.push(camelCase(item))
              break
            case 'number':
              array.push(item)
              break
            default:
              array.push(this.snakeToCamelCase(item))
          }
        }
        modelData[camelCase(key)] = array
      } else {
        modelData[camelCase(key)] =
          typeof apiModel[key] === 'object' ? this.snakeToCamelCase(apiModel[key]) : apiModel[key]
      }
    }
    return modelData
  }

  renameKeys(obj: any, newKeys: any) {
    const keyValues = Object.keys(obj).map((key) => {
      const newKey = newKeys[key] || key
      return { [newKey]: obj[key] }
    })
    return Object.assign({}, ...keyValues)
  }
}

export default new CaseConverter()
