import { Skeleton, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Box } from '../../atom/box'
import { AlertError } from '../../components/error'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { TenantTabAccessCode } from '../../components/tenants/tenant-tab-access-code'
import { TenantTabChargers } from '../../components/tenants/tenant-tab-chargers'
import { TenantDeploymentTab } from '../../components/tenants/tenant-tab-deployment'
import { TenantTabLocation } from '../../components/tenants/tenant-tab-location'
import { TenantTabOverview } from '../../components/tenants/tenant-tab-overview'
import { SwtchError } from '../../models/error'
import { Tenant, TenantPayload } from '../../models/tenant'
import { GetTenant, UpdateTenant } from '../../services/data-provider/tenants'
import { TabsWrapper } from '../../atom/chargers'
import { BackgroundContainer } from '../../atom/layout/backgroundContainer'
import { useTenantTranslation } from '../../hooks/translation/useTenantTranslation'
import { TabsButtonWrapper } from '../../atom/users-page-redesign-styles'
import { ButtonModal } from '../../atom/button'
import { plusSVG } from '../../assets/svg/plus'
import { TenantTabPricingSchema } from '../../components/tenants/tenant-tab-pricing-schema'
import { usePricingSchemas } from '../../hooks/usePricingSchemas'
import { TenantTabPartnerIntegration } from 'components/tenants/tenant-tab-partner-integration'
import TenantTabActivityTracking from 'components/tenants/tenant-tab-activity-tracking'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { TenantTabTagging } from 'components/tenants/tenant-tab-tagging'
import TenantTabIncidences from 'components/tenants/tenant-tab-incidences'

const TenantBasePage: React.FC = () => {
  document.querySelector('body')?.classList.add('redesignActive')
  // @ts-ignore
  const { tenantId } = useParams()
  const [tenant, setTenant] = useState<Tenant>()
  const [tenantPayload, setTenantPayload] = useState<TenantPayload>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<SwtchError>()
  const [tab, setTab] = useState('overview')
  const [showCreateDiscountSchemaModal, setShowCreateDiscountSchemaModal] = useState(false)
  const { showCreatePricingSchemaModal, setShowCreatePricingSchemaModal } = usePricingSchemas(tenant)
  const { discountPlanText } = useGeneralTranslation()
  const {
    tenantTabChargerText,
    overviewText,
    locationText,
    accessText,
    pricingPlanText,
    deploymentText,
    partnerIntegrationText,
    activityTrackingText,
    taggingText,
    incidencesText,
  } = useTenantTranslation()

  useEffect(() => {
    setLoading(true)
    GetTenant(tenantId)
      .then((tenant) => setTenant(tenant))
      .catch((err: SwtchError) => setError(err))
      .finally(() => setLoading(false))
  }, [tenantId])

  useEffect(() => {
    if (tenantPayload && !loading) {
      setLoading(true)
      UpdateTenant(tenantPayload)
        .then(setTenant)
        .catch((err: SwtchError) => setError(err))
        .finally(() => setLoading(false))
    }
  }, [tenantPayload])

  const handleTabOptions = () => {
    switch (tab) {
      case 'pricingSchema':
        return (
          <>
            <TabsButtonWrapper className="discount-plans-button">
              <ButtonModal
                size="large"
                click={() => setShowCreateDiscountSchemaModal(true)}
                icon={plusSVG}
                text={discountPlanText}
                type="primary"
              />
            </TabsButtonWrapper>
            <TabsButtonWrapper className="plans-button">
              <ButtonModal
                size="large"
                click={() => setShowCreatePricingSchemaModal(true)}
                icon={plusSVG}
                text={pricingPlanText}
                type="primary"
              />
            </TabsButtonWrapper>
          </>
        )
      default:
        return null
    }
  }

  if (!tenant) {
    return (
      <Box>
        <Skeleton active />
      </Box>
    )
  }
  return (
    <BackgroundContainer>
      <AlertError error={error} />
      <TabsWrapper>
        <Tabs defaultActiveKey="overview" tabBarExtraContent={handleTabOptions()} onChange={(key) => setTab(key)}>
          {!tenant || (
            <Tabs.TabPane key="overview" tab={overviewText}>
              <TenantTabOverview tenant={tenant} onSave={setTenantPayload} />
            </Tabs.TabPane>
          )}
          {!tenant || (
            <Tabs.TabPane key="location" tab={locationText}>
              <TenantTabLocation tenant={tenant} onSave={setTenantPayload} />
            </Tabs.TabPane>
          )}
          {!tenant || (
            <Tabs.TabPane key="access" tab={accessText}>
              <TenantTabAccessCode tenant={tenant} onSave={setTenantPayload} />
            </Tabs.TabPane>
          )}
          {!tenant || (
            <Tabs.TabPane key="deployment" tab={deploymentText}>
              <TenantDeploymentTab tenant={tenant} />
            </Tabs.TabPane>
          )}
          {!tenant?.chargerCount || (
            <Tabs.TabPane key="chargers" tab={`${tenantTabChargerText} (${tenant.chargerCount})`}>
              <TenantTabChargers />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane key="pricingSchema" tab={pricingPlanText}>
            <TenantTabPricingSchema
              tenant={tenant}
              showCreateDiscountSchemaModal={showCreateDiscountSchemaModal}
              showCreatePricingSchemaModal={showCreatePricingSchemaModal}
              setShowCreatePricingSchemaModal={setShowCreatePricingSchemaModal}
              setShowCreateDiscountSchemaModal={setShowCreateDiscountSchemaModal}
            />
          </Tabs.TabPane>
          {!tenant || (
            <Tabs.TabPane key="partnerIntegration" tab={partnerIntegrationText}>
              <TenantTabPartnerIntegration tenant={tenant} onSave={setTenantPayload} />
            </Tabs.TabPane>
          )}
          {!tenant || (
            <Tabs.TabPane key="activityTraceability" tab={activityTrackingText}>
              <TenantTabActivityTracking tenant={tenant} />
            </Tabs.TabPane>
          )}
          {!tenant || (
            <Tabs.TabPane key="incidences" tab={incidencesText}>
              <TenantTabIncidences tenant={tenant} />
            </Tabs.TabPane>
          )}
          {!tenant || (
            <Tabs.TabPane key="tagging" tab={taggingText}>
              <TenantTabTagging tenant={tenant} />
            </Tabs.TabPane>
          )}
        </Tabs>
      </TabsWrapper>
    </BackgroundContainer>
  )
}

export const TenantPageV2 = withAuthenticatedLayout(TenantBasePage)
