import { TenantRef } from './tenant'
import { Connector, ConnectorRef } from './connector'
import { ListingLoadMgmt, ListingRef, NewListing } from './listing'

export type ChargerType = 'single-port' | 'multi-port'

export type DiagnosticsRequestState = 'idle' | 'requested' | 'accepted' | 'uploading'
export type DiagnosticsCompletionState = 'uploaded' | 'failed' | 'not_available'
export type ChargingType = 'DC' | 'Level 2'

export const phaseType = ['AB', 'AC', 'BC']
export type PhaseType = typeof phaseType[number]

export const serviceStatus = ['under_repair', 'activating', 'service_mode', 'in_maintenance'] as const
export type ServiceStatus = typeof serviceStatus[number]

export const ChargerServiceStatusAlias: { [key: string]: ServiceStatus[] } = {
  underRepair: ['under_repair'],
  activating: ['activating'],
  commissioning: ['in_maintenance'],
  active: ['service_mode'],
}

export interface ChargerRef {
  id: number
  chargePointSerialNumber: string
}

export interface ChargerBase extends ChargerRef {
  chargePointVendor: string
  chargePointModel: string
  serviceStatus: ServiceStatus
  tenant?: TenantRef
  online: boolean
  chargingType: ChargingType
  chargerType?: ChargerType
  firmwareVersion?: string
}

export interface PeakShavingCharger extends ChargerBase {
  maxWatt: number
}

export interface Charger extends ChargerBase {
  connectors: Connector[]
  firmwareVersion: string
  chargerType: ChargerType
  maxWatt: number
  lastConnectedAt: string
  lastDisconnectedAt: string
  createdAt: string
  updatedAt: string
}

export interface ChargerDetails extends ChargersListingsLoadMgmt {
  cp_serial_number: string
  phases: PhaseType
  ampere_max: number
  ampere_min: number
  ampere_fallback: number
}

export interface ChargerDiagnosticsState {
  state: DiagnosticsRequestState
  requestTime: string
  startTime: string
  stopTime: string
  filename: string
}

export interface ChargerDiagnosticsFiles {
  files: ChargerDiagnosticsFile[]
}

export interface ChargerDiagnosticsFile {
  id: number
  state: DiagnosticsCompletionState
  requestTime: string
  startTime: string
  stopTime: string
  filename: string
}

export interface ChargerListings extends ChargerRef {
  listings: ListingRef[]
  connectors: ConnectorRef[]
}

export interface ChargersListingsLoadMgmt extends ChargerRef {
  listings: ListingLoadMgmt[]
}

export interface ChargersPerTenant {
  tenant: TenantRef
  listings: NewListing[]
}

export interface ChargerUptime {
  id: number
  online: boolean
  chargePointSerialNumber: string
  swtchCare: string
  connector: ConnectorRef
  listing: ListingRef
  uptimePercentage: number
  utilizationRate: number
}
