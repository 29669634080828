import { theme } from '../../theme'

export const duplicateSVG = (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 7.51367H4.5C3.67157 7.51367 3 8.18524 3 9.01367C3 9.8421 3 13.1852 3 14.0137C3 14.8421 3.67157 15.5137 4.5 15.5137C4.5 15.5137 8.67157 15.5137 9.5 15.5137C10.3284 15.5137 11 14.8421 11 14.0137V12.1387M13.5 3.51367L8.5 3.51367C7.67157 3.51367 7 4.18524 7 5.01367C7 5.8421 7 9.18525 7 10.0137C7 10.8421 7.67157 11.5137 8.5 11.5137C8.5 11.5137 12.6716 11.5137 13.5 11.5137C14.3284 11.5137 15 10.8421 15 10.0137V5.01367C15 4.18525 14.3284 3.51367 13.5 3.51367Z"
      stroke={theme.colors.darkGray}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
