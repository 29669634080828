export const chargersUptimeQuery = (
  f: any,
  startDate: moment.Moment,
  endDate: moment.Moment,
): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {
    start_date: `${startDate.format('YYYY-MM-DD')}`,
    end_date: `${endDate.format('YYYY-MM-DD')}`,
  }
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.tenant) {
    queryParams['tenant_id'] = `${f.tenant.id}`
  }

  return queryParams
}
